import React, { FC, useCallback } from 'react';
import { useComponentId } from '../../hooks/useComponentId';
import { FileList } from './FileList';
import { FileDropzone } from './FileDropzone';
import { useFileUploader } from './FileUploaderContext';
import { DoUploadFn, ServerFileModel, UploadModel } from './model';

const defaultAcceptFileTypes = [
  'text/*',
  'image/*',
  'application/msword',
  'application/pdf',
  'application/powerpoint',
  'application/zip',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'video/*',
];

export interface FileUploaderProps {
  instanceId?: string;
  onUploadSuccess?(fileUpload: ServerFileModel): void;
  onUploadRemoved?(fileUpload: ServerFileModel): void;
  doUpload: DoUploadFn;
  acceptFileTypes?: string[];
  source?: string;
  showIcon?: boolean;
  prompt?: string;
}

export const FileUploader: FC<FileUploaderProps> = props => {
  const {
    instanceId: instanceIdProp,
    doUpload,
    onUploadSuccess,
    onUploadRemoved,
    acceptFileTypes = defaultAcceptFileTypes,
    showIcon = true,
    prompt,
  } = props;
  const componentId = useComponentId();
  const instanceId = (instanceIdProp || componentId) as string;
  const { actions } = useFileUploader({
    instanceId,
  });

  const handleRemoveFile = useCallback(
    (upload: UploadModel) => {
      if (onUploadRemoved && upload.remoteRef)
        onUploadRemoved(upload.remoteRef);
    },
    [onUploadRemoved]
  );

  const handleUploadSuccess = useCallback(
    (upload: UploadModel) => {
      if (onUploadSuccess && upload.remoteRef)
        onUploadSuccess(upload.remoteRef);
    },
    [onUploadSuccess]
  );

  const onDrop = useCallback((acceptedFiles: File[]) => {
    actions.filesAdded(acceptedFiles);
  }, []);

  return (
    <div>
      <FileDropzone
        dropzoneOptions={{
          accept: acceptFileTypes,
          onDrop,
        }}
        showIcon={showIcon}
        prompt={prompt}
      />
      <FileList
        instanceId={instanceId}
        doUpload={doUpload}
        onUploadSuccess={handleUploadSuccess}
        onRemove={handleRemoveFile}
      />
    </div>
  );
};
