import React, { FC } from "react";
import { ToothSelector } from "components/ToothSelector";
import { ErrorMessage, useField } from "formik";
import {
  VerticalField,
  HorizontalField,
  compose,
  ValidateProp
} from "@preferral/ui";

interface ToothSelectorInputProps {
  id?: string;
  name: string;
  className?: string;
  validate?: ValidateProp;
}

export const ToothSelectorInput: FC<ToothSelectorInputProps> = (props) => {
  const { id, name, className = "", validate: validators } = props;

  const validate = validators && compose([validators].flat());
  const [field, , helpers] = useField({ name, validate });

  const { value } = field;
  const { setValue } = helpers;

  return (
    <div className={className}>
      <div id={id || name}>
        <ToothSelector value={value || []} onChange={setValue} />
      </div>
      <div className="mt-1">
        <ErrorMessage
          component="p"
          name={name}
          className="text-sm italic text-red-600"
        />
      </div>
    </div>
  );
};

export interface ToothSelectorFieldProps extends ToothSelectorInputProps {
  label: string;
  indicateOptional?: boolean;
  indicateRequired?: boolean;
}

export const ToothSelectorField: FC<ToothSelectorFieldProps> = (props) => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;

  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <ToothSelectorInput {...props} />
    </VerticalField>
  );
};

export const HorizontalToothSelectorField: FC<ToothSelectorFieldProps> = (
  props
) => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;

  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <ToothSelectorInput {...props} />
    </HorizontalField>
  );
};
