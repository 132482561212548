import React, { FC } from 'react';
import { useField } from 'formik';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

interface SliderFieldProps {
  value: number;
  name: string;
  max: number;
  min?: number;
  step: number;
}

export const SliderField: FC<SliderFieldProps> = (props) => {
  let { name, max, min, step } = props;
  const multiply = 5;
  const [field, , helpers] = useField({ name });
  const { value } = field;
  const { setValue } = helpers;
  const _marks = Array.from({ length: max / multiply + 1 }, (_, i) =>
    i ? i * multiply : 1
  );

  const marks = _marks.reduce((acc, cur) => {
    acc[cur] = cur === 1 ? '1' : cur % (multiply * 2) ? " '" : cur;
    return acc;
  }, {});
  return (
    <>
      <input
        className="hidden"
        type="number"
        name="distance"
        id="distance"
        min="1"
        max="50"
        value={value}
      />
      <Slider
        marks={marks}
        min={min}
        max={max}
        defaultValue={value}
        value={value}
        step={step}
        railStyle={{ backgroundColor: '#aac9ff' }}
        trackStyle={[{ backgroundColor: '#1c64f2' }]}
        dotStyle={{ display: 'none' }}
        handleRender={(renderProps, { value, dragging }) => {
          return (
            <div {...renderProps.props}>
              <div
                className="absolute bottom-5 bg-blue-500 p-1 text-white text-xs rounded text-center"
                style={{
                  display: dragging ? 'block' : 'none',
                  minWidth: '20px',
                  bottom: '15px',
                  right: '-5px',
                }}
              >
                {value}
              </div>
            </div>
          );
        }}
        onChange={(val) => setValue(val)}
      />
    </>
  );
};
