import { FC } from 'react'
import { required, Validator } from '@preferral/ui'
import { HorizontalMedicationListField } from 'components/formik/MedicationListField'
import { MedicationListQuestion } from '../types'
import { useQuestion } from './QuestionnaireFormContext'

interface MedicationListQuestionFieldProps {
  question: MedicationListQuestion
}

export const MedicationListQuestionField: FC<MedicationListQuestionFieldProps> = (
  props,
) => {
  const { question } = props

  const { name, isRequired, isVisible } = useQuestion(question.id)

  if (!isVisible) {
    return null
  }

  const validate = [isRequired ? required : null].filter(Boolean) as Validator[]

  return (
    <div className="_MedicationListQuestionField">
      <HorizontalMedicationListField
        indicateRequired={isRequired}
        label={question.prompt}
        name={name}
        validate={validate}
        includeDosage={!!(question.config?.includeDosage)}
        includeFrequency={!!(question.config?.includeFrequency)}
      />
    </div>
  )
}
