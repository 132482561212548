import { FC, useEffect, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import useLocalStorageState from 'use-local-storage-state'
import { gql, useQuery } from "@apollo/client";
import { ClipboardIcon, ClipboardCheckIcon } from "@heroicons/react/outline";
import { ScreenTitle } from "context/ScreenTitle";
import { Spinner } from "@preferral/ui";
import { NoticeModal } from "./NoticeModal";

const PATIENT_PACKET_SUBMISSION = gql`
  query GetPatientPacketSubmission($id: UUID4!) {
    patientPacketSubmission(id: $id) {
      id
      title
      nextForm {
        id
        startedAt
      }
      patientPacket {
        id
        noticeText
      }
      patientFormSubmissions {
        id
        patientFormId
        status
        patientForm {
          id
          title
          kind
          logoUrl
        }
        submittedAt
        insertedAt
      }
    }
  }
`;

interface Data {
  patientPacketSubmission: {
    id: string;
    title: string;
    patientFormSubmissions: PatientFormSubmissionModel[];
    patientPacket: {
      id: string;
      noticeText: null | string;
    }
  };
}

interface PatientFormModel {
  id: string;
  title: string;
  kind: "form" | "notice";
  logoUrl: string;
}

interface PatientFormSubmissionModel {
  id: string;
  patientFormId: string;
  patientForm: PatientFormModel;
  status: SubmissionStatus;
  submittedAt?: string;
  insertedAt: string;
}

type SubmissionStatus = "UNOPENED" | "IN_PROGRESS" | "COMPLETED";

interface Variables {
  id: string;
}

/**
 * Badge.
 */

interface BadgeProps {
  color: "gray" | "blue" | "green";
}

const badgeClassNames = {
  gray: "bg-gray-100 text-gray-600 border",
  blue: "bg-blue-100 text-blue-800",
  green: "bg-green-100 text-green-800",
};

const Badge: FC<BadgeProps> = props => {
  const { color, children } = props;

  const className = badgeClassNames[color];

  return (
    <span
      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${className}`}
    >
      {children}
    </span>
  );
};

/**
 * PatientFormTile.
 */

interface PatientFormTileProps {
  patientPacketSubmissionId: string;
  patientFormSubmission: PatientFormSubmissionModel;
}

const statusBadgeColors: Record<SubmissionStatus, "gray" | "blue" | "green"> = {
  UNOPENED: "gray",
  IN_PROGRESS: "blue",
  COMPLETED: "green",
};

const statusLabels = {
  UNOPENED: "Not Started",
  IN_PROGRESS: "In Progress",
  COMPLETED: "Completed",
};

const PatientFormTile: FC<PatientFormTileProps> = props => {
  const { patientPacketSubmissionId, patientFormSubmission } = props;
  const { status } = patientFormSubmission;

  const badgeColor = statusBadgeColors[status];
  const statusLabel = statusLabels[status];

  return (
    <Link
      to={`/pp/packets/${patientPacketSubmissionId}/form/${patientFormSubmission.id}`}
      className="_PatientFormTile bg-white block border-2 duration-300 hover:bg-gray-50 hover:border-blue-400 hover:shadow-xl relative rounded-lg shadow-none transition-all"
    >
      <div className="focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 group block w-full aspect-w-10 aspect-h-7 rounded-lg overflow-hidden">
        <div className="flex items-center justify-center p-4">
          {status === "COMPLETED" ? (
            <ClipboardCheckIcon className="h-24 w-24 text-green-400" />
          ) : (
            <ClipboardIcon className="h-24 w-24 text-gray-400" />
          )}
        </div>
      </div>
      <div className="p-2 border-t">
        <p className="block font-medium mb-1 ml-1 pointer-events-none text-gray-900 text-sm truncate">
          {patientFormSubmission.patientForm.title}
        </p>
        <p className="block text-sm font-medium text-gray-500 pointer-events-none">
          <Badge color={badgeColor}>{statusLabel}</Badge>
        </p>
      </div>
    </Link>
  );
};

interface RouteParams {
  patientPacketSubmissionId: string;
}
interface PatientPacketSubmissionShowScreenProps { }

export const PatientPacketSubmissionShowScreen: FC<PatientPacketSubmissionShowScreenProps> = () => {
  const match = useRouteMatch<RouteParams>();
  const { patientPacketSubmissionId } = match.params;

  const [dismissedNotices, setDismissedNotices] = useLocalStorageState<string[]>('dismissedNotices', {
    defaultValue: []
  })

  function dismissNotice(packetId: string) {
    return setDismissedNotices([...dismissedNotices.filter(d => d !== packetId), packetId])
  }

  const [activeModal, setActiveModal] = useState<"notice" | null>(null);

  const { data, loading, error } = useQuery<Data, Variables>(
    PATIENT_PACKET_SUBMISSION,
    {
      variables: { id: patientPacketSubmissionId },
      fetchPolicy: "network-only",
    }
  );

  const packetId = data?.patientPacketSubmission.id;
  const noticeDismissed = packetId && dismissedNotices.includes(packetId);
  const noticeText = data?.patientPacketSubmission.patientPacket.noticeText;

  useEffect(() => {
    if (noticeText && !noticeDismissed && !activeModal) {
      setActiveModal("notice");
    }
  }, [noticeDismissed, activeModal, noticeText, setActiveModal])

  // const onFormSubmitSuccess = useCallback(() => {
  //   return refetch();
  // }, [refetch]);

  const forms = data?.patientPacketSubmission.patientFormSubmissions.filter(
    pfs => pfs.patientForm.kind === "form"
  ) || [];
  const notices = data?.patientPacketSubmission.patientFormSubmissions.filter(
    pfs => pfs.patientForm.kind === "notice"
  ) || [];
  const hasForms = forms.length > 0;
  const hasNotices = notices.length > 0;
  const completedFormsCount = forms.filter(pfs => !!pfs.submittedAt).length;
  const completedNoticesCount = notices.filter(pfs => !!pfs.submittedAt).length;

  const instructions =
    hasForms && hasNotices
      ? "Please complete each of the following forms, and acknowledge each of the notices."
      : hasForms
        ? "Please complete each of the following forms"
        : hasNotices
          ? "Please acknowledge each of the following notices."
          : "Please complete each item below.";

  return (
    <>
      <NoticeModal
        isOpen={activeModal === "notice"}
        onClose={() => {
          packetId && dismissNotice(packetId);
          setActiveModal(null)
        }
        }
        text={data?.patientPacketSubmission.patientPacket.noticeText}
        logoUrl={getLogoUrl(data)}
      />
      <ScreenTitle
        title={
          data?.patientPacketSubmission
            ? ["Patient Packet", data.patientPacketSubmission.title]
            : "Patient Packet"
        }
      />
      <div className="_PatientPacketSubmissionShowScreen pt-6 px-2">
        <p>{instructions}</p>

        {loading ? (
          <div className="pt-12 p-4 text-center">
            <Spinner />
          </div>
        ) : error || !data?.patientPacketSubmission ? (
          <p>Failed to load.</p>
        ) : (
          <div>
            {hasNotices ? (
              <div className="pt-5">
                <div className="pt-2 py-4 flex items-end">
                  <h2 className="font-black mr-3 text-gray-700 text-lg">Notices</h2>
                  <Badge color="gray">{completedNoticesCount} / {notices.length} completed</Badge>
                </div>
                <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
                  {notices.map(patientFormSubmission => (
                    <li key={patientFormSubmission.id}>
                      <PatientFormTile
                        patientPacketSubmissionId={patientPacketSubmissionId}
                        patientFormSubmission={patientFormSubmission}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            {hasForms ? (
              <div className="pt-5">
                <div className="pt-2 py-4 flex items-end">
                  <h2 className="font-black mr-3 text-gray-700 text-lg">Forms</h2>
                  <Badge color="gray">{completedFormsCount} / {forms.length} completed</Badge>
                </div>

                <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
                  {forms.map(patientFormSubmission => (
                    <li key={patientFormSubmission.id}>
                      <PatientFormTile
                        patientPacketSubmissionId={patientPacketSubmissionId}
                        patientFormSubmission={patientFormSubmission}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </>
  );
};

function getLogoUrl(data: undefined | Data) {
  if (!data) return null;

  const form = data.patientPacketSubmission.patientFormSubmissions.find(pfs => !!pfs.patientForm.logoUrl);
  if (!form) return null;

  return form.patientForm.logoUrl;
}
