import React, { FC } from 'react';
import { useField, ErrorMessage } from 'formik';
import {
  ToggleSwitch,
  ToggleSwitchProps,
} from '../../ToggleSwitch/ToggleSwitch';
import {
  VerticalField,
  HorizontalField,
} from '../../FieldStructure/FieldStructure';
import { compose, ValidateProp } from '../validation';

/**
 * ToggleSwitchInput.
 */

interface ToggleSwitchInputProps {
  id?: string;
  name: string;
  toggleSwitchProps?: Partial<ToggleSwitchProps>;
  validate?: ValidateProp;
}

export const ToggleSwitchInput: FC<ToggleSwitchInputProps> = (props) => {
  const { id, name, toggleSwitchProps = {}, validate: validators } = props;

  const validate = validators && compose([validators].flat());
  const [field, , helpers] = useField({ name, validate });
  const { value } = field;
  const { setValue } = helpers;

  function handleChange() {
    setValue(!value);
  }

  return (
    <>
      <ToggleSwitch
        id={id || name}
        checked={!!value}
        onChange={handleChange}
        {...toggleSwitchProps}
      />
      <ErrorMessage
        component="p"
        name={name}
        className="mt-2 text-xs italic text-red-500"
      />
    </>
  );
};

/**
 * ToggleSwitchField.
 */

interface ToggleSwitchFieldProps extends ToggleSwitchInputProps {
  label: string;
  indicateOptional?: boolean;
  indicateRequired?: boolean;
}

export const ToggleSwitchField: FC<ToggleSwitchFieldProps> = (props) => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;

  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <ToggleSwitchInput {...rest} />
    </VerticalField>
  );
};

export const HorizontalToggleSwitchField: FC<ToggleSwitchFieldProps> = (
  props
) => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;

  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <ToggleSwitchInput {...rest} />
    </HorizontalField>
  );
};
