import React, { useCallback } from "react";
import cx from "classnames";
import "./ToothSelector.css";

type ToothSelectorProps = {
  value: string[];
  editable?: boolean;
  onChange?: (value: string[]) => void;
  className?: string;
};

const ToothSelector: React.FC<ToothSelectorProps> = ({
  value = [],
  onChange,
  editable = true,
  className
}) => {
  const toggleTooth = useCallback(
    (tooth: string) => {
      if (editable && onChange) {
        if (value.includes(tooth)) {
          const newValue = value.filter(t => t !== tooth);
          onChange(newValue);
        } else {
          onChange([...value, tooth]);
        }
      }
    },
    [value, onChange, editable]
  );
  return (
    <div className={cx("ToothSelector", className)}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 950.74 805.99">
        <title>teeth</title>
        <g
          id="teeth"
          data-name="teeth"
          className={editable ? "ToothSelector--editable" : undefined}
        >
          <line
            id="vertical"
            className="ToothSelector__axis_line"
            x1="480.89"
            y1="37.97"
            x2="480.89"
            y2="757.37"
          />
          <line
            id="horizonal"
            className="ToothSelector__axis_line"
            x1="69.56"
            y1="411.15"
            x2="896.1"
            y2="411.15"
          />
          <text
            className="ToothSelector__chart_label"
            transform="translate(0 418.18)"
          >
            Right
          </text>
          <text
            className="ToothSelector__chart_label"
            transform="translate(908.21 418.18)"
          >
            Left
          </text>
          <text
            className="ToothSelector__chart_label"
            transform="translate(362.32 20.57)"
          >
            Upper Jaw / Maxillary
          </text>
          <text
            className="ToothSelector__chart_label"
            transform="translate(348.41 799.48)"
          >
            Lower Jaw / Mandibular
          </text>
          <g id="bottom_right">
            <g
              id="c32"
              className={
                value.includes("32")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("32")}
            >
              <g id="t32" data-spec-id="t32" className="ToothSelector__tooth">
                <path
                  id="tr32"
                  d="M82.91,487.86l27.42-3.62,23.06,4.25c-.38,16.26-2.63,32-10.47,46.44-6.06,11.15-14.65,19.72-26.52,24.73-2.57,1.08-3.65.43-3.29-2.22a16.59,16.59,0,0,1,2.91-7c5.33-8,8-16.95,9.93-26.18a3.36,3.36,0,0,0-.7-3.31v-.74c-2.19,1.88-3.1,4.62-4.35,7a70.9,70.9,0,0,1-20.74,24.65c-3.58,2.68-7.74,3.4-12.15,3-1.62-.15-3.17-.57-3.62-2.24a3.75,3.75,0,0,1,1.39-3.69c3.06-3,6.38-5.63,8.91-9.17,5-7,7.06-15,8.46-23.25,1.59-9.36.19-18.7-.17-28,0-.22,0-.43-.07-.64"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tt32"
                  d="M133.45,487.86c-5.11,2.9-11.51-1.1-17.16.16a11.27,11.27,0,0,0-2.89.91c-7.51,4.25-15.49,4.19-23.63,2.87-2.44-.39-4-2.48-6.27-3.31l-.59-.63c-.41-2.51-2.48-4.91-3-7.5-.91-4.55-1.11-9.22,1-13.62,1.67-3.49,3.57-4.36,7.38-3.73a6.64,6.64,0,0,0,4.07-.64c5.86-2.81,12-3.39,18-.73a7.08,7.08,0,0,0,5.91-.09,31.88,31.88,0,0,1,12.92-2.94c6.27.07,11.12,5.17,10.26,11.44C139.48,470.05,138.4,482.19,133.45,487.86Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="l32" data-name="label">
                <circle
                  id="e32"
                  className="ToothSelector__number_circle"
                  cx="106.89"
                  cy="433.18"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(94.33 439.5)"
                >
                  32
                </text>
              </g>
            </g>
            <g
              id="c31"
              className={
                value.includes("31")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("31")}
            >
              <g id="t31" data-spec-id="t31" className="ToothSelector__tooth">
                <path
                  id="tr31"
                  d="M147.71,488.92c-1.12,3-.05,6,.49,8.83,2.19,11.51,3.07,23,1.64,34.67a49.07,49.07,0,0,1-2.93,10.68c-1.47,4-3.3,7.93-5.13,11.81-1.61,3.45-1.46,7.07-1.46,10.7a4.28,4.28,0,0,0,2.28,3.95c1.2.59,2.5-.4,3.67-1.32,7.11-5.66,11.17-13.61,15.74-21.11,3.05-5,5.32-10.5,8.57-15.43.93-1.41,1.87-3.71,3.63-3.16,2.14.66,1,2.94.8,4.63a98.57,98.57,0,0,1-7.09,27.25c-1.21,3-1.13,5.9-1,8.88.09,1.9,1.27,2.37,3.27,1.9,5.71-1.34,8.82-5.44,11.84-10,4.72-7.11,6.49-15.28,8.76-23.27a289.49,289.49,0,0,0,7-32.44c.78-4.76,2.45-9.43,1.9-14.38a4,4,0,0,1,0-1Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tt31"
                  d="M199.72,490.09a10.26,10.26,0,0,1,1.52-3.34c3.41-5.9,3.24-12.68,1.8-18.39-2.5-10-9.32-12.25-19.18-7.06a49.19,49.19,0,0,0-5.54,3.67,5.12,5.12,0,0,1-6.65,0c-3.17-2.39-7-3.28-10.71-3.94-6.19-1.11-11.58,2.11-14,8a22.82,22.82,0,0,0-.95,14.71c1.85,6.74,7.2,10.56,13.86,12.13a17.71,17.71,0,0,0,10.33-1c3.91-1.44,7.89-2.18,12.17-.86,6.2,1.92,11.83.14,16.94-3.62Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="l31" data-name="label">
                <circle
                  id="e31"
                  className="ToothSelector__number_circle"
                  cx="171.11"
                  cy="433.18"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(158.56 439.5)"
                >
                  31
                </text>
              </g>
            </g>
            <g
              id="c30"
              className={
                value.includes("30")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("30")}
            >
              <g id="t30" data-spec-id="t30" className="ToothSelector__tooth">
                <path
                  id="tr30"
                  d="M269.34,495.34c0,1.05-.18,1.79-.28,2.74-.76,7.12-1.84,14.26-.64,21.38a76.65,76.65,0,0,1-.05,25.82c-2.16,12.65-7.18,23.72-17.7,31.71-3.21,2.43-4.94,1.93-6.23-1.85a15,15,0,0,1-.4-2.94c-.6-4.31,1.64-7.77,3.3-11.44,3.58-7.94,4.82-16,1.23-24.37-.79-1.87-1.72-2.8-3.43-2.84s-2.66.79-3.39,2.48c-1.2,2.82-2.94,5.41-4.34,8.15a266.68,266.68,0,0,1-13.21,24c-4.1,6.35-9.79,10.56-15.7,14-.72,0-.89.06-1,0-4.4-3.95-2.68-8.75-1-12.85a115.6,115.6,0,0,0,8.36-32.5c.8-7.73,1.53-15.43.78-23.23-.5-5.14-.4-11.47-.64-16.64l25.83-5.79Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tt30"
                  d="M276.51,473c0-5.77-2-10.52-7.08-13.19-4.81-2.51-9.61-.78-13.92,1.83-1.63,1-3.58,1.64-4.57,3.42-10.79-7.4-18.87-6-27.55,1.3-7-3.47-12.37-.65-12.86,7-.47,7.33.26,14.54,3.26,21.38,1.56,3.57,4.59,5.62,8.49,5.1a36.66,36.66,0,0,1,7-.27A28.14,28.14,0,0,0,243.67,497a8.6,8.6,0,0,1,5.88-.66,32.77,32.77,0,0,0,19.79-1C275.86,485.61,276.51,479.28,276.51,473Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="l30" data-name="label">
                <circle
                  id="e30"
                  className="ToothSelector__number_circle"
                  cx="236.45"
                  cy="433.18"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(223.89 439.5)"
                >
                  30
                </text>
              </g>
            </g>
            <g
              id="c29"
              className={
                value.includes("29")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("29")}
            >
              <g id="t29" data-spec-id="t29" className="ToothSelector__tooth">
                <path
                  id="tr29"
                  d="M316.58,496.34a35.87,35.87,0,0,0-.72,4.41c-.92,10.08-1.66,20.15-2,30.25a124.26,124.26,0,0,1-7.51,39.79c-1.83,4.87-3.74,9.88-7.65,13.67-2.58,2.5-4.44,2-5.52-1.48a16.16,16.16,0,0,1-.78-5.15c.28-11.83.48-23.72-1.57-35.38s-4-23.1-5.59-34.7c-.49-3.56-1.72-7.31-.55-11Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tt29"
                  d="M285.42,496.34c2.61,2.07,4.15,5.13,7,7.05,5.54,3.75,13.61,2.63,18.49-1.86,1.66-1.54,2.67-3.72,4.77-4.82l.92-.37c1.12-5,3-9.68,3.49-14.75.19-2.08.28-4.17.38-6.26a9.42,9.42,0,0,0-3.69-8.48,79.15,79.15,0,0,0-11.82-7.33,8.07,8.07,0,0,0-8.06.35c-3.41,2.08-6.54,4.61-10,6.55-4.2,2.35-5.1,6.14-5.51,10.38-.68,6.89,2.24,13.07,3.7,19.54Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="l29" data-name="label">
                <circle
                  id="e29"
                  className="ToothSelector__number_circle"
                  cx="295.99"
                  cy="433.18"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(283.43 439.5)"
                >
                  29
                </text>
              </g>
            </g>
            <g
              id="c28"
              className={
                value.includes("28")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("28")}
            >
              <g id="t28" data-spec-id="t28" className="ToothSelector__tooth">
                <path
                  id="tr28"
                  d="M358.7,494c-.1,1.21-.13,2.44-.19,3.67-.3,6.9-1.45,13.78-2.43,20.65a327.45,327.45,0,0,1-6.94,35.78c-1.74,6.71-3.7,13.34-5.95,19.9a23.21,23.21,0,0,1-5.53,8.84c-3,3-5,2.51-6.62-1.47s-1.41-7.73-.81-11.81c2.37-16.14,2.46-32.42,1.39-48.65-.49-7.38-.9-14.87-2.75-22.14a8.43,8.43,0,0,1,.32-2.94Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tt28"
                  d="M358,494.38c-2,1-2.84,3.05-4.42,4.43-7.37,6.44-14.82,5.49-21-.45s-7.27-14.21-7.37-22.42c0-2.88,1.86-5.17,4-7,3.74-3.16,7.46-6.36,11.46-9.16a5.76,5.76,0,0,1,6.93-.06c3.76,2.86,7.45,5.82,11.07,8.84,3.84,3.21,4.23,7.71,3.58,12.14a30,30,0,0,1-2.33,7.82A16.21,16.21,0,0,0,358.7,494Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="l28" data-name="label">
                <circle
                  id="e28"
                  className="ToothSelector__number_circle"
                  cx="338.01"
                  cy="433.18"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(325.45 439.5)"
                >
                  28
                </text>
              </g>
            </g>
            <g
              id="c27"
              className={
                value.includes("27")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("27")}
            >
              <g id="t27" data-spec-id="t27" className="ToothSelector__tooth">
                <path
                  id="tr27"
                  d="M400.68,505c-.25,2.57-.19,5.23-.28,7.82-.38,10.36-1.34,20.66-2.15,31a203.45,203.45,0,0,1-6,36.49c-2.08,7.79-4.61,15.44-11,21a22.48,22.48,0,0,1-4.43,3c-1,.54-2.17.76-2.9-.07a3.35,3.35,0,0,1-.54-2.91,216,216,0,0,0,2.41-43.5c-.77-15.67-2.37-31.25-4.1-46.82a8.15,8.15,0,0,1,.33-4.42Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tt27"
                  d="M400,505.41c-1.47,1.6-2.84,3.31-4.44,4.77-5.68,5.2-13.54,6.4-19.59-.28-5.14-5.67-6.94-12.4-8.09-19.6-.64-4-1-7.83-.06-11.83a12.43,12.43,0,0,1,1.53-3c3.62-7.08,9.52-11.88,15.92-16.18,1.51-1,3-.92,4.81-.08,13.68,6.33,19.41,17.41,14.35,32.88-1,3.11-2.36,6.15-3.16,9.26a23.42,23.42,0,0,0-.6,3.56Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="l27" data-name="label">
                <circle
                  id="e27"
                  className="ToothSelector__number_circle"
                  cx="381.59"
                  cy="433.18"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(369.03 439.5)"
                >
                  27
                </text>
              </g>
            </g>
            <g
              id="c26"
              className={
                value.includes("26")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("26")}
            >
              <g id="t26" data-spec-id="t26" className="ToothSelector__tooth">
                <path
                  id="tr26"
                  d="M433.88,500c1.73,1.87.26,4,.31,5.9.16,5.78.24,11.56,0,17.33-.46,12.06-.77,24.12-2,36.15a152,152,0,0,1-4.1,24,19.12,19.12,0,0,1-3.84,6.79c-2.6-1.45-3.27-3.89-3.22-6.45.28-15.05-1-30-2.29-45-.87-10.1-2.4-20.14-3.22-30.24-.23-2.84-.58-5.66-1-8.48Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tt26"
                  d="M414.52,500c-1.56-10.9-3.92-21.71-3.46-32.81.27-6.54,3.44-9,8.45-8.57,4.51.4,9.08.08,13.63.09,3.12,0,4.72,1.6,4.79,4.79.26,11.74-.1,23.42-4.08,34.65a16.3,16.3,0,0,1-6.34,8.33c-2,1.26-4.86.4-6.89-1.33-1.79-1.52-3.46-3.18-5.18-4.78Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="l26" data-name="label">
                <circle
                  id="e26"
                  className="ToothSelector__number_circle"
                  cx="418.79"
                  cy="433.18"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(406.24 439.5)"
                >
                  26
                </text>
              </g>
            </g>
            <g
              id="c25"
              className={
                value.includes("25")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("25")}
            >
              <g id="t25" data-spec-id="t25" className="ToothSelector__tooth">
                <path
                  id="tr25"
                  d="M447.15,499.75c.81,6.31,1.33,12.65,1.87,19,.72,8.47,1.31,17,1.85,25.44.62,9.57,1.21,19.16,1.09,28.76,0,2.77.65,5,3.12,6.61,3.29-1.42,4-4.68,5-7.72,2.64-7.77,3.66-15.91,4.3-24,1.19-15,2.18-29.94,1.95-45,0-1.11-.23-2.21-.35-3.32l-8.77-2.17Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tt25"
                  d="M447.94,499.93a11.76,11.76,0,0,1,2.19,1.5c3,3.18,6.3,3,10,1.48,2.14-.9,3.14-3.1,5.15-4.08h.37c.8-7,3.25-13.61,3.73-20.65a134.57,134.57,0,0,0,.65-14.75c-.14-3.52-1.57-4.75-5.12-4.78-5.16,0-10.32-.07-15.49,0-4.29.07-6.84,3.15-6.67,7.36.43,10.46,2.55,20.67,4,31,.13.92.25,1.84.37,2.76Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="l25" data-name="label">
                <circle
                  id="e25"
                  className="ToothSelector__number_circle"
                  cx="451.69"
                  cy="433.18"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(439.13 439.5)"
                >
                  25
                </text>
              </g>
            </g>
          </g>
          <g id="bottom_left">
            <g
              id="c24"
              className={
                value.includes("24")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("24")}
            >
              <g id="t24" data-spec-id="t24" className="ToothSelector__tooth">
                <path
                  id="tr24"
                  d="M524.46,499.75c-.81,6.31-1.33,12.65-1.86,19-.72,8.47-1.31,17-1.86,25.44-.61,9.57-1.21,19.16-1.08,28.76,0,2.77-.65,5-3.12,6.61-3.3-1.42-4-4.68-5-7.72-2.65-7.77-3.66-15.91-4.3-24-1.19-15-2.19-29.94-1.95-45,0-1.11.23-2.21.35-3.32l8.76-2.17Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tt24"
                  d="M523.68,499.93a11.44,11.44,0,0,0-2.19,1.5c-3,3.18-6.31,3-10,1.48-2.15-.9-3.14-3.1-5.15-4.08H506c-.81-7-3.25-13.61-3.74-20.65a137.1,137.1,0,0,1-.65-14.75c.14-3.52,1.58-4.75,5.13-4.78,5.16,0,10.32-.07,15.48,0,4.29.07,6.84,3.15,6.67,7.36-.42,10.46-2.54,20.67-4,31-.13.92-.26,1.84-.38,2.76Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="l24" data-name="label">
                <circle
                  id="e24"
                  className="ToothSelector__number_circle"
                  cx="508.85"
                  cy="433.18"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(496.3 439.5)"
                >
                  24
                </text>
              </g>
            </g>
            <g
              id="c23"
              className={
                value.includes("23")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("23")}
            >
              <g id="t23" data-spec-id="t23" className="ToothSelector__tooth">
                <path
                  id="tr23"
                  d="M537.74,500c-1.73,1.87-.26,4-.32,5.9-.15,5.78-.24,11.56,0,17.33.46,12.06.76,24.12,2,36.15a153.08,153.08,0,0,0,4.09,24,19.28,19.28,0,0,0,3.84,6.79c2.61-1.45,3.27-3.89,3.23-6.45-.28-15.05,1-30,2.29-45,.87-10.1,2.39-20.14,3.22-30.24.23-2.84.58-5.66,1-8.48Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tt23"
                  d="M557.1,500c1.55-10.9,3.91-21.71,3.46-32.81-.27-6.54-3.45-9-8.45-8.57-4.51.4-9.09.08-13.64.09-3.11,0-4.71,1.6-4.78,4.79-.26,11.74.1,23.42,4.08,34.65a16.25,16.25,0,0,0,6.34,8.33c2,1.26,4.86.4,6.89-1.33,1.78-1.52,3.45-3.18,5.17-4.78Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="l23" data-name="label">
                <circle
                  id="e23"
                  className="ToothSelector__number_circle"
                  cx="541.79"
                  cy="433.18"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(529.23 439.5)"
                >
                  23
                </text>
              </g>
            </g>
            <g
              id="c22"
              className={
                value.includes("22")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("22")}
            >
              <g id="t22" data-spec-id="t22" className="ToothSelector__tooth">
                <path
                  id="tr22"
                  d="M570.94,505c.24,2.57.18,5.23.28,7.82.38,10.36,1.34,20.66,2.15,31a201.68,201.68,0,0,0,6,36.49c2.08,7.79,4.6,15.44,11,21a22.55,22.55,0,0,0,4.44,3c1,.54,2.16.76,2.89-.07a3.35,3.35,0,0,0,.55-2.91,216,216,0,0,1-2.42-43.5c.77-15.67,2.38-31.25,4.11-46.82a8.15,8.15,0,0,0-.33-4.42Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tt22"
                  d="M571.6,505.41c1.48,1.6,2.85,3.31,4.45,4.77,5.67,5.2,13.54,6.4,19.59-.28,5.14-5.67,6.93-12.4,8.09-19.6.64-4,1-7.83,0-11.83a12.41,12.41,0,0,0-1.52-3c-3.62-7.08-9.52-11.88-15.92-16.18-1.51-1-3-.92-4.81-.08-13.68,6.33-19.42,17.41-14.36,32.88,1,3.11,2.36,6.15,3.17,9.26a23.42,23.42,0,0,1,.6,3.56Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="l22" data-name="label">
                <circle
                  id="e22"
                  className="ToothSelector__number_circle"
                  cx="579.14"
                  cy="433.18"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(566.58 439.5)"
                >
                  22
                </text>
              </g>
            </g>
            <g
              id="c21"
              className={
                value.includes("21")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("21")}
            >
              <g id="t21" data-spec-id="t21" className="ToothSelector__tooth">
                <path
                  id="tr21"
                  d="M612.92,494c.09,1.21.13,2.44.18,3.67.31,6.9,1.46,13.78,2.43,20.65a327.49,327.49,0,0,0,7,35.78c1.74,6.71,3.7,13.34,5.94,19.9a23.36,23.36,0,0,0,5.54,8.84c2.94,3,5,2.51,6.62-1.47s1.41-7.73.81-11.81c-2.37-16.14-2.46-32.42-1.39-48.65.49-7.38.89-14.87,2.75-22.14a8.67,8.67,0,0,0-.32-2.94Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tt21"
                  d="M613.67,494.38c2,1,2.84,3.05,4.42,4.43,7.36,6.44,14.82,5.49,20.95-.45s7.28-14.21,7.37-22.42c0-2.88-1.86-5.17-4-7-3.74-3.16-7.47-6.36-11.47-9.16a5.76,5.76,0,0,0-6.93-.06c-3.76,2.86-7.44,5.82-11.06,8.84-3.85,3.21-4.24,7.71-3.58,12.14a29.64,29.64,0,0,0,2.33,7.82,16.45,16.45,0,0,1,1.22,5.48Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="l21" data-name="label">
                <circle
                  id="e21"
                  className="ToothSelector__number_circle"
                  cx="621.46"
                  cy="433.18"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(608.9 439.5)"
                >
                  21
                </text>
              </g>
            </g>
            <g
              id="c20"
              className={
                value.includes("20")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("20")}
            >
              <g id="t20" data-spec-id="t20" className="ToothSelector__tooth">
                <path
                  id="tr20"
                  d="M655,496.34a32.43,32.43,0,0,1,.72,4.41c.92,10.08,1.67,20.15,2,30.25a124.26,124.26,0,0,0,7.51,39.79c1.82,4.87,3.73,9.88,7.65,13.67,2.57,2.5,4.44,2,5.51-1.48a15.62,15.62,0,0,0,.78-5.15c-.28-11.83-.48-23.72,1.58-35.38s4-23.1,5.59-34.7c.48-3.56,1.71-7.31.55-11Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tt20"
                  d="M686.19,496.34c-2.6,2.07-4.14,5.13-7,7.05-5.53,3.75-13.6,2.63-18.48-1.86-1.67-1.54-2.67-3.72-4.77-4.82l-.93-.37c-1.11-5-3-9.68-3.49-14.75-.19-2.08-.28-4.17-.37-6.26-.15-3.42.87-6.36,3.68-8.48a79.25,79.25,0,0,1,11.83-7.33,8.07,8.07,0,0,1,8.06.35c3.4,2.08,6.54,4.61,10,6.55,4.2,2.35,5.1,6.14,5.51,10.38.67,6.89-2.24,13.07-3.7,19.54Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="l20" data-name="label">
                <circle
                  id="e20"
                  className="ToothSelector__number_circle"
                  cx="664.25"
                  cy="433.18"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(651.69 439.5)"
                >
                  20
                </text>
              </g>
            </g>
            <g
              id="c19"
              className={
                value.includes("19")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("19")}
            >
              <g id="t19" data-spec-id="t19" className="ToothSelector__tooth">
                <path
                  id="tr19"
                  d="M702.28,495.34c0,1.05.17,1.79.27,2.74.77,7.12,1.84,14.26.64,21.38a76.91,76.91,0,0,0,.06,25.82c2.15,12.65,7.18,23.72,17.69,31.71,3.21,2.43,4.95,1.93,6.24-1.85a16.65,16.65,0,0,0,.4-2.94c.6-4.31-1.64-7.77-3.3-11.44-3.58-7.94-4.82-16-1.24-24.37.8-1.87,1.73-2.8,3.43-2.84s2.67.79,3.39,2.48c1.21,2.82,2.95,5.41,4.34,8.15a264.31,264.31,0,0,0,13.22,24c4.1,6.35,9.79,10.56,15.7,14,.71,0,.89.06,1,0,4.41-3.95,2.69-8.75,1-12.85a115.27,115.27,0,0,1-8.36-32.5c-.8-7.73-1.53-15.43-.78-23.23.5-5.14.39-11.47.64-16.64l-25.83-5.79Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tt19"
                  d="M695.1,473c0-5.77,2-10.52,7.08-13.19,4.81-2.51,9.62-.78,13.92,1.83,1.64,1,3.59,1.64,4.58,3.42,10.78-7.4,18.86-6,27.55,1.3,7-3.47,12.37-.65,12.86,7,.47,7.33-.26,14.54-3.27,21.38-1.56,3.57-4.59,5.62-8.48,5.1a36.66,36.66,0,0,0-7-.27A28.14,28.14,0,0,1,728,497a8.61,8.61,0,0,0-5.88-.66,32.77,32.77,0,0,1-19.79-1C695.76,485.61,695.1,479.28,695.1,473Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="l19" data-name="label">
                <circle
                  id="e19"
                  className="ToothSelector__number_circle"
                  cx="722.15"
                  cy="433.18"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(709.59 439.5)"
                >
                  19
                </text>
              </g>
            </g>
            <g
              id="c18"
              className={
                value.includes("18")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("18")}
            >
              <g id="t18" data-spec-id="t18" className="ToothSelector__tooth">
                <path
                  id="tr18"
                  d="M823.91,488.92c1.11,3,.05,6-.49,8.83-2.19,11.51-3.08,23-1.64,34.67a49.62,49.62,0,0,0,2.92,10.68c1.47,4,3.31,7.93,5.13,11.81,1.62,3.45,1.46,7.07,1.46,10.7a4.29,4.29,0,0,1-2.27,3.95c-1.21.59-2.5-.4-3.67-1.32-7.11-5.66-11.18-13.61-15.74-21.11-3.05-5-5.32-10.5-8.57-15.43-.93-1.41-1.87-3.71-3.63-3.16-2.15.66-1,2.94-.8,4.63a98.22,98.22,0,0,0,7.09,27.25c1.2,3,1.13,5.9,1,8.88-.1,1.9-1.28,2.37-3.28,1.9-5.7-1.34-8.81-5.44-11.83-10-4.73-7.11-6.5-15.28-8.77-23.27a289.49,289.49,0,0,1-7-32.44c-.78-4.76-2.44-9.43-1.89-14.38a3.62,3.62,0,0,0,0-1Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tt18"
                  d="M771.89,490.09a9.86,9.86,0,0,0-1.52-3.34c-3.4-5.9-3.23-12.68-1.8-18.39,2.5-10,9.33-12.25,19.19-7.06A49.19,49.19,0,0,1,793.3,465a5.11,5.11,0,0,0,6.64,0c3.18-2.39,7-3.28,10.72-3.94,6.19-1.11,11.57,2.11,14,8a22.82,22.82,0,0,1,1,14.71c-1.85,6.74-7.2,10.56-13.87,12.13a17.67,17.67,0,0,1-10.32-1c-3.91-1.44-7.9-2.18-12.17-.86-6.21,1.92-11.84.14-16.94-3.62Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="l18" data-name="label">
                <circle
                  id="e18"
                  className="ToothSelector__number_circle"
                  cx="789.97"
                  cy="433.18"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(777.41 439.5)"
                >
                  18
                </text>
              </g>
            </g>
            <g
              id="c17"
              className={
                value.includes("17")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("17")}
            >
              <g id="t17" data-spec-id="t17" className="ToothSelector__tooth">
                <path
                  id="tr17"
                  d="M888.71,487.86l-27.42-3.62-23.06,4.25c.37,16.26,2.63,32,10.47,46.44,6,11.15,14.65,19.72,26.51,24.73,2.58,1.08,3.66.43,3.3-2.22a16.61,16.61,0,0,0-2.92-7c-5.32-8-8-16.95-9.93-26.18a3.39,3.39,0,0,1,.7-3.31v-.74c2.2,1.88,3.11,4.62,4.36,7a71,71,0,0,0,20.73,24.65c3.58,2.68,7.75,3.4,12.16,3,1.62-.15,3.17-.57,3.62-2.24a3.79,3.79,0,0,0-1.39-3.69c-3.06-3-6.39-5.63-8.92-9.17-5-7-7.06-15-8.46-23.25-1.58-9.36-.18-18.7.18-28a4.48,4.48,0,0,1,.07-.64"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tt17"
                  d="M838.17,487.86c5.11,2.9,11.51-1.1,17.16.16a11.19,11.19,0,0,1,2.88.91c7.51,4.25,15.49,4.19,23.64,2.87,2.44-.39,4-2.48,6.27-3.31l.59-.63c.4-2.51,2.47-4.91,3-7.5.92-4.55,1.12-9.22-1-13.62-1.68-3.49-3.57-4.36-7.38-3.73a6.67,6.67,0,0,1-4.08-.64c-5.86-2.81-12-3.39-18-.73a7.08,7.08,0,0,1-5.91-.09,31.88,31.88,0,0,0-12.92-2.94c-6.28.07-11.12,5.17-10.26,11.44C832.14,470.05,833.22,482.19,838.17,487.86Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="l17" data-name="label">
                <circle
                  id="e17"
                  className="ToothSelector__number_circle"
                  cx="857.54"
                  cy="433.18"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(844.98 439.5)"
                >
                  17
                </text>
              </g>
            </g>
          </g>
          <g id="upper_left">
            <g
              id="c16"
              className={
                value.includes("16")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("16")}
            >
              <g id="t16" data-spec-id="t16" className="ToothSelector__tooth">
                <path
                  id="tr16b"
                  d="M881.63,262.38a2.12,2.12,0,0,1,3.53,1.42c.28,2.29-.56,5.8-1.54,8.42-3.93,10.46-10.74,17.36-13.19,27.86-1,4.11-3.56,8.7-3,13.05l-5-7.43V285s5.22-8,7.71-10.85A43.12,43.12,0,0,1,881.63,262.38Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tr16a"
                  d="M864.55,300.84c-1.22-8.36,1.52-15,4.51-22.67,1.08-2.76,1.26-5.23-1.2-7.63-5.66,3.21-9.32,8.19-12.43,13.54-4.89,8.41-6.86,17.76-8.14,27.27-1,7.7-2.07,15.41-1.48,23.23l42-3.69a3.56,3.56,0,0,0,.71-3.31c-.54-3.58-1.49-7.12-1.77-10.71-1-13.46-.38-26.79,3.62-39.82a17.56,17.56,0,0,0,1.11-7c-.3-2.86-1.28-3.52-4-2.51-.66.24-7.68,8-9.65,10.93-3,4.44-6.67,14.54-8.07,19.47-.94,3.33-2.95,12.67-2.95,12.67Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tt16"
                  d="M888.21,331.26c4.64,6.09,5.24,12.81,2.93,19.9a15.6,15.6,0,0,1-8.85,9.56c-2.7,1.22-5.3,1.23-7.76-1-3.92-3.62-4.7-5.06-11-.43-3.24,2.37-7,3.95-11,3.62-5.54-.45-10.13-6.16-10.33-12.07-.47-13.35,6.37-21.12,18.45-26.15,8.07-3.36,15.46-1,22.47,3.3,1.51.93,3,2,4.43,2.94Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="l16" data-name="label">
                <circle
                  id="e16"
                  className="ToothSelector__number_circle"
                  cx="864.51"
                  cy="388.41"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(851.95 394.73)"
                >
                  16
                </text>
              </g>
            </g>
            <g
              id="c15"
              className={
                value.includes("15")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("15")}
            >
              <g id="t15" data-spec-id="t15" className="ToothSelector__tooth">
                <path
                  id="tr15b"
                  d="M814.18,253.24a5.68,5.68,0,0,0,2.72-2.79c1-1.82,2.15-4,4.74-3.66,3.12.4,2.88,3.37,3.66,5.49a5.64,5.64,0,0,1,0,1.84l-4.56,54.43-19.68-2.08Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tr15a"
                  d="M814.27,253.75c1.87,4.09-.11,8.69-1.46,12.91-3.8,11.87-5.17,23.94-2.64,36.23l.23.26c3.84-2.37,4.79-6.57,6-10.35a88,88,0,0,0,4.12-31.48c-.21-5.09,2-8,5-6.89,6.33,5.24,8.08,12.65,9.42,20.33,1.45,8.31.26,16.49-.77,24.71-1.13,9.06-1.73,18.17-2.21,27.28l-44.68,5.06a33,33,0,0,0,.88-5.79c.35-6.54,1.52-13,2.1-19.55.54-6.13.17-12.4,1.26-18.41,2.33-12.83,8.8-23.67,17.53-33.25a6.49,6.49,0,0,1,2.78-1.65C812.48,252.92,814,253.18,814.27,253.75Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tt15"
                  d="M787.29,331.81c-1.63,5.15-3,9.92-3,15.22,0,6.16,2.71,11.58,8.21,14.38a15.29,15.29,0,0,0,16.59-2.21c4.11-3.68,4.11-3.68,9.59-1.11,4.54,2.14,12.07-.43,16.22-5.53,6.31-7.76,2.86-21.89-5.82-27.06-6.62-3.94-13.26-2.64-20-1.88a28.79,28.79,0,0,1-4.08.5c-6.22.68-10.59,2-15.42,5.39C789,329.94,787.54,331,787.29,331.81Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="l15" data-name="label">
                <circle
                  id="e15"
                  className="ToothSelector__number_circle"
                  cx="809.17"
                  cy="388.41"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(796.62 394.73)"
                >
                  15
                </text>
              </g>
            </g>
            <g
              id="c14"
              className={
                value.includes("14")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("14")}
            >
              <g id="t14" data-spec-id="t14" className="ToothSelector__tooth">
                <path
                  id="tr14"
                  d="M767.68,260a18.94,18.94,0,0,0-.48-4c-.68-2.92-.3-6-1.49-8.85-1.28-3.06-3.39-4-5.51-2.19a44,44,0,0,0-10.29,13.35l-6.83,15.69-2.15,33.44,21.57-.59Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tr14-2"
                  data-name="tr14"
                  d="M725.56,328.17a59,59,0,0,0,.17-8c.17-9.7-.05-19.41-.36-29.13-.4-12.44,2-24.5,12-33.53a54.83,54.83,0,0,1,5.16-4.06c3.1-2.19,4.91-1.47,5.9,2.2a12.14,12.14,0,0,1,.45,4.84c-1.7,9.91-6,19.21-3.32,28.7a74.62,74.62,0,0,0,4.74,12.21c5.4-6.74,8.39-14.82,11.54-22.61,0,0,4.22-11.95,6.9-18.23a58.92,58.92,0,0,1,3.23-7.17c.78-1.36,1.84-2.72,3.73-2.51,2.09.24,2.84,2.29,3.2,3.69a47.23,47.23,0,0,1,1.34,15.84c-.84,10.34-4.25,20.1-6.08,30.21-1.44,8-.93,16-.92,24a4.37,4.37,0,0,1-.49,2.21Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tt14"
                  d="M726.25,328c2.77-3.43,5.74-4.32,10.7-5.78,2.27-.66,5.65-1.16,7.27-.3,3.42,1.82,6.65,1.16,10.06.94,4.89-.31,9.83-.18,14.34,1.9a11.5,11.5,0,0,1,6.36,6.17,64.3,64.3,0,0,1,4,14c1.32,8.82-1.16,14.55-11.83,17.39a14.33,14.33,0,0,1-13.63-3,25.25,25.25,0,0,1-2.87-3.28c-5,3.47-9.38,7.22-15.21,6.91-7.6-.4-14.65-4.94-14.16-13.93.34-6.26,1.07-12.36,3.52-18A16.19,16.19,0,0,1,726.25,328Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="l14" data-name="label">
                <circle
                  id="e14"
                  className="ToothSelector__number_circle"
                  cx="745.53"
                  cy="388.41"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(732.97 394.73)"
                >
                  14
                </text>
              </g>
            </g>
            <g
              id="c13"
              className={
                value.includes("13")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("13")}
            >
              <g id="t13" data-spec-id="t13" className="ToothSelector__tooth">
                <path
                  id="tr13"
                  d="M686.41,322.93c.51-3.44.26-7.05.61-10.51,2.17-21.3,5-42.46,11.07-63.06a41.81,41.81,0,0,1,4.47-10.68,7.31,7.31,0,0,1,4.25-3.47c2.13,1.92,2.72,4.35,2.78,7.15.27,12.8-1.54,25.57-.71,38.35.8,12.17.59,24.44,2.84,36.51.25,1.31.45,2.86-.69,4Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tt13"
                  d="M686.92,323.21a33.61,33.61,0,0,1,6.37-5.7,12.1,12.1,0,0,1,14.09.77c5.92,4.81,7.41,11.67,8.71,18.49.78,4.08,1.85,8.25.86,12.53-1.13,4.88-3.58,8.7-8.51,10.27-4.11,1.31-8,3.56-12.52,3.33-7.07-.36-12-4.64-15.74-10-1.8-2.55-1.23-6.07-.45-9.12a119.08,119.08,0,0,1,5.07-16.34A19,19,0,0,1,686.92,323.21Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="l13" data-name="label">
                <circle
                  id="e13"
                  className="ToothSelector__number_circle"
                  cx="694.51"
                  cy="388.41"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(681.95 394.73)"
                >
                  13
                </text>
              </g>
            </g>
            <g
              id="c12"
              className={
                value.includes("12")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("12")}
            >
              <g id="t12" data-spec-id="t12" className="ToothSelector__tooth">
                <path
                  id="tr12"
                  d="M670.53,321.24c1-1.62.8-3.42.73-5.17-.34-8.23-1-16.46-1-24.7-.08-10.81-.37-21.63.27-32.45.27-4.54,1.38-9.06.41-13.64a10,10,0,0,0-6.69-7.59c-3.05-1-6.22.11-8.42,2.81-2,2.45-4,4.9-4.83,8.09a296.17,296.17,0,0,0-6.41,29.85,243.23,243.23,0,0,0-3,39.84,33.75,33.75,0,0,1-.18,4.65Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tt12"
                  d="M642.42,323.47a27.63,27.63,0,0,1,6.39-7.35,13,13,0,0,1,16.92.71c8.89,8.29,10.35,18.89,8.75,30.2-.29,2.06-1.79,4-3.53,5.59a46.8,46.8,0,0,1-9,6.49,13,13,0,0,1-12.52.19c-4.17-2-8.13-4.17-11-7.85-4-5.15-3-11.08-1.35-16.54A60.92,60.92,0,0,1,642.42,323.47Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="l12" data-name="label">
                <circle
                  id="e12"
                  className="ToothSelector__number_circle"
                  cx="650.83"
                  cy="388.41"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(638.27 394.73)"
                >
                  12
                </text>
              </g>
            </g>
            <g
              id="c11"
              className={
                value.includes("11")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("11")}
            >
              <g id="t11" data-spec-id="t11" className="ToothSelector__tooth">
                <path
                  id="tr11"
                  d="M596.72,322a5,5,0,0,1-.59-3.38c1.38-17,3.35-34,6.08-50.86,2-12.52,4.47-25,10.24-36.52a32.87,32.87,0,0,1,7.81-9.89c1.09-1,2.78-2.18,4.32-1.45s1.88,2.58,1.9,4.34a31.19,31.19,0,0,1-.72,7.75c-1.9,7.88-1.4,16-1.37,23.95.07,19.06.87,38.1,1.32,57.16.06,2.7,1.65,5.4,0,8.11Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tt11"
                  d="M596.6,322.28c-3.56,8.16-6.34,16.57-7.34,25.45-.72,6.41,2.12,11.69,5.87,16.59a15.64,15.64,0,0,0,21.4,3.33,74.12,74.12,0,0,1,7.74-4.77c7-3.6,8.23-9.74,7.31-16.62-1-7.52-2.25-15.05-5.15-22.12-2.59-6.3-7.23-10.7-14-12.08-3.49-.71-6.6,1.26-9.18,3.61-2.25,2.05-4.32,4.28-6.46,6.42Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="l11" data-name="label">
                <circle
                  id="e11"
                  className="ToothSelector__number_circle"
                  cx="607.31"
                  cy="388.41"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(594.75 394.73)"
                >
                  11
                </text>
              </g>
            </g>
            <g
              id="c10"
              className={
                value.includes("10")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("10")}
            >
              <g id="t10" data-spec-id="t10" className="ToothSelector__tooth">
                <path
                  id="tr10"
                  d="M581.15,320.86c1.23-3.81.11-7.65-.11-11.42-.9-14.87-1.86-29.69-.78-44.63.57-7.85,2.89-15.22,4.14-22.86.4-2.42.37-5.06-1.83-7.13-9.1,3.9-13.24,11.75-16.13,20.43-3.94,11.84-5.81,24.13-7.49,36.48-1.2,8.83-2,17.69-3,26.54a8.84,8.84,0,0,0,.49,3.33Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tt10"
                  d="M556.44,322a80,80,0,0,0-6.61,31.34c-.05,6.63,3.39,10.09,9.93,9.91,5.67-.15,11.39.79,17-1.37,6.91-2.67,8.85-4.69,8.39-11.48-.52-7.9-.94-15.79-2.38-23.63-1-5.41-4.6-8.35-8.29-11.35s-8.24-2.51-11.77.3c-2.2,1.75-3.41,4.46-5.89,5.91Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="l10" data-name="label">
                <circle
                  id="e10"
                  className="ToothSelector__number_circle"
                  cx="563.08"
                  cy="388.41"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(550.52 394.73)"
                >
                  10
                </text>
              </g>
            </g>
            <g
              id="c9"
              className={
                value.includes("9")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("9")}
            >
              <g id="t9" data-spec-id="t9" className="ToothSelector__tooth">
                <path
                  id="tr9"
                  d="M509.71,322.48c-1.47-2.08-.9-4.49-.66-6.63,1.31-11.94,2.4-23.94,4.48-35.75s4.1-23.67,10.94-33.94c1.41-2.13,2.62-4.54,4.48-6.2,4.26-3.81,5.68-4,8.71,1.81,1.91,3.65,1,7.87.7,11.75A271.48,271.48,0,0,0,538,283c.29,9.49,1.46,18.91,1.85,28.39.13,2.93,1.9,5.89.06,8.84Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tt9"
                  d="M540.31,320.63c4.79,11.34,5.92,23.27,5.5,35.4-.27,7.57-4.42,12.55-12.87,12.57-8.23,0-16.47-.34-24.71.28-4.2.31-7.26-3.44-6.59-8.05,1.71-11.77,3.16-23.62,6.71-35,1.64-5.27,10.54-15,17.59-14.12,4.19.52,7.35,2.55,10.33,5.22,1.17,1.05,1.72,2.71,3.31,3.33Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="l9" data-name="label">
                <circle
                  id="e9"
                  className="ToothSelector__number_circle"
                  cx="516.37"
                  cy="388.41"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(510.09 394.73)"
                >
                  9
                </text>
              </g>
            </g>
          </g>
          <g id="upper_right">
            <g
              id="c8"
              className={
                value.includes("8")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("8")}
            >
              <g id="t8" data-spec-id="t8" className="ToothSelector__tooth">
                <path
                  id="tr8"
                  d="M461.91,321.07c1.47-2.08.89-4.48.66-6.63-1.32-11.94-2.4-23.94-4.48-35.75S454,255,447.15,244.75c-1.42-2.13-2.62-4.54-4.48-6.2-4.27-3.81-5.68-4-8.72,1.81-1.9,3.65-1,7.87-.7,11.75a269,269,0,0,1,.34,29.51c-.29,9.49-1.46,18.91-1.86,28.39-.12,2.93-1.89,5.9-.06,8.84Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tt8"
                  d="M431.3,319.22c-4.79,11.34-5.91,23.27-5.49,35.4.27,7.57,4.42,12.56,12.87,12.58,8.23,0,16.47-.35,24.71.27,4.2.31,7.26-3.44,6.59-8.05-1.71-11.77-3.17-23.62-6.71-35-1.64-5.26-10.54-15-17.6-14.12-4.18.52-7.34,2.55-10.32,5.22-1.18,1.05-1.72,2.71-3.31,3.33Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="l8" data-name="label">
                <circle
                  id="e8"
                  className="ToothSelector__number_circle"
                  cx="446.41"
                  cy="388.41"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(440.13 394.73)"
                >
                  8
                </text>
              </g>
            </g>
            <g
              id="c7"
              className={
                value.includes("7")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("7")}
            >
              <g id="t7" data-spec-id="t7" className="ToothSelector__tooth">
                <path
                  id="tr7"
                  d="M390.47,319.46c-1.23-3.81-.12-7.66.11-11.43.89-14.87,1.85-29.69.78-44.63-.57-7.85-2.89-15.22-4.14-22.86-.4-2.42-.37-5.06,1.82-7.12,9.1,3.89,13.25,11.74,16.14,20.42,3.94,11.85,5.8,24.14,7.48,36.48,1.21,8.83,2,17.69,3,26.54a8.84,8.84,0,0,1-.49,3.33Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tt7"
                  d="M415.17,320.56a79.82,79.82,0,0,1,6.61,31.34c.06,6.63-3.38,10.1-9.92,9.92-5.68-.16-11.39.78-17-1.38-6.92-2.67-8.85-4.69-8.4-11.48.53-7.89.94-15.79,2.39-23.63,1-5.4,4.6-8.35,8.29-11.35s8.24-2.51,11.77.3c2.2,1.75,3.4,4.46,5.88,5.91Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="l7" data-name="label">
                <circle
                  id="e7"
                  className="ToothSelector__number_circle"
                  cx="402.04"
                  cy="388.41"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(395.76 394.73)"
                >
                  7
                </text>
              </g>
            </g>
            <g
              id="c6"
              className={
                value.includes("6")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("6")}
            >
              <g id="t6" data-spec-id="t6" className="ToothSelector__tooth">
                <path
                  id="tr6"
                  d="M374.9,320.56a5.05,5.05,0,0,0,.59-3.38c-1.39-17-3.35-34-6.08-50.86-2-12.52-4.48-25-10.24-36.52a33,33,0,0,0-7.81-9.89c-1.09-1-2.79-2.17-4.33-1.44s-1.88,2.57-1.9,4.33a31.15,31.15,0,0,0,.73,7.75c1.9,7.89,1.39,16,1.36,24-.07,19.05-.86,38.1-1.31,57.15-.07,2.7-1.66,5.4,0,8.11Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tt6"
                  d="M375,320.87c3.56,8.17,6.35,16.57,7.34,25.45.72,6.41-2.11,11.69-5.86,16.59a15.64,15.64,0,0,1-21.4,3.33,75.31,75.31,0,0,0-7.75-4.77c-7-3.59-8.22-9.74-7.3-16.62,1-7.52,2.24-15.05,5.15-22.12,2.59-6.3,7.23-10.7,14-12.07,3.49-.71,6.6,1.25,9.18,3.6,2.24,2.05,4.32,4.28,6.46,6.42Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="l6" data-name="label">
                <circle
                  id="e6"
                  className="ToothSelector__number_circle"
                  cx="357.89"
                  cy="388.41"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(351.61 394.73)"
                >
                  6
                </text>
              </g>
            </g>
            <g
              id="c5"
              className={
                value.includes("5")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("5")}
            >
              <g id="t5" data-spec-id="t5" className="ToothSelector__tooth">
                <path
                  id="tr5"
                  d="M301.08,319.83c-1-1.62-.79-3.42-.72-5.16.34-8.24,1-16.47,1-24.71.08-10.81.37-21.63-.27-32.45-.27-4.53-1.39-9-.42-13.64a10,10,0,0,1,6.7-7.59c3.05-1,6.22.11,8.42,2.81,2,2.45,4,4.9,4.83,8.09A299,299,0,0,1,327.06,277a242.16,242.16,0,0,1,3,39.85,35.31,35.31,0,0,0,.18,4.64Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tt5"
                  d="M329.2,322.07a27.54,27.54,0,0,0-6.4-7.36,13,13,0,0,0-16.91.71c-8.89,8.29-10.35,18.89-8.75,30.2.29,2.06,1.78,4,3.53,5.59a46.37,46.37,0,0,0,8.95,6.49,13,13,0,0,0,12.52.19c4.17-2,8.13-4.17,11-7.85,4-5.15,3-11.08,1.35-16.54A61.79,61.79,0,0,0,329.2,322.07Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="l5" data-name="label">
                <circle
                  id="e5"
                  className="ToothSelector__number_circle"
                  cx="312.01"
                  cy="388.41"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(305.73 394.73)"
                >
                  5
                </text>
              </g>
            </g>
            <g
              id="c4"
              className={
                value.includes("4")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("4")}
            >
              <g id="t4" data-spec-id="t4" className="ToothSelector__tooth">
                <path
                  id="tr4"
                  d="M285.21,321.52c-.51-3.44-.26-7-.62-10.51-2.16-21.29-5-42.45-11.07-63.06a41.58,41.58,0,0,0-4.46-10.68,7.31,7.31,0,0,0-4.25-3.47c-2.13,1.92-2.72,4.35-2.78,7.16-.27,12.79,1.53,25.56.7,38.34-.79,12.18-.59,24.44-2.84,36.51-.24,1.31-.45,2.86.69,4.05Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tt4"
                  d="M284.7,321.8a33.92,33.92,0,0,0-6.37-5.7,12.1,12.1,0,0,0-14.09.77c-5.92,4.81-7.42,11.67-8.72,18.5-.77,4.07-1.85,8.24-.86,12.52,1.13,4.88,3.59,8.7,8.52,10.27,4.11,1.31,8,3.56,12.52,3.33,7.07-.36,12-4.63,15.74-10,1.79-2.55,1.22-6.08.45-9.13A120.92,120.92,0,0,0,286.82,326,19,19,0,0,0,284.7,321.8Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="l4" data-name="label">
                <circle
                  id="e4"
                  className="ToothSelector__number_circle"
                  cx="268.61"
                  cy="388.41"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(262.33 394.73)"
                >
                  4
                </text>
              </g>
            </g>
            <g
              id="c3"
              className={
                value.includes("3")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("3")}
            >
              <g id="t3" data-spec-id="t3" className="ToothSelector__tooth">
                <path
                  id="tr3b"
                  d="M203.94,258.61a18.94,18.94,0,0,1,.48-4c.67-2.92.3-6,1.49-8.85,1.28-3.06,3.38-4,5.51-2.18A44.14,44.14,0,0,1,221.71,257l6.83,15.69,2.14,33.44-21.56-.59Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tr3a"
                  d="M246.06,326.76a57.35,57.35,0,0,1-.17-8c-.17-9.7.05-19.41.36-29.13.4-12.44-2-24.5-12-33.53a54.38,54.38,0,0,0-5.16-4c-3.1-2.2-4.91-1.48-5.9,2.19a12.14,12.14,0,0,0-.45,4.84c1.69,9.91,6,19.21,3.32,28.7A75.69,75.69,0,0,1,221.36,300c-5.4-6.74-8.39-14.82-11.54-22.61,0,0-4.22-11.95-6.9-18.23a62.15,62.15,0,0,0-3.23-7.17c-.78-1.35-1.85-2.72-3.73-2.51-2.1.24-2.84,2.29-3.21,3.7A47.4,47.4,0,0,0,191.41,269c.84,10.34,4.26,20.1,6.09,30.21,1.44,8,.93,16,.91,24a4.25,4.25,0,0,0,.5,2.21Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tt3"
                  d="M245.36,326.55c-2.76-3.42-5.73-4.32-10.7-5.78-2.26-.66-5.64-1.16-7.26-.3-3.42,1.82-6.65,1.16-10.06.94-4.89-.31-9.84-.18-14.34,1.9-2.69,1.25-5.14,3-6.36,6.17a64.3,64.3,0,0,0-4,14c-1.32,8.82,1.16,14.55,11.82,17.4a14.35,14.35,0,0,0,13.63-3,25.34,25.34,0,0,0,2.88-3.28c5,3.48,9.38,7.22,15.21,6.91,7.6-.4,14.65-4.93,14.16-13.93-.35-6.26-1.07-12.36-3.52-18A16.55,16.55,0,0,0,245.36,326.55Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="l3" data-name="label">
                <circle
                  id="e3"
                  className="ToothSelector__number_circle"
                  cx="217.07"
                  cy="388.41"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(210.79 394.73)"
                >
                  3
                </text>
              </g>
            </g>
            <g
              id="c2"
              className={
                value.includes("2")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("2")}
            >
              <g id="t2" data-spec-id="t2" className="ToothSelector__tooth">
                <path
                  id="tr2b"
                  d="M157.44,251.83a5.66,5.66,0,0,1-2.72-2.78c-1-1.83-2.15-4-4.74-3.67-3.13.4-2.88,3.37-3.67,5.49a5.94,5.94,0,0,0,0,1.84l4.56,54.44,19.69-2.09Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tr2a"
                  d="M157.35,252.34c-1.87,4.09.11,8.69,1.46,12.91,3.79,11.87,5.17,23.94,2.63,36.23l-.22.27c-3.84-2.38-4.79-6.58-6-10.36a88,88,0,0,1-4.12-31.48c.21-5.09-2-8-5-6.89-6.32,5.24-8.07,12.65-9.41,20.33-1.45,8.31-.27,16.5.76,24.71,1.14,9.06,1.74,18.17,2.22,27.28l44.68,5.07a34.14,34.14,0,0,1-.89-5.8c-.35-6.53-1.52-13-2.09-19.55-.54-6.13-.18-12.4-1.27-18.41-2.32-12.82-8.79-23.67-17.53-33.25a6.38,6.38,0,0,0-2.78-1.65C159.13,251.51,157.6,251.77,157.35,252.34Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tt2"
                  d="M184.33,330.41c1.62,5.14,3,9.91,3,15.21,0,6.16-2.72,11.58-8.21,14.38a15.29,15.29,0,0,1-16.59-2.21c-4.12-3.68-4.12-3.68-9.59-1.11-4.54,2.14-12.07-.43-16.22-5.53-6.31-7.76-2.86-21.89,5.81-27,6.63-4,13.26-2.65,20-1.89a27.43,27.43,0,0,0,4.07.5c6.22.68,10.59,2,15.43,5.39C182.59,328.53,184.08,329.62,184.33,330.41Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="l2" data-name="label">
                <circle
                  id="e2"
                  className="ToothSelector__number_circle"
                  cx="155.4"
                  cy="388.41"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(149.13 394.73)"
                >
                  2
                </text>
              </g>
            </g>
            <g
              id="c1"
              className={
                value.includes("1")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("1")}
            >
              <g id="t1" data-spec-id="t1" className="ToothSelector__tooth">
                <path
                  id="tr1b"
                  d="M90,261c-1.52-1.07-3.31-.41-3.53,1.42-.29,2.29.55,5.8,1.54,8.42,3.93,10.46,10.73,17.36,13.19,27.86,1,4.11,3.56,8.7,3,13.05l5-7.43V283.56s-5.21-8.05-7.7-10.86A43.12,43.12,0,0,0,90,261Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tr1a"
                  d="M107.07,299.43c1.22-8.36-1.52-15-4.52-22.66-1.07-2.77-1.26-5.23,1.2-7.64,5.66,3.21,9.32,8.19,12.44,13.55,4.88,8.4,6.85,17.75,8.13,27.26,1,7.7,2.08,15.42,1.49,23.23l-42-3.69a3.53,3.53,0,0,1-.71-3.31c.53-3.58,1.49-7.11,1.76-10.7,1-13.47.39-26.8-3.61-39.83a17.54,17.54,0,0,1-1.11-7c.29-2.87,1.28-3.53,4-2.52.67.24,7.68,8,9.65,10.93,3,4.44,6.68,14.54,8.08,19.47.94,3.33,2.94,12.67,2.94,12.67Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="tt1"
                  d="M83.41,329.85c-4.64,6.09-5.24,12.81-2.93,19.9a15.56,15.56,0,0,0,8.84,9.56c2.7,1.22,5.31,1.23,7.76-1,3.93-3.61,4.7-5,11-.43,3.25,2.37,7,4,11,3.63,5.54-.46,10.12-6.17,10.33-12.08.46-13.35-6.37-21.12-18.45-26.15-8.07-3.36-15.46-1.05-22.47,3.3-1.51.93-3,2-4.43,2.94Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="l1" data-name="label">
                <circle
                  id="e1"
                  className="ToothSelector__number_circle"
                  cx="101.61"
                  cy="388.41"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(95.33 394.73)"
                >
                  1
                </text>
              </g>
            </g>
          </g>
          <g id="baby_bottom_right">
            <g
              id="cT"
              className={
                value.includes("T")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("T")}
            >
              <g id="tT" data-spec-id="tT" className="ToothSelector__tooth">
                <path
                  id="trT"
                  d="M261.83,750.37a4,4,0,0,1-2.94-4.79,9.88,9.88,0,0,0,.32-3,128.89,128.89,0,0,0-.41-15.22c-.12-1.06.93-3,.89-4l3.19-.72,4.42,27.51Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="trT-2"
                  data-name="trT"
                  d="M256.42,711.5c-1.27-3.54-4.08-4.14-6.39-1.19-4.72,6-7.34,13-8.84,20.42-.56,2.8-1,5.63-1.62,8.41-1.3,5.73-3.26,11.29-3.7,17.23-.14,1.76.17,3.72-.87,5.43-.74,1.23-1.74,1.66-2.81.77a21,21,0,0,1-3-3.77c-4.54-6.16-5.71-13.27-6-20.65a50.57,50.57,0,0,1,.37-6.62,74.17,74.17,0,0,1,1.61-9.82c.62-2.78,1.24-5.59,1.59-8.41.5-4.06,1-8.14,1.38-12.21s-.05-8.3-.2-12.43a13.21,13.21,0,0,0-.63-3.67l7.46-3,42.92.79v5.72c-.41,2.7-1.71,5.13-2.25,7.81-.92,4.58-2,9.21-1.29,13.83.92,6.23,3,17.42,2.34,25.65-.53,7.15-1.24,14.26-4.42,20.84a12.72,12.72,0,0,1-2.81,4,3,3,0,0,1-4.39-.19,7.37,7.37,0,0,1-2.33-5c-.05-3-.66-5.85-.51-8.82.22-4.49-.47-8.95-.85-13.42A51.35,51.35,0,0,0,260,724.8S257.89,715.59,256.42,711.5Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="ttT"
                  d="M243.39,686.12a8.73,8.73,0,0,1,4.42,1.72,21.57,21.57,0,0,0,10.41,3.06c6.62.48,12.86-1.55,19.24-2.62.48-.08.58-.38.79-.63,4-4.83,7.45-10,9.17-16,1.65-5.77,1.95-11.54-1.29-17.05a9.5,9.5,0,0,0-2.48-2.74,2.77,2.77,0,0,0-2.57-.6c-2.67,1-5.5,1-8.23,1.58-2.13.46-4.05,1.58-6.21,2a18.6,18.6,0,0,1-8.64-.35,17.15,17.15,0,0,0-11.37.58c-1.94.84-3.65.92-5.21-.29a5.1,5.1,0,0,0-5.81-.31,7.45,7.45,0,0,1-7.46,0c-2.58-1.35-5-.35-6.63,1.94-2.94,4.21-2.14,8.58-1,13a70.79,70.79,0,0,0,6.77,16.67C230.76,688.16,238.19,686.06,243.39,686.12Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="lT" data-name="label">
                <circle
                  id="eT"
                  className="ToothSelector__number_circle"
                  cx="249.02"
                  cy="622.77"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(242.79 629.1)"
                >
                  T
                </text>
              </g>
            </g>
            <g
              id="cS"
              className={
                value.includes("S")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("S")}
            >
              <g id="tS" data-spec-id="tS" className="ToothSelector__tooth">
                <path
                  id="trS"
                  d="M338.86,729.64c1.14,3.52.43,6.88.26,10.31-.13,2.67-.72,5.31-.55,8,.19,3,2.52,4.45,5.21,3.21l1.8-.8L348,748.8l-6.07-22.29Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="trS-2"
                  data-name="trS"
                  d="M348.82,695.1a57.45,57.45,0,0,1,4.15,11c1.72,5.72,3,11.55,4.11,17.41,1.62,8.32,1.7,16.69,1.44,25.06-.1,3.1-.33,6.29-1.87,9.25-2.06,4-6,3.82-8.09.19-1.65-2.9-2.06-6.18-3.14-9.25-1.6-4.56-3-9.21-4.47-13.8-.15-.46-2.09-5.31-2.09-5.31-.06-.18-.12-.36-.19-.55a172.67,172.67,0,0,0-9.74-21.19c-2-3.77-5.21-4-8-.81-.28.32-.55.65-.8,1-6.27,8.89-8.9,19-9.59,29.66-.22,3.52-.06,7.08,0,10.62a3.93,3.93,0,0,1-2,3.72c-1.19.6-2.31-.26-3.19-1.3-1-1.23-2.26-2.27-3.22-3.61-3.68-5.18-4.66-11.16-5.14-17.25a91.39,91.39,0,0,1,.43-14.42c.12-1.53.11-3.1.37-4.61.83-4.69,1.74-9.38,2.8-14,.69-3,1.21-6.85,2.3-9.76Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="ttS"
                  d="M352.21,689.26c.73-3.31,1.19-6.66,1.66-10a95.21,95.21,0,0,0,.7-15.64c-.1-4.06-2.52-9.81-8.57-11.28a41.85,41.85,0,0,0-9.63-1.58,22.74,22.74,0,0,0-9.43,2.22c-2.61,1.15-5.24,2.28-8.21,2.25a9.45,9.45,0,0,1-3-.63c-2.37-.82-4.6-1.08-6.84.57a6.6,6.6,0,0,1-6.38,1c-5.7-2.1-6.67,0-7.92,4.46a26.25,26.25,0,0,0,1.86,19.63,20.54,20.54,0,0,0,10.29,9.34c4.65,2.11,9.18,4.51,14,6.28,6,2.23,12.15,4,18.64,3.34a15.68,15.68,0,0,0,9.42-3.75S351.69,691.61,352.21,689.26Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="lS" data-name="label">
                <circle
                  id="eS"
                  className="ToothSelector__number_circle"
                  cx="323.5"
                  cy="622.77"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(317.45 629.1)"
                >
                  S
                </text>
              </g>
            </g>
            <g
              id="cR"
              className={
                value.includes("R")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("R")}
            >
              <g id="lR" data-name="label">
                <circle
                  id="eR"
                  className="ToothSelector__number_circle"
                  cx="375.28"
                  cy="622.77"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(368.25 629.1)"
                >
                  R
                </text>
              </g>
              <g id="tR" data-spec-id="tR" className="ToothSelector__tooth">
                <path
                  id="trR"
                  d="M395.58,688c.47,2.93.07,6.49-.2,9.46-.36,4-.36,8-1.07,12a45.13,45.13,0,0,0-.35,5.21c-.26,4.67-.67,9.35-1,14-.43,5.59.12,11.1.19,16.64a105,105,0,0,1-1.3,16.82,14.62,14.62,0,0,1-2.33,6c-2.23,3.29-4.35,3.68-7.42,1.19-4.18-3.39-6.73-7.88-8.63-12.82a128.4,128.4,0,0,1-5.11-17.65,59.09,59.09,0,0,1-1-7c-.57-5.55.07-11.11-.38-16.63-.33-4.08-.63-8.16-1-12.24-.3-3-.87-6-.89-9,0-1.2-.87-2.36-.21-3.6Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="ttR"
                  d="M395.48,688.65c-2.21,1.41-4.32,3-6.64,4.17a23.37,23.37,0,0,1-8.58,2.53,22.44,22.44,0,0,1-8.44-.85,14,14,0,0,1-9.17-8.47,44.45,44.45,0,0,1-3.19-21c.28-3.63,1.63-6.85,4.93-9,3.13-2.08,6.41-4.06,8.81-7,3.12-3.83,7-3.83,11.26-2.81,5.14,1.22,8.3,4.6,11.06,8.82,4.45,6.78,5,14.22,4.1,21.86-.43,3.75-.86,7.66-3.73,10.65A6.71,6.71,0,0,0,395.48,688.65Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
            </g>
            <g
              id="cQ"
              className={
                value.includes("Q")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("Q")}
            >
              <g id="tQ" data-spec-id="tQ" className="ToothSelector__tooth">
                <path
                  id="trQ"
                  d="M432.34,679.84c.84,2.06.56,4.3.7,6.42a115.66,115.66,0,0,1,0,15c-.25,3.81-.51,7.64-1,11.45-.43,3.39-.95,6.8-1.42,10.19-.52,3.74-.91,7.5-1.4,11.24-.43,3.27-1,6.52-1.37,9.8-.32,2.83-1,5.62-1.56,8.42-.64,3.08-1.27,6.16-2,9.22s-2.64,3.51-5,1.4a25.61,25.61,0,0,1-7.4-12.62c-1.9-6.85-1.91-13.84-1.37-20.84.16-2.08.12-4.14.14-6.21.05-6,.12-11.89,0-17.83-.12-5.55-.35-11.1-.85-16.63-.17-1.87-.28-3.74-.29-5.62a4.19,4.19,0,0,0-.34-1.67Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="ttQ"
                  d="M408.89,681.05c-3.21-4.45-4.35-9.71-5.78-14.84-2.32-8.32,3.68-14.27,11-15.07,5.95-.65,11.89-.24,17.83-.32,4.13-.05,5.43,2,4.44,6.18a12.79,12.79,0,0,0,0,2.4c-.23,5.36-.65,10.69-2.39,15.83-.5,1.49-1.82,4.41-1.82,4.41-2,1.84-3.51,4.16-5.76,5.87-4.87,3.71-10.59,2.15-14.28-1.06C411.35,683.8,409,681.22,408.89,681.05Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="lQ" data-name="label">
                <circle
                  id="eQ"
                  className="ToothSelector__number_circle"
                  cx="415.37"
                  cy="622.77"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(406.71 629.1)"
                >
                  Q
                </text>
              </g>
            </g>
            <g
              id="cP"
              className={
                value.includes("P")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("P")}
            >
              <g id="tP" data-spec-id="tP" className="ToothSelector__tooth">
                <path
                  id="trP"
                  d="M444.17,683.24c-.71,2.1.25,4.14.28,6.21,0,2.48,1,4.94,1.05,7.42s.45,5.07.54,7.61c.09,2.74.56,5.48.3,8.22-1.14,12.27-.77,24.57-.71,36.87a32.05,32.05,0,0,0,2.57,11.81c.95,2.34,2.36,2.56,4.17.8a13.39,13.39,0,0,0,4-6.81A82,82,0,0,1,459,747a73.42,73.42,0,0,0,2.24-10.21q.65-3.69,1.27-7.4c.41-2.47.76-5,1.1-7.43.61-4.46,1.09-8.94,1.62-13.42a2.41,2.41,0,0,0,0-.6c-.22-4,.44-8,.5-12,0-2.46.32-4.94.3-7.42,0-1.93-.34-3.89-.17-5.79Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="ttP"
                  d="M465.88,682.25c.27-2,1.36-3.93,1.78-6,.48-2.4,1.21-4.75,1.15-7.22A68.46,68.46,0,0,1,470,654.18a5.36,5.36,0,0,0-5.38-6.2c-4.21,0-8.4.43-12.62.54a35.83,35.83,0,0,0-4.82.56c-4.55.72-6.25,5.06-6.8,8.52A56.92,56.92,0,0,0,440,667a32.77,32.77,0,0,0,3.18,13.44,21.42,21.42,0,0,0,2.63,4c2.76,3.68,7.53,4.39,12.62,3a17.31,17.31,0,0,0,7-4.41A5.13,5.13,0,0,0,465.88,682.25Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="lP" data-name="label">
                <circle
                  id="eP"
                  className="ToothSelector__number_circle"
                  cx="449.86"
                  cy="622.77"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(443.09 629.1)"
                >
                  P
                </text>
              </g>
            </g>
          </g>
          <g id="baby_bottom_left">
            <g
              id="cO"
              className={
                value.includes("O")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("O")}
            >
              <g id="tO" data-spec-id="tO" className="ToothSelector__tooth">
                <path
                  id="trO"
                  d="M527.45,683.24c.7,2.1-.25,4.14-.29,6.21,0,2.48-1,4.94-1,7.42s-.45,5.07-.54,7.61c-.09,2.74-.56,5.48-.31,8.22,1.14,12.27.78,24.57.72,36.87a31.87,31.87,0,0,1-2.58,11.81c-.94,2.34-2.35,2.56-4.17.8a13.45,13.45,0,0,1-4-6.81,84.59,84.59,0,0,0-2.62-8.42,70.65,70.65,0,0,1-2.24-10.21q-.65-3.69-1.27-7.4c-.42-2.47-.77-5-1.11-7.43-.6-4.46-1.09-8.94-1.62-13.42a4,4,0,0,1,0-.6c.22-4-.44-8-.49-12,0-2.46-.32-4.94-.3-7.42,0-1.93.34-3.89.16-5.79Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="ttO"
                  d="M505.74,682.25c-.27-2-1.36-3.93-1.78-6-.48-2.4-1.22-4.75-1.15-7.22a69.09,69.09,0,0,0-1.17-14.84A5.36,5.36,0,0,1,507,648c4.21,0,8.41.43,12.62.54a36,36,0,0,1,4.83.56c4.55.72,6.24,5.06,6.8,8.52a56.91,56.91,0,0,1,.39,9.41,32.61,32.61,0,0,1-3.18,13.44,21.38,21.38,0,0,1-2.62,4c-2.77,3.68-7.53,4.39-12.63,3a17.28,17.28,0,0,1-7-4.41A4.44,4.44,0,0,1,505.74,682.25Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="lO" data-name="label">
                <circle
                  id="eO"
                  className="ToothSelector__number_circle"
                  cx="511.11"
                  cy="622.77"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(502.46 629.1)"
                >
                  O
                </text>
              </g>
            </g>
            <g
              id="cN"
              className={
                value.includes("N")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("N")}
            >
              <g id="tN" data-spec-id="tN" className="ToothSelector__tooth">
                <path
                  id="trN"
                  d="M539.27,679.84c-.84,2.06-.55,4.3-.69,6.42a112.21,112.21,0,0,0,0,15c.25,3.81.51,7.64,1,11.45.42,3.39,1,6.8,1.41,10.19.52,3.74.91,7.5,1.41,11.24.43,3.27,1,6.52,1.36,9.8.32,2.83,1,5.62,1.57,8.42.63,3.08,1.26,6.16,2,9.22s2.64,3.51,5,1.4a25.55,25.55,0,0,0,7.41-12.62c1.9-6.85,1.9-13.84,1.36-20.84-.15-2.08-.11-4.14-.13-6.21,0-6-.12-11.89,0-17.83.13-5.55.36-11.1.85-16.63.17-1.87.29-3.74.3-5.62a4,4,0,0,1,.33-1.67Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="ttN"
                  d="M562.73,681.05c3.21-4.45,4.35-9.71,5.78-14.84,2.32-8.32-3.68-14.27-11-15.07-6-.65-11.89-.24-17.84-.32-4.12-.05-5.43,2-4.43,6.18a12.79,12.79,0,0,1,0,2.4c.22,5.36.64,10.69,2.38,15.83.5,1.49,1.82,4.41,1.82,4.41,2,1.84,3.52,4.16,5.77,5.87,4.86,3.71,10.58,2.15,14.27-1.06C560.27,683.8,562.61,681.22,562.73,681.05Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="lN" data-name="label">
                <circle
                  id="eN"
                  className="ToothSelector__number_circle"
                  cx="546.85"
                  cy="622.77"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(538.23 629.1)"
                >
                  N
                </text>
              </g>
            </g>
            <g
              id="cM"
              className={
                value.includes("M")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("M")}
            >
              <g id="lM" data-name="label">
                <circle
                  id="eM"
                  className="ToothSelector__number_circle"
                  cx="586.74"
                  cy="622.77"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(576.58 629.1)"
                >
                  M
                </text>
              </g>
              <g id="tM" data-spec-id="tM" className="ToothSelector__tooth">
                <path
                  id="trM"
                  d="M576,688c-.46,2.93-.06,6.49.2,9.46.36,4,.36,8,1.07,12a41.07,41.07,0,0,1,.35,5.21c.27,4.67.68,9.35,1,14,.44,5.59-.12,11.1-.19,16.64a103.86,103.86,0,0,0,1.31,16.82,14.48,14.48,0,0,0,2.33,6c2.23,3.29,4.35,3.68,7.42,1.19,4.18-3.39,6.73-7.88,8.63-12.82a129.77,129.77,0,0,0,5.1-17.65,59.1,59.1,0,0,0,1-7c.57-5.55-.07-11.11.37-16.63.34-4.08.64-8.16,1-12.24.3-3,.87-6,.9-9,0-1.2.86-2.36.21-3.6Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="ttM"
                  d="M576.14,688.65c2.2,1.41,4.32,3,6.64,4.17a23.31,23.31,0,0,0,8.58,2.53,22.39,22.39,0,0,0,8.43-.85A14,14,0,0,0,609,686a44.44,44.44,0,0,0,3.18-21c-.27-3.63-1.62-6.85-4.93-9-3.12-2.08-6.41-4.06-8.81-7-3.12-3.83-6.95-3.83-11.26-2.81-5.13,1.22-8.29,4.6-11.06,8.82-4.44,6.78-5,14.22-4.09,21.86.43,3.75.86,7.66,3.72,10.65A6.1,6.1,0,0,1,576.14,688.65Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
            </g>
            <g
              id="cL"
              className={
                value.includes("L")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("L")}
            >
              <g id="tL" data-spec-id="tL" className="ToothSelector__tooth">
                <path
                  id="trL2"
                  d="M632.76,729.64c-1.14,3.52-.44,6.88-.27,10.31.14,2.67.73,5.31.56,8-.2,3-2.52,4.45-5.21,3.21l-1.81-.8-2.37-1.57,6.08-22.29Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="trL1"
                  d="M622.79,695.1a57.41,57.41,0,0,0-4.14,11c-1.72,5.72-3,11.55-4.11,17.41-1.62,8.32-1.71,16.69-1.44,25.06.1,3.1.33,6.29,1.86,9.25,2.07,4,6,3.82,8.09.19,1.66-2.9,2.07-6.18,3.15-9.25,1.6-4.56,3-9.21,4.47-13.8.15-.46,2.09-5.31,2.09-5.31,0-.18.12-.36.19-.55a174.12,174.12,0,0,1,9.73-21.19c2-3.77,5.21-4,8-.81.28.32.55.65.8,1,6.26,8.89,8.9,19,9.58,29.66.23,3.52.06,7.08,0,10.62a3.93,3.93,0,0,0,2,3.72c1.18.6,2.31-.26,3.19-1.3,1-1.23,2.26-2.27,3.21-3.61,3.68-5.18,4.67-11.16,5.15-17.25a93.85,93.85,0,0,0-.43-14.42c-.12-1.53-.11-3.1-.38-4.61-.83-4.69-1.74-9.38-2.8-14-.68-3-1.21-6.85-2.29-9.76Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="ttL"
                  d="M619.41,689.26c-.74-3.31-1.19-6.66-1.66-10a95.21,95.21,0,0,1-.7-15.64c.1-4.06,2.52-9.81,8.57-11.28a41.85,41.85,0,0,1,9.63-1.58,22.77,22.77,0,0,1,9.43,2.22c2.61,1.15,5.23,2.28,8.2,2.25a9.45,9.45,0,0,0,3-.63c2.36-.82,4.6-1.08,6.84.57a6.59,6.59,0,0,0,6.38,1c5.69-2.1,6.67,0,7.91,4.46a26.24,26.24,0,0,1-1.85,19.63,20.58,20.58,0,0,1-10.29,9.34c-4.65,2.11-9.18,4.51-14,6.28-6,2.23-12.16,4-18.64,3.34a15.68,15.68,0,0,1-9.42-3.75S619.93,691.61,619.41,689.26Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="lL" data-name="label">
                <circle
                  id="eL"
                  className="ToothSelector__number_circle"
                  cx="640.34"
                  cy="622.77"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(634.38 629.1)"
                >
                  L
                </text>
              </g>
            </g>
            <g
              id="cK"
              className={
                value.includes("K")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("K")}
            >
              <g id="tK" data-spec-id="tK" className="ToothSelector__tooth">
                <path
                  id="trK2"
                  d="M709.79,750.37a4,4,0,0,0,2.94-4.79,9.88,9.88,0,0,1-.32-3,131.06,131.06,0,0,1,.4-15.22c.12-1.06-.93-3-.88-4l-3.19-.72-4.43,27.51Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="trK1"
                  d="M715.2,711.5c1.27-3.54,4.07-4.14,6.39-1.19,4.71,6,7.33,13,8.83,20.42.57,2.8,1,5.63,1.63,8.41,1.3,5.73,3.26,11.29,3.7,17.23.13,1.76-.18,3.72.86,5.43.75,1.23,1.75,1.66,2.81.77a20.17,20.17,0,0,0,3-3.77c4.55-6.16,5.71-13.27,6-20.65a52.36,52.36,0,0,0-.37-6.62,74.2,74.2,0,0,0-1.62-9.82c-.62-2.78-1.23-5.59-1.58-8.41-.51-4.06-1-8.14-1.38-12.21s0-8.3.2-12.43a13.57,13.57,0,0,1,.62-3.67l-7.46-3-42.91.79v5.72c.4,2.7,1.71,5.13,2.25,7.81.92,4.58,2,9.21,1.28,13.83-.92,6.23-3,17.42-2.33,25.65.53,7.15,1.23,14.26,4.41,20.84a12.91,12.91,0,0,0,2.82,4,3,3,0,0,0,4.39-.19,7.42,7.42,0,0,0,2.33-5c0-3,.66-5.85.51-8.82-.23-4.49.46-8.95.84-13.42a53.66,53.66,0,0,1,1.13-8.34S713.72,715.59,715.2,711.5Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="ttK"
                  d="M728.22,686.12a8.77,8.77,0,0,0-4.42,1.72,21.5,21.5,0,0,1-10.4,3.06c-6.62.48-12.86-1.55-19.25-2.62-.47-.08-.58-.38-.79-.63-4-4.83-7.44-10-9.17-16-1.64-5.77-2-11.54,1.3-17.05a9.36,9.36,0,0,1,2.48-2.74,2.77,2.77,0,0,1,2.57-.6c2.66,1,5.5,1,8.23,1.58,2.13.46,4,1.58,6.2,2a18.63,18.63,0,0,0,8.65-.35,17.15,17.15,0,0,1,11.37.58c1.93.84,3.64.92,5.21-.29a5.08,5.08,0,0,1,5.8-.31,7.47,7.47,0,0,0,7.47,0c2.57-1.35,5-.35,6.63,1.94,2.93,4.21,2.13,8.58,1,13a71.22,71.22,0,0,1-6.77,16.67C740.86,688.16,733.43,686.06,728.22,686.12Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="lK" data-name="label">
                <circle
                  id="eK"
                  className="ToothSelector__number_circle"
                  cx="712.55"
                  cy="622.77"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(705.52 629.1)"
                >
                  K
                </text>
              </g>
            </g>
          </g>
          <g id="baby_upper_left">
            <g
              id="cJ"
              className={
                value.includes("J")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("J")}
            >
              <g id="tJ" data-spec-id="tJ" className="ToothSelector__tooth">
                <path
                  id="trJ2"
                  d="M741.7,98.83a11.94,11.94,0,0,0-.22-4.55,30,30,0,0,0-2.21-6.8,29.5,29.5,0,0,1-2-11.83,10.06,10.06,0,0,0-1.93-6.43c-1.53-2-2.74-2.51-5.24-1.8a13.67,13.67,0,0,1-1.41.34c-2.61.44-3.75,2.14-4.18,4.48a40.22,40.22,0,0,0-.68,10.63,32,32,0,0,1-2.1,14,3.91,3.91,0,0,1-.84,1.19l-1.63,15.72,21.95-1.09Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="trJ1"
                  d="M748.3,127.34a12.25,12.25,0,0,0,.19-1.6,25.21,25.21,0,0,1,2.24-10.41c1.35-2.92,3-5.71,4.37-8.63,3.5-7.55,4.88-15.37,3-23.64-1.59-7.1-6-11.74-12.45-14.61-2.27-1-3.58-.06-3.61,2.39a9.38,9.38,0,0,0,1.42,4.61,25.87,25.87,0,0,1,3,13c0,2.93-3.74,11.62-9.72,18.25a9.67,9.67,0,0,1-8.47,2.78c-2.09-.24-3.7-.73-4.74-3.14-1.36-3.13-2.58-6.33-3.8-9.5a34.58,34.58,0,0,1-2.25-14.4c.28-5.08.14-10.15.12-15.23A9,9,0,0,0,716.06,62c-1.31-1.91-2.3-2.26-4.23-1.19a10.37,10.37,0,0,0-4.6,5c-2.56,5.88-3.07,12.21-4,18.44a86.1,86.1,0,0,0-.42,12,38.53,38.53,0,0,0,.76,4.81c.44,3.7,2.46,6.84,3.3,10.4,1.14,4.78,2,9.5,1.25,14.46-.21,1.46-1,3-.29,4.59l7.93,6,29-1.74Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="ttJ"
                  d="M748.5,127.55c5.4,3,6.37,8.38,7.59,13.63a82.09,82.09,0,0,1,1.6,9.21A14.55,14.55,0,0,1,746.3,166a17.13,17.13,0,0,1-10-.86c-3.59-1.34-7-1.22-9.79,1.31-3.12,2.83-6.76,3.12-10.46,2.66a32.9,32.9,0,0,1-11-3.51c-5.87-3-7.57-6-6.15-12.23A105.1,105.1,0,0,1,704,136a17,17,0,0,1,3.21-4.81,3.94,3.94,0,0,1,3.21-1.13c4.74.32,9-1.65,13.43-2.64a48.21,48.21,0,0,1,7-1.07c3.4-.27,6.81-.3,10.22-.41A16.42,16.42,0,0,1,748.5,127.55Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="lJ" data-name="label">
                <circle
                  id="eJ"
                  className="ToothSelector__number_circle"
                  cx="720.97"
                  cy="188.18"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(718.52 194.5)"
                >
                  J
                </text>
              </g>
            </g>
            <g
              id="cI"
              className={
                value.includes("I")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("I")}
            >
              <g id="tI" data-spec-id="tI" className="ToothSelector__tooth">
                <path
                  id="trI2"
                  d="M655.79,83.68c.24-3.88,3.58-7.51,7.55-7.42,2.57,0,4.06,1.7,5,3.81,2.24,5.1,3.61,10.5,5.22,15.82.9,3,1.62,6,2.42,9v15.7l-21.63-.73Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="trI1"
                  d="M649.72,130c-1-5.41-2-10.82-3-16.23-.91-5.08-1.93-10.14-2.89-15.21a48.22,48.22,0,0,1-1-13.83A24.71,24.71,0,0,1,646.25,74c2.06-3.36,4.77-2.32,6,.46a53.88,53.88,0,0,1,2.42,7.2l1.44,4.6q2.52,7.52,5,15c1.12,3.37,1.8,6.89,3.6,10,2,3.59,3.94,3.63,5.78,2.35a17.48,17.48,0,0,0,6.09-8.33c.64-1.66,1.61-4.52,2.1-6.06,1.26-4,1.6-8.23,2.15-12.4.58-4.41.66-8.83,1.11-13.24a10.23,10.23,0,0,1,1.68-4.76c1.31-1.87,4.65-2.6,5.73-.43,3,6.06,5.39,16.3,6.11,26.1.44,5.93-.83,11.3-3.23,17-2.56,6.07-5.74,11.83-7.43,18.21-.29,1.08.07,2.32-.75,3.3Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="ttI"
                  d="M649.72,130.15c-2.54,3.48-3.51,7.57-4.63,11.61s-2.28,7.8-3.62,11.62c-.88,2.5-.11,4.7.9,6.79.65,1.35,2.15,1.69,3.73,1.91a31.8,31.8,0,0,1,10.43,3.73c5.13,2.7,10.23,2.12,15.45.05a34.25,34.25,0,0,1,11.4-2.65c4.22-.15,8-3.19,8.22-6.61A20.07,20.07,0,0,0,689.4,146a55.39,55.39,0,0,1-3.52-8.18c-.26-.8-.32-1.67-.59-2.47-1-3.06-3.75-3.7-6.14-4.5a42.66,42.66,0,0,0-10.2-1.69c-2.87-.23-5.74-.68-8.61-.75A26.67,26.67,0,0,0,649.92,130Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="lI" data-name="label">
                <circle
                  id="eI"
                  className="ToothSelector__number_circle"
                  cx="660.63"
                  cy="188.18"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(657.27 194.5)"
                >
                  I
                </text>
              </g>
            </g>
            <g
              id="cH"
              className={
                value.includes("H")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("H")}
            >
              <g id="lH" data-name="label">
                <circle
                  id="eH"
                  className="ToothSelector__number_circle"
                  cx="612.96"
                  cy="188.18"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(604.7 194.5)"
                >
                  H
                </text>
              </g>
              <g id="tH" data-spec-id="tH" className="ToothSelector__tooth">
                <path
                  id="trH"
                  d="M599.83,126.94c-.84-1.75-.46-3.63-.32-5.4a34.73,34.73,0,0,0,.31-3.81c-.17-5.15.33-10.29.51-15.43.21-6.21.64-12.43,1.17-18.63.45-5.15.85-10.32,1.59-15.43.92-6.36,1.44-12.8,3.13-19a12.33,12.33,0,0,1,4.23-6.6,5.08,5.08,0,0,1,7.21.59c2.09,2.19,2.52,5,3,7.81.54,3.14,1.13,6.27,1.58,9.42a28.91,28.91,0,0,1,.33,4c0,2.89.37,5.75.58,8.61.32,4.34.41,8.71,1.09,13,.44,2.85.41,5.77.87,8.61.68,4.14,1.24,8.3,1.86,12.44s1.5,8.26,2.17,12.4a33.56,33.56,0,0,0,1.32,5.84Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="ttH"
                  d="M630.46,125.36a14.1,14.1,0,0,0,1.37,2.82,41.3,41.3,0,0,1,6.29,15c1,6,.37,12.07-4.42,16.81-3.06,3-6,6.21-9,9.2-4.33,4.19-8,4.46-13.21,2.22a22.75,22.75,0,0,1-9-7.41,72.46,72.46,0,0,0-4.63-5.79c-5.08-5.38-5.51-14-2.61-20.28,1.63-3.53,3-7.2,4.44-10.81l.4-.2a69.52,69.52,0,0,1,9.81-2.81,42.4,42.4,0,0,1,15,0Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
            </g>
            <g
              id="cG"
              className={
                value.includes("G")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("G")}
            >
              <g id="tG" data-spec-id="tG" className="ToothSelector__tooth">
                <path
                  id="trG"
                  d="M559.35,127.75c-1.15-2,0-4.08.16-6,.23-2.2.19-4.43.54-6.64.63-4,.57-8,.92-12,.31-3.41.81-6.81,1.18-10.22.15-1.47.2-2.95.32-4.41.31-4,.78-8,1.31-12,.77-5.69,1.34-11.44,3.57-16.84a10,10,0,0,1,3.74-5c2.33.49,2.75,2.68,3.45,4.46a71.19,71.19,0,0,1,3.94,13.63c.7,4,1.35,8,1.79,12,.88,8.14.39,16.31.81,24.45.29,5.69,1,11.38,1.73,17.06a16,16,0,0,0,.64,2.92Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="ttG"
                  d="M559.55,127.75c2.44-.78,4.61-2.17,7.2-2.67a17.76,17.76,0,0,1,13.62,2.49,8.57,8.57,0,0,0,2.42,1.38l.66.14a21.2,21.2,0,0,0,2.36,4.86,27.92,27.92,0,0,1,4.34,17.83c-.48,5.09-2.66,9-7.91,11.15a55.28,55.28,0,0,1-13.48,3.82,32.25,32.25,0,0,1-11.4-.77c-3-.68-5.29-4.43-4.79-7.77a55.83,55.83,0,0,0,.38-5.62c.44-7.87,1.71-15.54,5.39-22.64.33-.64.54-1.34.81-2Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="lG" data-name="label">
                <circle
                  id="eG"
                  className="ToothSelector__number_circle"
                  cx="567.96"
                  cy="188.18"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(559.98 194.5)"
                >
                  G
                </text>
              </g>
            </g>
            <g
              id="cF"
              className={
                value.includes("F")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("F")}
            >
              <g id="tF" data-spec-id="tF" className="ToothSelector__tooth">
                <path
                  id="trF"
                  d="M509.46,126.54c-.73-2,0-3.93.33-5.79s.38-3.52.56-5.24c.47-4.51.46-9.07.49-13.61,0-7.49.43-15,.51-22.44a135.26,135.26,0,0,1,1.57-16,11.07,11.07,0,0,1,2.77-5.8c1.9-2.15,4.68-1.93,7.38.6a42.48,42.48,0,0,1,8.61,12c3.15,6.21,6,12.57,7.41,19.43a77.08,77.08,0,0,1,1.46,9.21c.4,4.4.53,8.82.86,13.22a138.15,138.15,0,0,0,1.72,14.27,15.56,15.56,0,0,0,.63,2.35Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="ttF"
                  d="M509.66,126.54a35.94,35.94,0,0,1,8.4-3.48c9-2.09,17.23-1.08,24.47,5.08a1.8,1.8,0,0,0,.59.21l.64.38c.46,1.31,1,2.54,1.58,3.82a44.9,44.9,0,0,1,3.27,11.82,27.14,27.14,0,0,1-1.73,14.81c-2.28,5.24-6.54,8-12.17,8.86s-11.1,0-16.64-.42a92.68,92.68,0,0,1-11.23-1.54c-2.8-.54-4.07-2.34-4.75-4.68A13.94,13.94,0,0,1,502,154a19.77,19.77,0,0,0,.05-3c.61-7.91,2.12-15.58,6.43-22.42.37-.6.36-1.29.77-1.83Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="lF" data-name="label">
                <circle
                  id="eF"
                  className="ToothSelector__number_circle"
                  cx="520.48"
                  cy="188.18"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(514.62 194.5)"
                >
                  F
                </text>
              </g>
            </g>
          </g>
          <g id="baby_upper_right">
            <g
              id="cE"
              className={
                value.includes("E")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("E")}
            >
              <g id="tE" data-spec-id="tE" className="ToothSelector__tooth">
                <path
                  id="trE"
                  d="M462.16,126.54c.72-2-.05-3.93-.34-5.79s-.37-3.52-.55-5.24c-.47-4.51-.47-9.07-.49-13.61,0-7.49-.44-15-.52-22.44a135.24,135.24,0,0,0-1.56-16,11.14,11.14,0,0,0-2.77-5.8c-1.9-2.15-4.68-1.93-7.38.6a42.48,42.48,0,0,0-8.61,12c-3.16,6.21-6,12.57-7.42,19.43a81.34,81.34,0,0,0-1.46,9.21c-.39,4.4-.53,8.82-.86,13.22a138.12,138.12,0,0,1-1.71,14.27,16.84,16.84,0,0,1-.63,2.35Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="ttE"
                  d="M462,126.54a35.94,35.94,0,0,0-8.4-3.48c-9-2.09-17.24-1.08-24.47,5.08a1.8,1.8,0,0,1-.59.21l-.64.38c-.46,1.31-1,2.54-1.58,3.82A44.9,44.9,0,0,0,423,144.37a27.14,27.14,0,0,0,1.72,14.81c2.29,5.24,6.55,8,12.18,8.86s11.09,0,16.63-.42a92.84,92.84,0,0,0,11.24-1.54c2.79-.54,4.07-2.34,4.75-4.68a14,14,0,0,0,.08-7.42,18.54,18.54,0,0,1-.05-3c-.61-7.91-2.13-15.58-6.43-22.42-.37-.6-.36-1.29-.77-1.83Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="lE" data-name="label">
                <circle
                  id="eE"
                  className="ToothSelector__number_circle"
                  cx="441.48"
                  cy="188.18"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(435.34 194.5)"
                >
                  E
                </text>
              </g>
            </g>
            <g
              id="cD"
              className={
                value.includes("D")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("D")}
            >
              <g id="tD" data-spec-id="tD" className="ToothSelector__tooth">
                <path
                  id="trD"
                  d="M412.27,127.75c1.14-2,0-4.08-.17-6-.22-2.2-.19-4.43-.54-6.64-.62-4-.56-8-.92-12-.3-3.41-.8-6.81-1.17-10.22-.16-1.47-.2-2.95-.32-4.41-.32-4-.79-8-1.32-12-.76-5.69-1.34-11.44-3.56-16.84a10,10,0,0,0-3.75-5c-2.32.49-2.75,2.68-3.44,4.46a72,72,0,0,0-3.95,13.63c-.69,4-1.34,8-1.78,12-.88,8.14-.4,16.31-.81,24.45-.3,5.69-1,11.38-1.73,17.06a15.22,15.22,0,0,1-.65,2.92Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="ttD"
                  d="M412.07,127.75c-2.44-.78-4.61-2.17-7.21-2.67a17.77,17.77,0,0,0-13.62,2.49,8.43,8.43,0,0,1-2.42,1.38l-.66.14A20.8,20.8,0,0,1,385.8,134a28,28,0,0,0-4.34,17.83c.49,5.09,2.67,9,7.91,11.15a55.23,55.23,0,0,0,13.49,3.82,32.25,32.25,0,0,0,11.4-.77c3-.68,5.29-4.43,4.78-7.77-.28-1.88-.27-3.74-.38-5.62-.43-7.87-1.7-15.54-5.38-22.64a21.45,21.45,0,0,1-.81-2Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="lD" data-name="label">
                <circle
                  id="eD"
                  className="ToothSelector__number_circle"
                  cx="397.26"
                  cy="188.18"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(389.19 194.5)"
                >
                  D
                </text>
              </g>
            </g>
            <g
              id="cC"
              className={
                value.includes("C")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("C")}
            >
              <g id="lC" data-name="label">
                <circle
                  id="eC"
                  className="ToothSelector__number_circle"
                  cx="348.98"
                  cy="188.18"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(342.01 194.5)"
                >
                  C
                </text>
              </g>
              <g id="tC" data-spec-id="tC" className="ToothSelector__tooth">
                <path
                  id="trC"
                  d="M371.79,126.94c.84-1.75.45-3.63.31-5.4a34.72,34.72,0,0,1-.3-3.81c.17-5.15-.33-10.29-.51-15.43-.21-6.21-.64-12.43-1.18-18.63-.44-5.15-.84-10.32-1.58-15.43-.92-6.36-1.45-12.8-3.14-19a12.28,12.28,0,0,0-4.23-6.6,5.06,5.06,0,0,0-7.2.59c-2.09,2.19-2.53,5-3,7.81-.54,3.14-1.12,6.27-1.57,9.42a28.94,28.94,0,0,0-.34,4c0,2.89-.36,5.75-.57,8.61-.33,4.34-.41,8.71-1.09,13-.45,2.85-.41,5.77-.87,8.61-.68,4.14-1.24,8.3-1.87,12.44s-1.49,8.26-2.17,12.4a32.67,32.67,0,0,1-1.32,5.84Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="ttC"
                  d="M341.15,125.36a13.53,13.53,0,0,1-1.36,2.82,41.32,41.32,0,0,0-6.3,15c-1,6-.37,12.07,4.43,16.81,3.05,3,5.95,6.21,9,9.2,4.33,4.19,8,4.46,13.21,2.22a22.82,22.82,0,0,0,9-7.41,72.46,72.46,0,0,1,4.63-5.79c5.08-5.38,5.51-14,2.61-20.28-1.63-3.53-3-7.2-4.44-10.81l-.4-.2a69.27,69.27,0,0,0-9.82-2.81,42.4,42.4,0,0,0-15,0Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
            </g>
            <g
              id="cB"
              className={
                value.includes("B")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("B")}
            >
              <g id="tB" data-spec-id="tB" className="ToothSelector__tooth">
                <path
                  id="trB2"
                  d="M315.82,83.68c-.23-3.88-3.57-7.51-7.55-7.42-2.56,0-4,1.7-5,3.81-2.24,5.1-3.61,10.5-5.22,15.82-.9,3-1.62,6-2.42,9v15.7l21.63-.73Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="trB1"
                  d="M321.9,130c1-5.41,2-10.82,3-16.23.91-5.08,1.93-10.14,2.89-15.21a48.58,48.58,0,0,0,1-13.83A24.85,24.85,0,0,0,325.37,74c-2.07-3.36-4.77-2.32-6,.46a55.3,55.3,0,0,0-2.41,7.2l-1.44,4.6q-2.52,7.52-5,15c-1.12,3.37-1.8,6.89-3.6,10-2.06,3.59-3.95,3.63-5.79,2.35a17.61,17.61,0,0,1-6.09-8.33c-.64-1.66-1.6-4.52-2.09-6.06-1.27-4-1.6-8.23-2.15-12.4-.58-4.41-.67-8.83-1.12-13.24A10.33,10.33,0,0,0,288,68.88c-1.32-1.87-4.66-2.6-5.73-.43-3,6.06-5.4,16.3-6.12,26.1-.43,5.93.84,11.3,3.24,17,2.56,6.07,5.73,11.83,7.43,18.21.28,1.08-.07,2.32.74,3.3Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="ttB"
                  d="M321.9,130.15c2.54,3.48,3.5,7.57,4.63,11.61s2.27,7.8,3.62,11.62c.88,2.5.1,4.7-.9,6.79-.65,1.35-2.15,1.69-3.74,1.91a32,32,0,0,0-10.43,3.73c-5.13,2.7-10.23,2.12-15.44.05a34.3,34.3,0,0,0-11.4-2.65c-4.23-.15-8-3.19-8.22-6.61a20,20,0,0,1,2.2-10.62,55.39,55.39,0,0,0,3.52-8.18c.26-.8.32-1.67.59-2.47,1-3.06,3.74-3.7,6.14-4.5a42.49,42.49,0,0,1,10.19-1.69c2.88-.23,5.74-.68,8.62-.75A26.67,26.67,0,0,1,321.7,130Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="lB" data-name="label">
                <circle
                  id="eB"
                  className="ToothSelector__number_circle"
                  cx="299"
                  cy="188.18"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(291.73 194.5)"
                >
                  B
                </text>
              </g>
            </g>
            <g
              id="cA"
              className={
                value.includes("A")
                  ? `ToothSelector__tooth-container--on`
                  : `ToothSelector__tooth-container`
              }
              onClick={() => toggleTooth("A")}
            >
              <g id="tA" data-spec-id="tA" className="ToothSelector__tooth">
                <path
                  id="trA2"
                  d="M229.92,98.83a11.93,11.93,0,0,1,.21-4.55,30.07,30.07,0,0,1,2.22-6.8,29.49,29.49,0,0,0,2-11.83,10,10,0,0,1,1.94-6.43c1.53-2,2.73-2.51,5.23-1.8.47.13,1,.27,1.41.34,2.62.44,3.75,2.14,4.19,4.48a40.22,40.22,0,0,1,.67,10.63,32,32,0,0,0,2.11,14,3.91,3.91,0,0,0,.84,1.19l1.63,15.72-22-1.09Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="trA1"
                  d="M223.31,127.34c-.06-.53-.18-1.06-.19-1.6a25.05,25.05,0,0,0-2.24-10.41c-1.34-2.92-3-5.71-4.36-8.63-3.5-7.55-4.89-15.37-3-23.64,1.58-7.1,6-11.74,12.45-14.61,2.26-1,3.58-.06,3.61,2.39a9.48,9.48,0,0,1-1.42,4.61,26,26,0,0,0-3,13c0,2.93,3.75,11.62,9.73,18.25a9.67,9.67,0,0,0,8.47,2.78c2.09-.24,3.69-.73,4.74-3.14,1.35-3.13,2.58-6.33,3.8-9.5a34.58,34.58,0,0,0,2.25-14.4c-.29-5.08-.14-10.15-.13-15.23A9.13,9.13,0,0,1,255.56,62c1.31-1.91,2.3-2.26,4.22-1.19a10.39,10.39,0,0,1,4.61,5c2.55,5.88,3.06,12.21,4,18.44a83.15,83.15,0,0,1,.42,12,38.51,38.51,0,0,1-.75,4.81c-.44,3.7-2.46,6.84-3.31,10.4-1.13,4.78-2,9.5-1.24,14.46.21,1.46,1,3,.29,4.59l-7.93,6-29-1.74Z"
                  transform="translate(-4.92 -3.39)"
                />
                <path
                  id="ttA"
                  d="M223.11,127.55c-5.39,3-6.36,8.38-7.58,13.63a82.09,82.09,0,0,0-1.6,9.21A14.55,14.55,0,0,0,225.31,166a17.16,17.16,0,0,0,10-.86c3.58-1.34,7-1.22,9.78,1.31,3.13,2.83,6.77,3.12,10.46,2.66a32.83,32.83,0,0,0,11-3.51c5.88-3,7.57-6,6.16-12.23A105.1,105.1,0,0,0,267.61,136a17,17,0,0,0-3.22-4.81,3.91,3.91,0,0,0-3.2-1.13c-4.75.32-9-1.65-13.44-2.64a48.07,48.07,0,0,0-7-1.07c-3.41-.27-6.81-.3-10.22-.41A16.43,16.43,0,0,0,223.11,127.55Z"
                  transform="translate(-4.92 -3.39)"
                />
              </g>
              <g id="lA" data-name="label">
                <circle
                  id="eA"
                  className="ToothSelector__number_circle"
                  cx="235.08"
                  cy="188.18"
                  r="19.72"
                />
                <text
                  className="ToothSelector__number"
                  transform="translate(227.81 194.5)"
                >
                  A
                </text>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export { ToothSelector };
