import React, {
  FC,
  useLayoutEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { createPortal } from 'react-dom';

interface PopperProps {
  content: React.ReactNode;
  placement: {
    vertical: 'top' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
    verticalOffset?: number;
  };
  size?: {
    width?: number;
  };
  widthFull?: true;
  popperLayerSelector?: string;
}

export const Popper: FC<PopperProps> = props => {
  const {
    placement,
    content,
    widthFull,
    children,
    popperLayerSelector = '[data-popper-layer]',
  } = props;

  const popperLayer = document.querySelector(popperLayerSelector);

  const containerRef = useRef<HTMLDivElement>(null);
  const popperRef = useRef<HTMLDivElement>(null);

  const [position, setPosition] = useState({
    top: 0,
    left: 0,
    width: props.size?.width,
  });

  const repositionPopper = useCallback(() => {
    if (!containerRef.current || !popperRef.current) return;

    const containerRect = containerRef.current.getBoundingClientRect();
    const popperRect = popperRef.current.getBoundingClientRect();

    const width = widthFull
      ? containerRef.current?.clientWidth
      : props.size?.width;

    setPosition(currentPosition => ({
      ...currentPosition,
      width,
      ...computeVerticalPosition(containerRect, popperRect, { placement }),
      ...computeHorizontalPosition(
        containerRect,
        popperRect,
        placement.horizontal
      ),
    }));
  }, [containerRef, popperRef, placement]);

  useLayoutEffect(() => {
    const reposition = () => {
      window.requestAnimationFrame(() => repositionPopper());
    };

    repositionPopper();
    window.addEventListener('scroll', reposition, true);
    return () => window.removeEventListener('scroll', reposition, true);
  }, [content, containerRef, popperRef, repositionPopper]);

  return (
    <div className="pa-popperContainer" ref={containerRef}>
      {children}

      {/*
        NB: The following element is present to make scrolling work with the "portal" popper
            element. The "portal" allows overlaying content, regardless of z-index, but is
            out of the scrolling flow. This is the same height and vertical position, but
            is positioned inside the scrolling element.
       */}
      {placement.vertical === 'bottom' &&
        popperRef.current &&
        popperLayer &&
        content && (
          <div className="relative">
            <div
              className="absolute"
              style={{
                top: '100%',
                left: 0,
                width: 1,
                height: popperRef.current.clientHeight,
              }}
            />
          </div>
        )}

      {popperLayer &&
        content &&
        createPortal(
          <div
            className="pa-popper absolute z-50"
            style={position}
            ref={popperRef}
          >
            {content}
          </div>,
          popperLayer
        )}
    </div>
  );
};

function computeVerticalPosition(
  containerRect: ClientRect,
  popperRect: ClientRect,
  position: {
    placement: PopperProps['placement'];
  }
) {
  const { placement } = position;
  const offset = placement.verticalOffset || 0;

  if (placement.vertical === 'top')
    return { top: containerRect.top - popperRect.height - offset };

  return { top: containerRect.bottom + offset };
}

function computeHorizontalPosition(
  containerRect: ClientRect,
  popperRect: ClientRect,
  placement: PopperProps['placement']['horizontal']
) {
  if (placement === 'left')
    return {
      left: containerRect.left + containerRect.width - popperRect.width,
    };

  if (placement === 'right') return { left: containerRect.left };

  // Center positioned.
  return {
    left: containerRect.left + containerRect.width / 2 - popperRect.width / 2,
  };
}
