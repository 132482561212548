import React, { FC } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

interface circleProgressProps {
  width?: number;
  height?: number;
  value: number;
  styles?: {
    textSize: string;
    textColor: string;
    pathColor: string;
  };
}
export const CircleProgress: FC<circleProgressProps> = (props) => {
  const { value, styles = {}, width = 45, height = 45 } = props;

  return (
    <div className="m-auto" style={{ width: width, height: height }}>
      <CircularProgressbar
        value={value}
        text={`${value}%`}
        styles={buildStyles(styles)}
      />
    </div>
  );
};
