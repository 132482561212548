import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons/faFileAlt";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons/faFileDownload";
import { faHospitalAlt } from "@fortawesome/free-solid-svg-icons/faHospitalAlt";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faQuestion } from "@fortawesome/free-solid-svg-icons/faQuestion";
import { faSignature } from "@fortawesome/free-solid-svg-icons/faSignature";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";

/**
 * Warning: side-effects!
 * See: https://github.com/FortAwesome/react-fontawesome/tree/9ee4f58cfc07296f250b870249a66ed493f8589f#build-a-library-to-reference-icons-throughout-your-app-more-conveniently
 * Building this "library" allows us to only import the SVG icons we care to use,
 * imported individually above, and then use them by string reference in components.
 * This function needs to be run in an initializing module of the app/site.
 */
export default function createIconLibrary() {
  library.add(
    faArrowLeft,
    faFileAlt,
    faFileDownload,
    faHospitalAlt,
    faPlus,
    faQuestion,
    faSignature,
    faTimes
  );
}
