import { FC, useLayoutEffect } from "react";
import { SignInForm } from "./SignInForm";
import logoSrc from "images/aristamd_logo.svg";
import { useAuth } from "context/AuthContext";
import { useHistory, useLocation } from "react-router-dom";

interface SignInScreenProps { }

function useFriendlyForward(defaultPath: string) {
  const { token } = useAuth();
  const location = useLocation<any>();
  const history = useHistory();

  const { from } = (location.state as any) || {
    from: { pathname: "/pp/home" },
  };

  const defaultDestination = { pathname: defaultPath };
  const destination = location.state?.from || defaultDestination;

  return useLayoutEffect(() => {
    if (token) {
      history.replace(destination);
    }
  }, [history, token, from, destination]);
}

export const SignInScreen: FC<SignInScreenProps> = (props) => {
  useFriendlyForward("/pp/home");

  return (
    <div className="min-h-screen bg-cool-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto w-48" src={logoSrc} alt="Preferral Logo" />
        <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
          Patient Portal Sign In
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <SignInForm />
      </div>
    </div>
  );
};
