import React, { FC } from 'react';
import { ErrorMessage, useField } from 'formik';
import { StandardOption } from '../SelectField/SelectField';
import {
  VerticalField,
  HorizontalField,
} from '../../FieldStructure/FieldStructure';
import { compose, ValidateProp } from '../validation';

/**
 * CheckboxInput.
 */

export interface CheckboxGroupInputProps {
  id?: string;
  name: string;
  options: StandardOption[];
  validate?: ValidateProp;
}

export const CheckboxGroupInput: FC<CheckboxGroupInputProps> = (props) => {
  const { id, name, options, validate: validators } = props;

  const validate = validators && compose([validators].flat());
  const [field, meta] = useField({
    name,
    validate,
    type: 'checkbox',
    multiple: true,
  });

  const showError = !!(meta && meta.touched && meta.error);

  const controlClassName = [
    'w-full',
    showError && '-m-2 p-2 border border-red-500 rounded',
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <div className="max-w-lg">
      <div className={controlClassName}>
        {options.map((o, idx) => (
          <div key={o.value} className={idx > 0 ? 'mt-4' : ''}>
            <div className="relative flex items-start">
              <div className="absolute flex items-center h-5">
                {/* <Field
                  id={`${id || name}--${o.value}`}
                  name={name}
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                  value={o.value}
                  validate={validate}
                /> */}
                <input
                  id={`${id || name}--${o.value}`}
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                  {...field}
                  checked={field.value && field.value.includes(o.value)}
                  value={o.value}
                />
              </div>
              <div className="pl-7 text-sm leading-5">
                <label
                  htmlFor={`${id || name}--${o.value}`}
                  className="font-medium text-gray-700"
                >
                  {o.label}
                </label>
              </div>
            </div>
          </div>
        ))}
      </div>
      <ErrorMessage
        component="p"
        name={name}
        className="mt-2 text-red-500 text-xs italic"
      />
    </div>
  );
};

/**
 * CheckboxGroupField.
 */

export interface CheckboxGroupFieldProps extends CheckboxGroupInputProps {
  label: string;
  indicateOptional?: boolean;
  indicateRequired?: boolean;
}

export const CheckboxGroupField: FC<CheckboxGroupFieldProps> = (props) => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;

  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <CheckboxGroupInput {...rest} />
    </VerticalField>
  );
};

export const HorizontalCheckboxGroupField: FC<CheckboxGroupFieldProps> = (
  props
) => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;

  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <CheckboxGroupInput {...rest} />
    </HorizontalField>
  );
};
