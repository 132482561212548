const isObject = (value: any) => value !== null && typeof value === "object";

const isFileList = (value: any) =>
  typeof FileList !== "undefined" && value instanceof FileList;

const isUploadFile = (value: any) =>
  (typeof File !== "undefined" && value instanceof File) ||
  (typeof Blob !== "undefined" && value instanceof Blob);

export const extractFiles = (variables: any) => {
  const files: { file: File | Blob; name: string }[] = [];
  const walkTree = (tree: any, path: string[] = []) => {
    const mapped = Array.isArray(tree) ? tree : Object.assign({}, tree);
    Object.keys(mapped).forEach((key) => {
      const value = mapped[key];
      const name = [...path, key].join(".");

      if (isUploadFile(value) || isFileList(value)) {
        const file: File | Blob = isFileList(value)
          ? Array.prototype.slice.call(value)
          : value;
        files.push({ file, name });
        mapped[key] = name;
      } else if (isObject(value)) {
        mapped[key] = walkTree(value, [...path, key]);
      }
    });

    return mapped;
  };

  return {
    files,
    variables: walkTree(variables),
  };
};
