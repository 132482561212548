import { FC, useCallback } from "react";
import * as Yup from "yup";
import qs from "query-string";
import { gql, useMutation } from "@apollo/client";
import { Formik } from "formik";
import { Button, TextField } from "@preferral/ui";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";

const REQUEST_PASSWORDLESS_LOGIN_LINK = gql`
  mutation RequestPasswordlessLoginLink(
    $username: String!
    $targetPath: String
  ) {
    requestPasswordlessLoginLink(username: $username, targetPath: $targetPath) {
      errors {
        key
        message
      }
      sent
    }
  }
`;

interface Data {
  requestPasswordlessLoginLink: {
    errors?: InputError[];
    sent?: boolean;
  };
}

interface Variables {
  username: string;
  targetPath?: string;
}

interface RequestPasswordlessLoginLinkFormProps {
  onSuccess(sentTo: string): void;
}

interface FormValues {
  username: string;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    username: Yup.string().required("Required"),
  })
  .required();

export const RequestPasswordlessLoginLinkForm: FC<RequestPasswordlessLoginLinkFormProps> = props => {
  const { onSuccess } = props;
  const location = useLocation();
  const { target: targetPath } = qs.parse(location.search);

  const [requestLink] = useMutation<Data, Variables>(
    REQUEST_PASSWORDLESS_LOGIN_LINK
  );

  const onSubmit = useCallback(
    async (values, formikActions) => {
      const { setStatus, setSubmitting } = formikActions;
      setStatus({ errors: null });

      const variables = targetPath ? { ...values, targetPath } : values;

      try {
        const { data } = await requestLink({ variables });

        if (data?.requestPasswordlessLoginLink.errors) {
          setStatus({ errors: data.requestPasswordlessLoginLink.errors });
        } else if (data?.requestPasswordlessLoginLink.sent) {
          // it worked...
          onSuccess(values.username);
        }
        setSubmitting(false);
      } catch (e) {
        toast.error("Something went wrong.");
        console.error(e);
        setSubmitting(false);
      }
    },
    [requestLink, onSuccess, targetPath]
  );

  return (
    <Formik<FormValues>
      initialValues={{ username: "" }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ status, handleSubmit, isSubmitting }) => (
        <form
          className="_RequestPasswordlessLoginLinkForm"
          onSubmit={handleSubmit}
        >
          <FormStatusErrors status={status} />
          <TextField label="Email or Phone Number" name="username" />

          <div className="mt-3">
            <Button
              type="submit"
              isLoading={isSubmitting}
              kind="primary"
              color="blue"
              className="w-full"
            >
              Send Me a Link
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
