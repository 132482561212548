import { FC } from 'react'
import {
  HorizontalSelectField,
  required,
  Validator,
  StandardOption,
} from '@preferral/ui'
import { DropdownQuestion } from '../types'
import { useQuestion } from './QuestionnaireFormContext'

interface DropdownQuestionFieldProps {
  question: DropdownQuestion
}

export const DropdownQuestionField: FC<DropdownQuestionFieldProps> = (
  props,
) => {
  const { question } = props

  const { name, isVisible, isRequired } = useQuestion(question.id)

  if (!isVisible) {
    return null
  }

  const validate = [isRequired ? required : null].filter(Boolean) as Validator[]

  return (
    <div className="DropdownQuestionField">
      <HorizontalSelectField
        indicateRequired={isRequired}
        label={question.prompt}
        name={name}
        options={question.options.map(toOption)}
        validate={validate}
        isClearable
      />
    </div>
  )
}

function toOption(value: string): StandardOption {
  return {
    value,
    label: value,
  }
}
