import React, { FC } from "react";

interface BlankAvatarProps {
  className?: string;
  circleClassName?: string;
  profileClassName?: string;
};

export const BlankAvatar: FC<BlankAvatarProps> = props => {
  const {
    className = "h-8 w-8",
    circleClassName = "text-blue-300",
    profileClassName = "text-white"
  } = props;

  return (
    <div className={`_BlankAvatar object-cover inline-flex rounded-full shadow-inner overflow-hidden ${className}`}>
      <svg className="w-full h-full" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle className={circleClassName} cx="50" cy="50" r="50" fill="currentColor" />
        <path className={profileClassName} d="M9.361 79.136c4.242-6.829 13.853-12.258 26.014-14.712C30.85 59.662 28 52.724 28 45c0-14.36 9.85-26 22-26s22 11.64 22 26c0 7.724-2.85 14.662-7.376 19.424 12.162 2.454 21.773 7.883 26.014 14.712C81.564 91.77 66.743 100 50 100S18.436 91.77 9.36 79.136z" fill="currentColor" />
      </svg>
    </div>
  );
};
