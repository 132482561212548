import { FC, useLayoutEffect } from "react";
import { Layout, TopNav, PageContent } from "layouts/PortalLayout";
import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  useHistory,
} from "react-router-dom";
import qs from "query-string";
import { HomeScreen } from "screens/HomeScreen/HomeScreen";
import { NoMatchScreen } from "screens/NoMatchScreen/NoMatchScreen";
import { PatientPacketSubmissionShowScreen } from "screens/PatientPacketSubmissionShowScreen";
import { FormsScreen } from "screens/FormsScreen";
import { SettingsScreen } from "screens/SettingsScreen";
// import { AppointmentsScreen } from "screens/AppointmentsScreen";
import { PatientPacketFormScreen } from "screens/PatientPacketSubmissionShowScreen/PatientPacketFormScreen";
import { useAuth } from "context/AuthContext";

function usePasswordlessLoginToken() {
  const location = useLocation();
  const history = useHistory();
  const { plt: passwordlessLoginToken } = qs.parse(location.search);
  const { logout, token } = useAuth();

  const unixNow = Math.round(Date.now() / 1000);
  const hasFreshToken = (token?.claims.exp || 0) > unixNow;

  return useLayoutEffect(() => {
    if (passwordlessLoginToken && !hasFreshToken) {
      logout()
      history.replace(`/auth/passwordless?plt=${passwordlessLoginToken}`, { from: location });
    }
  }, [logout, hasFreshToken, location, history, passwordlessLoginToken]);
}

interface AuthenticatedAppProps { }

export const AuthenticatedApp: FC<AuthenticatedAppProps> = (props) => {
  const match = useRouteMatch();

  usePasswordlessLoginToken();

  return (
    <Layout>
      <TopNav
        userDisplayName="Patient"
        links={[
          { to: "/pp/forms", label: "Forms" },
        ]}
      ></TopNav>
      <PageContent>
        <Switch>
          <Route path={`${match.path}/home`}>
            <HomeScreen />
          </Route>
          {/* <Route path={`${match.path}/appointments`}>
            <AppointmentsScreen />
          </Route> */}
          <Route exact path={`${match.path}/forms`}>
            <FormsScreen />
          </Route>
          <Route exact path={`${match.path}/packets`}>
            <h1>Patient Packets screen</h1>
          </Route>
          <Route
            exact
            path={`${match.path}/packets/:patientPacketSubmissionId`}
          >
            <PatientPacketSubmissionShowScreen />
          </Route>
          <Route
            path={`${match.path}/packets/:patientPacketSubmissionId/form/:patientFormSubmissionId`}
          >
            <PatientPacketFormScreen />
          </Route>
          <Route path={`${match.path}/settings`}>
            <SettingsScreen />
          </Route>
          <Route path="*">
            <NoMatchScreen />
          </Route>
        </Switch>
      </PageContent>
    </Layout>
  );
};
