import React, { FC } from "react";

type StatusErrorsProps = {
  status?: { errors?: { key: string; message: string }[] };
};

export const FormStatusErrors: FC<StatusErrorsProps> = ({ status }) =>
  status && status.errors ? (
    <div className="text-center">
      {status.errors.map((error) => (
        <p
          className="mb-2 text-red-500 capitalize"
          key={`${error.key}${error.message}`}
        >
          {error.key ? `${error.key} ${error.message}` : error.message}
        </p>
      ))}
    </div>
  ) : null;
