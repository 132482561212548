import "core-js/features/string/pad-start";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import ReactModal from "react-modal";
import "fontsource-inter/latin.css";
import "./index.css";
import "./styles/tailwind.dist.css";
import "./styles/ReactModal.css";
import { EnvironmentNotice } from "@preferral/ui";
import { App } from "./App";
import { AppProviders } from "./context";
import reportWebVitals from "./reportWebVitals";
import createIconLibrary from "./createIconLibrary";
import { ENV } from "config/constants";

createIconLibrary();

ReactModal.setAppElement("#root");

ReactDOM.render(
  <React.StrictMode>
    <AppProviders>
      <App />
      <EnvironmentNotice isProduction={ENV === "PROD"} />
    </AppProviders>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
