import React, { FC } from 'react';
import { ImageWithIEPolyfill as Image } from '../Image/ImageWithIEPolyfill';
import { ImageObject } from '../Image/Image';

const CheckBadge: FC<{ className?: string }> = (props) => {
  const { className = '' } = props;
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M22.5 12.5c0-1.58-.875-2.95-2.148-3.6.154-.435.238-.905.238-1.4 0-2.21-1.71-3.998-3.818-3.998-.47 0-.92.084-1.336.25C14.818 2.415 13.51 1.5 12 1.5s-2.816.917-3.437 2.25a3.606 3.606 0 0 0-1.336-.25c-2.11 0-3.818 1.79-3.818 4 0 .494.083.964.237 1.4-1.272.65-2.147 2.018-2.147 3.6 0 1.495.782 2.798 1.942 3.486-.02.17-.032.34-.032.514 0 2.21 1.708 4 3.818 4 .47 0 .92-.086 1.335-.25.62 1.334 1.926 2.25 3.437 2.25 1.512 0 2.818-.916 3.437-2.25.415.163.865.248 1.336.248 2.11 0 3.818-1.79 3.818-4 0-.174-.012-.344-.033-.513 1.158-.687 1.943-1.99 1.943-3.484V12.5Zm-6.616-3.334-4.334 6.5a.749.749 0 0 1-1.041.208l-.115-.094-2.415-2.415a.749.749 0 1 1 1.06-1.06l1.77 1.767 3.825-5.74a.75.75 0 0 1 1.25.833v.001Z" fill="currentColor" />
    </svg>
  )
}

type AvatarSizeString = 'thumb' | 'sm' | 'md' | 'lg';
type AvatarSize = AvatarSizeString | Size;

interface Size {
  px: number;
  className: string;
}

const sizes: Record<AvatarSizeString, Size> = {
  thumb: {
    className: 'h-12',
    px: 48,
  },
  sm: {
    className: 'h-18',
    px: 72,
  },
  md: {
    className: 'h-24 lg:h-32',
    px: 128,
  },
  lg: {
    className: 'h-32 lg:h-48',
    px: 192,
  },
};

interface AvatarProps {
  image: ImageObject;
  size?: AvatarSize;
  alt?: string;
  className?: string;
  verifiedCheck?: boolean;
}

export const Avatar: FC<AvatarProps> = props => {
  const { image, alt, size = 'sm', className = '', verifiedCheck = false } = props;
  const { px: sizePx, className: sizeClassName } =
    typeof size === 'string' ? sizes[size] : size;

  return (
    <div className="Avatar relative">
      <div
        className={`_AvatarImageContainer object-cover inline-flex rounded-full shadow-inner overflow-hidden ${sizeClassName} ${className}`}
      >
        <Image alt={alt || ''} height={sizePx} width={sizePx} image={image} />
      </div>
      {
        verifiedCheck ? (
          <CheckBadge className="absolute bottom-0 right-0 text-blue-500 transform translate-x-1" />
        ) : null
      }
    </div>
  );
};
