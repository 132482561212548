import React, { FC } from "react";

interface NotificationsSettingsScreenProps {}

export const NotificationsSettingsScreen: FC<NotificationsSettingsScreenProps> = (
  props
) => {
  return (
    <div className="_NotificationsSettingsScreen py-6 px-4 space-y-6 sm:p-6 lg:pb-8">
      <div>
        <h2 className="text-lg leading-6 font-medium text-gray-900">
          Notifications
        </h2>
      </div>
    </div>
  );
};
