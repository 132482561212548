import React from "react";
import { Toaster } from "react-hot-toast";
import { Route, Switch, Redirect } from "react-router-dom";
import { AuthenticatedApp } from "./AuthenticatedApp";
import { UnauthenticatedApp } from "./UnauthenticatedApp";
import "./App.css";
import { PrivateRoute } from "components/PrivateRoute/PrivateRoute";
import { NoMatchScreen } from "screens/NoMatchScreen/NoMatchScreen";
import { RequestPatientPacketScreen } from "screens/RequestPatientPacketScreen";

export function App() {
  return (
    <div className="_App antialiased">
      <Switch>
        <Route exact path="/">
          <Redirect to="/auth/sign_in" />
        </Route>
        <Route path="/auth">
          <UnauthenticatedApp />
        </Route>
        <Route path="/request_packet/:patientPacketId">
          <RequestPatientPacketScreen />
        </Route>
        <PrivateRoute path="/pp">
          <AuthenticatedApp />
        </PrivateRoute>
        <Route path="*">
          <NoMatchScreen />
        </Route>
      </Switch>
      <Toaster position="top-center" />
    </div>
  );
}
