import React from "react";
import { ZERO_WIDTH_SPACE } from "@preferral/common";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FAIcon } from "../FAIcon/FAIcon";

interface FilterFieldProps {
  label?: string;
  icon?: IconProp;
  className?: string;
  labelClassName?: string;
  style?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  htmlFor?: string;
  children?: React.ReactNode;
}

export const FilterField = React.forwardRef<HTMLDivElement, FilterFieldProps>(
  (props, ref) => {
    const {
      label = ZERO_WIDTH_SPACE,
      icon,
      className = '',
      labelClassName = '',
      style,
      labelStyle,
      htmlFor,
      children
    } = props;

    return (
      <div ref={ref} className={`_FilterField flex flex-col justify-end ${className}`} style={style}>
        <label
          htmlFor={htmlFor}
          className={`block text-gray-700 text-sm text-left font-bold mb-1 ${labelClassName}`}
          style={labelStyle}
        >
          {
            icon ? (
              <span className="mr-2 text-gray-600 text-xs">
                <FAIcon icon={icon} />
              </span>
            ) : null
          }
          {label}
        </label>
        {children}
      </div>
    )
  }
);
