import { FC } from "react";
import { Route, Switch } from "react-router-dom";
import { NoMatchScreen } from "screens/NoMatchScreen/NoMatchScreen";
import { SignInScreen } from "./screens/SignInScreen/SignInScreen";
import { PasswordlessLoginScreen } from "./screens/PasswordlessLoginScreen";
import { ForgotPasswordScreen } from "./screens/ForgotPasswordScreen/ForgotPasswordScreen";
import { RequestPasswordlessLoginLinkScreen } from "screens/RequestPasswordlessLoginLinkScreen";

type UnauthenticatedAppProps = {};

export const UnauthenticatedApp: FC<UnauthenticatedAppProps> = () => {
  return (
    <div className="UnauthenticatedApp w-screen h-screen">
      <Switch>
        <Route exact path="/auth/sign_in">
          <SignInScreen />
        </Route>
        <Route exact path="/auth/passwordless">
          <PasswordlessLoginScreen />
        </Route>
        <Route exact path="/auth/request_passwordless">
          <RequestPasswordlessLoginLinkScreen />
        </Route>
        <Route exact path="/auth/forgot_password">
          <ForgotPasswordScreen />
        </Route>

        <Route path="*">
          <NoMatchScreen />
        </Route>
      </Switch>
    </div>
  );
};
