import { FC } from "react";
import { gql, useQuery } from "@apollo/client";
import { Link, useRouteMatch } from "react-router-dom";
import { formatPhoneNumber } from "@preferral/common";
import { useDropdown, ZoomIn, BlankAvatar } from "@preferral/ui";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import { useAuth } from "context/AuthContext";
// import preferralCrossSrc from "images/preferral_cross.svg";
import aristamdLogoSrc from 'images/aristamd_logo_stacked.svg';
import { useLayout } from "./Layout";

const CURRENT_PATIENT_ACCOUNT = gql`
  query CurrentPatientAccount {
    me {
      id
      email
      phoneNumber
      timeZoneName
    }
  }
`;

interface Data {
  me: {
    id: string;
    email?: string;
    phoneNumber?: string;
    timeZoneName: string;
  };
}

/**
 * TopNavLink
 */

interface TopNavLinkProps {
  to: string;
}

export const TopNavLink: FC<TopNavLinkProps> = (props) => {
  const { to, children } = props;
  const match = useRouteMatch(to);
  const isActive = !!match;

  const linkClassNames = isActive
    ? "border-blue-500 text-gray-900 focus:border-blue-700"
    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:text-gray-700 focus:border-gray-300";

  return (
    <Link
      to={to}
      className={`${linkClassNames} inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium leading-5 focus:outline-none transition duration-150 ease-in-out`}
    >
      {children}
    </Link>
  );
};

/**
 * MobileMenuNavLink
 */

interface MobileMenuNavLinkProps {
  to: string;
}

export const MobileMenuNavLink: FC<MobileMenuNavLinkProps> = (props) => {
  const { to, children } = props;
  const match = useRouteMatch(to);
  const isActive = !!match;

  const linkClassNames = isActive
    ? "border-blue-500 text-blue-700 bg-blue-50 focus:text-blue-800 focus:bg-blue-100 focus:border-blue-700"
    : "border-transparent text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300";

  return (
    <Link
      to={to}
      className={`${linkClassNames} block pl-3 pr-4 py-2 border-l-4 text-base font-medium focus:outline-none transition duration-150 ease-in-out`}
    >
      {children}
    </Link>
  );
};

/**
 * TopNav.
 */

interface TopNavProps {
  userDisplayName: string;
  links: { to: string; label: string }[];
}

export const TopNav: FC<TopNavProps> = (props) => {
  const { links } = props;
  const { menuOpen, toggleMenu } = useLayout();
  const { logout } = useAuth();

  // NB: This toggle is for the desktop user dropdown menu, not the mobile one.
  const { isOpen, toggle, triggerRef, contentRef } = useDropdown();

  const { data } = useQuery<Data>(CURRENT_PATIENT_ACCOUNT);
  const email = data?.me.email;
  const phoneNumber = data?.me.phoneNumber
    ? formatPhoneNumber(data.me.phoneNumber)
    : "Loading...";

  return (
    <nav className="bg-white border-b border-gray-200">
      {/* Desktop version */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="flex-shrink-0 flex items-center">
              <img
                className="block h-8 w-auto"
                src={aristamdLogoSrc}
                alt="AristaMD logo"
              />
            </div>
            <div className="hidden sm:-my-px sm:ml-6 space-x-8 sm:flex">
              {links.map((link) => (
                <TopNavLink key={link.to} to={link.to}>
                  {link.label}
                </TopNavLink>
              ))}
            </div>
          </div>
          <div className="hidden sm:ml-6 sm:flex sm:items-center">
            <button
              className="p-1 border-2 border-transparent text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:text-gray-500 focus:bg-gray-100 transition duration-150 ease-in-out"
              aria-label="Notifications"
            >
              <BellIcon className="h-6 w-6" />
            </button>

            {/* <!-- Profile dropdown --> */}
            <div className="ml-3 relative">
              <div>
                <button
                  ref={triggerRef}
                  onClick={toggle}
                  className="flex text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition duration-150 ease-in-out"
                  id="user-menu"
                  aria-label="User menu"
                  aria-haspopup="true"
                >
                  <BlankAvatar className="w-8 h-8" />
                </button>
              </div>
              {/* <!--
              Profile dropdown panel, show/hide based on dropdown state.

              Entering: "transition ease-out duration-200"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            --> */}
              <ZoomIn
                ref={contentRef}
                show={isOpen}
                className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg"
              >
                <div className="py-1 rounded-md bg-white shadow-xs">
                  {/* <Link
                    to="/pp/settings/profile"
                    className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                  >
                    My Profile
                  </Link>

                  <Link
                    to="/pp/settings/account"
                    className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                  >
                    Account Settings
                  </Link> */}

                  <button
                    onClick={logout}
                    type="button"
                    className="block w-full text-left px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                  >
                    Sign out
                  </button>
                </div>
              </ZoomIn>
            </div>
          </div>
          <div className="-mr-2 flex items-center sm:hidden">
            {/* <!-- Mobile menu button --> */}
            <button
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
              onClick={toggleMenu}
            >
              {menuOpen ? (
                <XIcon className="block h-6 w-6" />
              ) : (
                <MenuIcon className="block h-6 w-6" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile version */}
      <div className={`${menuOpen ? "block" : "hidden"} sm:hidden`}>
        <div className="pt-2 pb-3 space-y-1">
          {links.map((link) => (
            <MobileMenuNavLink key={link.to} to={link.to}>
              {link.label}
            </MobileMenuNavLink>
          ))}
        </div>
        <div className="pt-4 pb-3 border-t border-gray-200">
          <div className="flex items-center px-4 space-x-3">
            <div className="flex-shrink-0">
              <BlankAvatar className="h-10 w-10" />
            </div>
            <div>
              <div className="text-base font-medium leading-6 text-gray-800">
                {email || phoneNumber}
              </div>
              {/* <div className="text-sm font-medium leading-5 text-gray-500">
                tom@example.com
              </div> */}
            </div>
          </div>
          <div
            className="mt-3 space-y-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu"
          >
            {/* <MobileMenuNavLink to="/pp/settings/profile">
              My Profile
            </MobileMenuNavLink>
            <MobileMenuNavLink to="/pp/settings/account">
              Account Settings
            </MobileMenuNavLink> */}

            <button
              onClick={logout}
              type="button"
              className="block w-full text-left px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:outline-none focus:text-gray-800 focus:bg-gray-100 transition duration-150 ease-in-out"
              role="menuitem"
            >
              Sign out
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};
