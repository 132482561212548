import React, { FC, useMemo, HTMLAttributes } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { CloudUploadIcon } from '../CloudUploadIcon/CloudUploadIcon';

const baseStyle = {
  borderWidth: 2,
  borderColor: '#a7a7a7',
  borderStyle: 'dashed',
  borderRadius: 16,
};

const activeStyle = {
  borderStyle: 'solid',
  borderColor: '#6c6',
  backgroundColor: '#eee',
};

const acceptStyle = {
  borderStyle: 'solid',
  borderColor: '#00e676',
};

const rejectStyle = {
  borderStyle: 'solid',
  borderColor: '#ff1744',
};

interface FileDropzoneProps {
  dropzoneOptions?: DropzoneOptions;
  prompt?: string;
  showIcon?: boolean;
}

export const FileDropzone: FC<FileDropzoneProps> = props => {
  const { prompt, dropzoneOptions, showIcon = true } = props;

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone(dropzoneOptions);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragAccept, isDragReject]
  );

  return (
    <div
      className="FileUploader px-4 py-6 cursor-pointer focus:outline-none focus:shadow-outline"
      {...(getRootProps({ style }) as HTMLAttributes<HTMLDivElement>)}
    >
      <input {...(getInputProps() as HTMLAttributes<HTMLInputElement>)} />
      {showIcon ? <CloudUploadIcon className="mx-auto" /> : null}
      <p className={`${showIcon ? 'mt-3' : ''} mx-auto text-center text-sm w-48`}>
        {prompt || "Drag 'n' drop some files here, or click to select files"}
      </p>
    </div>
  );
};
