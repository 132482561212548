import React, { FC, useState, useCallback, memo } from 'react';

interface EnvironmentNoticeProps {
  isProduction: boolean;
}

export const EnvironmentNotice: FC<EnvironmentNoticeProps> = memo(props => {
  const { isProduction } = props;
  const [dismissed, setDismissed] = useState(false);

  const dismiss = useCallback(() => setDismissed(true), [setDismissed]);

  if (isProduction || dismissed) {
    return null;
  }

  return (
    <div className="_EnvironmentNotice fixed z-50 top-6 left-1/2 transform -translate-x-1/2 w-full px-4 lg:max-w-md">
      <div className="bg-purple-100 p-4 border border-purple-300 rounded-lg shadow-2xl">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg
              className="h-5 w-5 text-purple-500"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <div className="ml-3">
            <p className="font-semibold leading-5 text-purple-800">
              This is a non-production environment
            </p>
          </div>
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                className="inline-flex rounded-md p-1.5 text-purple-500 hover:bg-purple-200 focus:outline-none focus:bg-purple-200 transition ease-in-out duration-150"
                onClick={dismiss}
              >
                <svg
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
