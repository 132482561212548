import { FC } from "react";
import { Modal } from 'components/Modal';
import { PatientFormSubmissionModel } from './PatientPacketFormScreen';
import { Link } from "react-router-dom";
import { CheckIcon, ClipboardCheckIcon, ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/outline'

interface FormSubmittedModalProps {
  isOpen: boolean;
  onClose(): void;
  patientFormSubmission: PatientFormSubmissionModel;
};

export const FormSubmittedModal: FC<FormSubmittedModalProps> = props => {
  const { isOpen, onClose, patientFormSubmission } = props;
  const { patientPacketSubmission } = patientFormSubmission;

  const packetComplete = !patientPacketSubmission.nextForm;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="_FormSubmittedModal"
    >
      <div className="w-full inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:w-full sm:p-6">
        <div>
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
            {
              packetComplete ? (
                <ClipboardCheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
              ) : (
                <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
              )
            }
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {packetComplete ? "Form Packet Complete" : "Form Submitted"}
            </h3>
            <div className="mt-2">
              {
                packetComplete ? (
                  <>
                    <p className="text-sm text-gray-500">
                      All forms in the {patientPacketSubmission.title} packet are now complete.
                    </p>
                    <p className="text-sm text-gray-500">
                      Thank you for submitting each form!
                    </p>
                  </>
                ) : (
                  <p className="text-sm text-gray-500">
                    {patientPacketSubmission.completedPatientFormsCount} of {patientPacketSubmission.patientFormSubmissionsCount} forms completed.
                  </p>
                )
              }
            </div>
          </div>
        </div>
        {
          packetComplete ? (
            <div className="mt-5 sm:mt-6">
              <Link to={`/pp/packets/${patientPacketSubmission.id}`}
                className="w-full inline-flex items-center justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                onClick={onClose}
              >
                <ArrowLeftIcon className="h-4 w-4 mr-2" />Back to Packet
              </Link>
            </div>
          ) : (
            <>
              <div className="mt-5 sm:mt-6">
                <Link to={`/pp/packets/${patientPacketSubmission.id}/form/${patientPacketSubmission.nextForm.id}`}
                  className="inline-flex justify-center items-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
                  onClick={onClose}
                >
                  Go to Next Form <ArrowRightIcon className="h-4 w-4 ml-2" />
                </Link>
              </div>
              <div className="mt-5 text-center">
                <Link to={`/pp/packets/${patientPacketSubmission.id}`}
                  className="inline-flex items-center text-sm justify-center font-medium text-indigo-600 hover:text-indigo-500"
                  onClick={onClose}
                >
                  <ArrowLeftIcon className="h-4 w-4 mr-2" />Back to Packet
                </Link>
              </div>
            </>
          )
        }

      </div >
      {/* <ModalHeader icon="file-alt" title="Form Submitted" onClose={onClose} />
      <div className="_FormSubmittedModal p-3">
        <p>Form Submitted</p>
        <p>{patientPacketSubmission.completedPatientFormsCount} of {patientPacketSubmission.patientFormSubmissionsCount} forms complete</p>
        {
          patientPacketSubmission.nextForm ? (
            <Link to={`/pp/packets/${patientPacketSubmission.id}/form/${patientPacketSubmission.nextForm.id}`}>
              Start Next Form
            </Link>
          ) : (
            <>
              <p>Packet Complete</p>
              <Link to={`/pp/packets/${patientPacketSubmission.id}`}>
                Done
              </Link>
            </>
          )
        }
      </div> */}
    </Modal >
  );
};
