import React, { forwardRef, HTMLAttributes } from 'react';

export const Container = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>((props, ref) => {
  const { className, ...rest } = props;

  return (
    <div
      className={`max-w-7xl mx-auto px-4 sm:px-6 md:px-8 ${className || ''}`}
      ref={ref}
      {...rest}
    />
  );
});
