import { FC } from 'react'
import {
  HorizontalCheckboxGroupField,
  required,
  StandardOption,
  Validator,
} from '@preferral/ui'
import { CheckboxesQuestion } from '../types'
import { useQuestion } from './QuestionnaireFormContext'

interface CheckboxesQuestionFieldProps {
  question: CheckboxesQuestion
}

export const CheckboxesQuestionField: FC<CheckboxesQuestionFieldProps> = (
  props,
) => {
  const { question } = props

  const { name, isRequired, isVisible } = useQuestion(question.id)

  if (!isVisible) {
    return null
  }

  const validate = [isRequired ? required : null].filter(Boolean) as Validator[]

  return (
    <div className="_CheckboxesQuestionField">
      <HorizontalCheckboxGroupField
        indicateRequired={isRequired}
        label={question.prompt}
        name={name}
        validate={validate}
        options={question.options.map(asOption)}
      />
    </div>
  )
}

function asOption(value: string): StandardOption {
  return {
    value,
    label: value,
  }
}
