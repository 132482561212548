import React, { FC } from "react";

const classNameMap: Record<AppColor, string> = {
  gray: "bg-gray-100 border-gray-200 text-gray-600",
  red: "bg-red-100 border-red-200 text-red-600",
  orange: "bg-orange-100 border-orange-200 text-orange-600",
  yellow: "bg-yellow-100 border-yellow-200 text-yellow-600",
  green: "bg-green-100 border-green-200 text-green-600",
  teal: "bg-teal-100 border-teal-200 text-teal-600",
  blue: "bg-blue-100 border-blue-200 text-blue-600",
  indigo: "bg-indigo-100 border-indigo-200 text-indigo-600",
  purple: "bg-purple-100 border-purple-200 text-purple-600",
  pink: "bg-pink-100 border-pink-200 text-pink-600"
};

interface BadgeProps {
  color: AppColor;
  className?: string;
}

export const Badge: FC<BadgeProps> = (props) => {
  const { color, children, className = '' } = props;

  const cn = [
    "_Badge",
    classNameMap[color],
    "border capitalize font-semibold inline-block leading-none px-2 py-1 rounded-full text-xs",
    className
  ].filter(Boolean).join(" ");

  return (
    <p className={cn}>
      {children}
    </p>
  );
};
