import React, { FC, InputHTMLAttributes, ChangeEvent, ReactNode } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useField, ErrorMessage } from 'formik';
import {
  VerticalField,
  HorizontalField,
} from '../../FieldStructure/FieldStructure';
import { Spinner } from '../../Spinner/Spinner';
import { IconContainer } from '../IconContainer';
import { SpinnerContainer } from '../SpinnerContainer';
import { FAIcon } from '../../FAIcon/FAIcon';
import { compose, ValidateProp } from '../validation';

interface InputTextProps {
  value?: string;
  icon?: IconProp;
  iconRightContent?: ReactNode;
  isLoading?: boolean;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  containerClassName?: string;
  className?: string;
  onChange?(newValue: string): void;
  onBlur?(e: any): void;
  onFocus?(e: any): void;
}

export const InputText: FC<InputTextProps> = (props) => {
  const {
    value,
    onChange,
    onBlur,
    onFocus,
    icon,
    isLoading = false,
    inputProps = {},
    containerClassName = '',
    className = '',
    iconRightContent,
  } = props;

  function handleChange(evt: ChangeEvent<HTMLInputElement>) {
    if (onChange) onChange(evt.target.value);
  }

  /**
   * NB: `type` and `className` can both be overridden by `inputProps`.
   */

  return (
    <div
      className={`rounded-md shadow-sm relative ${
        !!icon ? 'has-icons-left' : ''
      } ${containerClassName}`}
    >
      <input
        type="text"
        value={value || ''}
        onChange={handleChange}
        onBlur={onBlur}
        onFocus={onFocus}
        className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${className}`}
        {...inputProps}
      />
      {icon && (
        <IconContainer className="text-gray-500">
          <FAIcon icon={icon} />
        </IconContainer>
      )}
      {isLoading ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : iconRightContent ? (
        <SpinnerContainer>{iconRightContent}</SpinnerContainer>
      ) : null}
    </div>
  );
};

/**
 * TextInput.
 */

export interface TextInputProps {
  name: string;
  id?: string;
  placeholder?: string;
  autoFocus?: boolean;
  icon?: IconProp;
  type?: string;
  className?: string;
  isLoading?: boolean;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  validate?: ValidateProp;
}

export const TextInput: FC<TextInputProps> = (props) => {
  const {
    id,
    name,
    placeholder,
    icon,
    type = 'text',
    autoFocus = false,
    isLoading = false,
    className = '',
    inputProps = {},
    validate: validators,
  } = props;

  const validate = validators && compose([validators].flat());
  const [field, meta, helpers] = useField({ name, validate });
  const { value = '', onBlur } = field;
  const { setValue } = helpers;

  const isMobile = /iPad|iPhone|iPod/g.test(navigator.userAgent);

  return (
    <>
      <InputText
        inputProps={{
          id: id || name,
          type,
          placeholder,
          name,
          autoFocus: autoFocus && !isMobile,
          ...inputProps,
        }}
        icon={icon}
        className={
          meta && meta.touched && meta.error
            ? `${className} border border-red-500`
            : className
        }
        value={value}
        onChange={setValue}
        onBlur={onBlur}
        isLoading={isLoading}
      />
      <ErrorMessage
        component="p"
        name={name}
        className="mt-2 text-red-500 text-xs italic"
      />
    </>
  );
};

/**
 * TextField.
 */

interface TextFieldProps extends TextInputProps {
  label: string;
  indicateOptional?: boolean;
  indicateRequired?: boolean;
}

export const TextField: FC<TextFieldProps> = (props) => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;

  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <TextInput {...props} placeholder={props.placeholder || label} />
    </VerticalField>
  );
};

export const HorizontalTextField: FC<TextFieldProps> = (props) => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;

  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <TextInput {...rest} placeholder={rest.placeholder || label} />
    </HorizontalField>
  );
};
