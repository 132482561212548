import React, { Component, createRef, forwardRef, Ref } from 'react';
import { Image, ImageProps } from './Image';

type ImageWithIEPolyfillProps = {
  objectFit?:
    | 'cover'
    | '-moz-initial'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset'
    | 'contain'
    | 'fill'
    | 'none'
    | 'scale-down'
    | undefined;
  objectPosition?: string;
  innerRef?: React.Ref<Image>;
  alt?: string;
} & ImageProps;

class ImageWithIEPolyfillInner extends Component<ImageWithIEPolyfillProps> {
  static defaultProps = {
    objectFit: `cover`,
    objectPosition: `50% 50%`,
    alt: ``,
  };
  imageRef: React.RefObject<Image> =
    this.props.innerRef && typeof this.props.innerRef !== 'function'
      ? this.props.innerRef
      : createRef<Image>();

  // Load object-fit/position polyfill if required (e.g. in IE)
  componentDidMount() {
    const testImg = document.createElement(`img`);
    if (
      typeof testImg.style.objectFit === `undefined` ||
      typeof testImg.style.objectPosition === `undefined`
    ) {
      import(`object-fit-images`).then(({ default: ObjectFitImages }) =>
        ObjectFitImages(this.imageRef.current!.imageRef.current)
      );
    }
  }

  render() {
    const { objectFit, objectPosition, ...props } = this.props;

    return (
      <Image
        ref={this.imageRef}
        {...props}
        imgStyle={{
          ...props.imgStyle,
          objectFit: objectFit,
          objectPosition: objectPosition,
          fontFamily: `"object-fit: ${objectFit}; object-position: ${objectPosition}`,
        }}
      />
    );
  }
}

export const ImageWithIEPolyfill = forwardRef(
  (props: ImageWithIEPolyfillProps, ref: Ref<Image>) => (
    <ImageWithIEPolyfillInner {...props} innerRef={ref} />
  )
);
