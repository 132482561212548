import { Button } from "@preferral/ui";
import { Modal } from "components/Modal";
import { FC } from "react";
import ReactMarkdown from "react-markdown";

interface NoticeModalProps {
  isOpen: boolean;
  onClose(): void;
  text: string | null | undefined;
  logoUrl: string | null;
};

export const NoticeModal: FC<NoticeModalProps> = props => {
  const { isOpen, onClose, text, logoUrl } = props;
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      {
        logoUrl ? (
          <div className="px-4 py-8">
            <img src={logoUrl} alt="logo" className="h-24 object-contain w-full" />
          </div>
        ) : null
      }
      <article className="p-3 prose">
        {
          text ? (
            <ReactMarkdown>
              {text}
            </ReactMarkdown>
          ) : null
        }
      </article>
      <div className="p-6 text-center">
        <Button
          type="button"
          onClick={onClose}
          size="lg"
          color="blue"
        >
          Ok
        </Button>
      </div>
    </Modal>
  );
};
