import React, { FC, HTMLAttributes, ReactNode } from 'react';
import { useField, ErrorMessage } from 'formik';
import { VerticalField } from '../../FieldStructure/FieldStructure';
import { useComponentId } from '../../../hooks/useComponentId';
import { compose, ValidateProp } from '../validation';

interface InputSingleCheckboxProps {
  id?: string;
  name?: string;
  value: boolean;
  onChange(newValue: boolean): void;
  inputProps?: HTMLAttributes<HTMLInputElement>;
  checkboxLabel?: ReactNode;
  disabled?: boolean;
  checkboxClassName?: string;
}

export const InputSingleCheckbox: FC<InputSingleCheckboxProps> = props => {
  const {
    id,
    name,
    disabled = false,
    inputProps = {},
    checkboxLabel = null,
    value,
    onChange,
    checkboxClassName = ''
  } = props;

  const generatedId = useComponentId();

  function handleChange() {
    onChange(!value);
  }

  return (
    <div className="flex items-start text-sm">
      <div className="flex items-center">
        &#8203;
        <input
          id={id || name || generatedId}
          name={name}
          type="checkbox"
          checked={value}
          onChange={handleChange}
          disabled={disabled}
          className={`form-checkbox border-cool-gray-400 h-4 w-4 text-blue-600 ${checkboxClassName}`}
          {...inputProps}
        />
      </div>
      {checkboxLabel && (
        <label
          htmlFor={id || name || generatedId}
          className="ml-3 font-medium text-cool-gray-800"
        >
          {checkboxLabel}
        </label>
      )}
    </div>
  );
};

interface SingleCheckboxInputProps {
  id?: string;
  name: string;
  disabled?: boolean;
  inputProps?: HTMLAttributes<HTMLInputElement>;
  checkboxLabel?: ReactNode;
  validate?: ValidateProp;
  checkboxClassName?: string;
}

export const SingleCheckboxInput: FC<SingleCheckboxInputProps> = props => {
  const { id, name, disabled, inputProps, checkboxLabel, validate: validators, checkboxClassName } = props;

  const validate = validators && compose([validators].flat());
  const [field, , helpers] = useField({ name, validate });
  const { value } = field;
  const { setValue } = helpers;

  return (
    <>
      <InputSingleCheckbox
        id={id}
        name={name}
        disabled={disabled}
        value={value}
        onChange={setValue}
        inputProps={inputProps}
        checkboxLabel={checkboxLabel}
        checkboxClassName={checkboxClassName}
      />
      <ErrorMessage
        component="p"
        name={name}
        className="mt-2 text-red-500 text-xs italic"
      />
    </>
  );
};

interface SingleCheckboxFieldProps extends SingleCheckboxInputProps {
  label: string;
  indicateOptional?: boolean;
  indicateRequired?: boolean;
}

export const SingleCheckboxField: FC<SingleCheckboxFieldProps> = props => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;
  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <SingleCheckboxInput {...rest} />
    </VerticalField>
  );
};
