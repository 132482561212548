import React, { FC, ReactNode, CSSProperties, useMemo } from 'react';
import styled from 'styled-components/macro';
import { AppColor } from '../../styles/colorStyles';

const HoverTarget = styled.span`
  position: relative;
`;

const StyledTooltip = styled.span`
  ${HoverTarget} > & {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -85%);
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    display: inline-block;
    box-sizing: content-box;
    font-size: 0.875rem;
    font-family: sans-serif;
    text-align: center;
    border-radius: 0.375rem;
    padding: 0.25rem 0.75rem;
    transition: transform 150ms, opacity 150ms, visibility 150ms;
  }

  ${HoverTarget}:hover > & {
    transform: translate(-50%, calc(-100% - 14px));
    opacity: 1;
    visibility: visible;
  }
`;

const FrontTriangle = styled.span`
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translate(-50%, 100%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  border-top: 10px solid currentColor;
`;

const BackTriangle = styled.span`
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translate(-50%, 100%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid currentColor;
`;

const bodyClassNames: Record<AppColor, string> = {
  gray: "bg-gray-100 border-gray-300 text-gray-700",
  red: "bg-red-100 border-red-300 text-red-700",
  orange: "bg-orange-100 border-orange-300 text-orange-700",
  yellow: "bg-yellow-100 border-yellow-300 text-yellow-700",
  green: "bg-green-100 border-green-300 text-green-700",
  teal: "bg-teal-100 border-teal-300 text-teal-700",
  blue: "bg-blue-100 border-blue-300 text-blue-700",
  indigo: "bg-indigo-100 border-indigo-300 text-indigo-700",
  purple: "bg-purple-100 border-purple-300 text-purple-700",
  pink: "bg-pink-100 border-pink-300 text-pink-700",
};

const lightTriangleClassName: Record<AppColor, string> = {
  gray: "text-gray-100",
  red: "text-red-100",
  orange: "text-orange-100",
  yellow: "text-yellow-100",
  green: "text-green-100",
  teal: "text-teal-100",
  blue: "text-blue-100",
  indigo: "text-indigo-100",
  purple: "text-purple-100",
  pink: "text-pink-100",
}

const darkTriangleClassName: Record<AppColor, string> = {
  gray: "text-gray-300",
  red: "text-red-300",
  orange: "text-orange-300",
  yellow: "text-yellow-300",
  green: "text-green-300",
  teal: "text-teal-300",
  blue: "text-blue-300",
  indigo: "text-indigo-300",
  purple: "text-purple-300",
  pink: "text-pink-300",
}

const MAX_WIDTH = 240;

interface TooltipProps {
  tip?: ReactNode | null | false;
  color?: AppColor;
  onMouseLeave?(): void;
  style?: CSSProperties;
}

export const Tooltip: FC<TooltipProps> = props => {
  const { tip, children, onMouseLeave, color = 'blue', style: styleProp = {} } = props;

  const width = Math.min((tip?.toString().length || 0) * 6.66 || MAX_WIDTH, MAX_WIDTH)
  const style = useMemo(() => ({ width, ...styleProp }), [width, styleProp]);

  if (!tip) return <>{children}</>;

  const bodyCn = [
    "border shadow-lg",
    bodyClassNames[color]
  ].filter(Boolean).join(" ");

  return (
    <HoverTarget onMouseLeave={onMouseLeave}>
      {children}
      <StyledTooltip style={style} className={bodyCn}>
        <span className="block relative">
          <span>{tip}</span>
          <BackTriangle className={darkTriangleClassName[color]} />
          <FrontTriangle className={lightTriangleClassName[color]} />
        </span>
      </StyledTooltip>
    </HoverTarget>
  );
};
