import { ApolloLink, Observable, HttpOptions, selectURI, selectHttpOptionsAndBody, fallbackHttpConfig, parseAndCheckHttpResponse } from "@apollo/client";
import { print } from "graphql/language/printer";
import { extractFiles } from "./extractFiles";

function coalesceCredentials(value: string | undefined) {
  // "omit" | "same-origin" | "include" | undefined
  if (value === "omit") {
    return "omit";
  } else if (value === "same-origin") {
    return "same-origin";
  } else if (value === "include") {
    return "include";
  } else {
    return undefined;
  }
}

export const createUploadMiddleware = (opts: HttpOptions = {}) => {
  const {
    uri: fetchUri = "/graphql",
    headers,
    fetch: linkFetch = fetch,
    credentials,
    includeExtensions,
    fetchOptions,
  } = opts;

  return new ApolloLink((operation, forward) => {
    const { variables, files } = extractFiles(operation.variables);

    // console.log("variables", variables);
    // console.log("files", files);

    if (files.length > 0) {
      const context = operation.getContext();
      const { headers: contextHeaders } = context;
      const formData = new FormData();

      formData.append("query", print(operation.query));
      formData.append("variables", JSON.stringify(variables));

      files.forEach(({ name, file }) => {
        if (typeof Blob !== "undefined" && file instanceof Blob) {
          const parts = file.type.split("/");
          const ext = parts[parts.length - 1];
          const fileName =
            file instanceof File && file.name ? file.name : `blob.${ext}`;
          formData.append(name, file, fileName);
        } else {
          formData.append(name, file);
        }
      });

      const linkConfig = {
        http: { includeExtensions },
        options: fetchOptions,
        credentials,
        headers,
      };

      let finalOptions: RequestInit = {
        method: "POST",
        headers: Object.assign({}, contextHeaders, headers),
        body: formData,
        credentials: coalesceCredentials(credentials),
      };

      const contextConfig = {
        http: context.http,
        options: context.fetchOptions,
        credentials: context.credentials,
        headers: context.headers,
      };

      let { options } = selectHttpOptionsAndBody(
        operation,
        fallbackHttpConfig,
        linkConfig,
        contextConfig
      );

      finalOptions = Object.assign(options, finalOptions);

      return new Observable((observer) => {
        const uri = selectURI(operation, fetchUri);
        linkFetch(uri, finalOptions)
          .then((response) => {
            operation.setContext({ response });
            return response;
          })
          .then(parseAndCheckHttpResponse(operation))
          .then((result) => {
            // we have data and can send it back up the link chain
            observer.next(result);
            observer.complete();
            return result;
          })
          .catch((err) => {
            if (err.result && err.result.errors && err.result.data) {
              observer.next(err.result);
            }
            observer.error(err);
          });
      });
    }
    return forward ? forward(operation) : null;
  });
};
