import React, { FC } from "react";

interface AccountSettingsScreenProps {}

export const AccountSettingsScreen: FC<AccountSettingsScreenProps> = (
  props
) => {
  return (
    <div className="_AccountSettingsScreen py-6 px-4 space-y-6 sm:p-6 lg:pb-8">
      <div>
        <h2 className="text-lg leading-6 font-medium text-gray-900">Account</h2>
      </div>
    </div>
  );
};
