import React, { FC, ChangeEvent } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ErrorMessage, useField } from 'formik';
import {
  HorizontalField,
  VerticalField,
} from '../../FieldStructure/FieldStructure';
import { FAIcon } from '../../FAIcon/FAIcon';
import { IconContainer } from '../IconContainer';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import { compose, ValidateProp } from '../validation';

// prettier-ignore
const ssnMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

/**
 * InputSSNMask.
 */

export interface InputSSNMaskProps {
  value: string;
  icon?: IconProp;
  inputProps?: MaskedInputProps;
  className?: string;
  onChange(newValue: string): void;
  onBlur?(e: any): void;
}

export const InputSSNMask: FC<InputSSNMaskProps> = props => {
  const {
    value,
    onChange,
    onBlur,
    icon,
    inputProps = {},
    className = '',
  } = props;

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    onChange(evt.target.value);
  };

  /**
   * NB: `type` and `className` can both be overridden by `inputProps`.
   */

  return (
    <div
      className={`rounded-md shadow-sm relative ${!!icon ? 'has-icons-left' : ''
        }`}
    >
      <MaskedInput
        type="text"
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${className}`}
        mask={ssnMask}
        {...inputProps}
      />
      {icon && (
        <IconContainer className="text-gray-500">
          <FAIcon icon={icon} />
        </IconContainer>
      )}
    </div>
  );
};

export interface SSNMaskInputProps {
  name: string;
  id?: string;
  placeholder?: string;
  autoFocus?: boolean;
  icon?: IconProp;
  type?: string;
  className?: string;
  inputProps?: MaskedInputProps;
  validate?: ValidateProp;
}

export const SSNMaskInput: FC<SSNMaskInputProps> = props => {
  const {
    id,
    name,
    placeholder,
    icon,
    type = 'text',
    autoFocus = false,
    className = '',
    inputProps = {},
    validate: validators
  } = props;

  const validate = validators && compose([validators].flat());
  const [field, meta, helpers] = useField({ name, validate });
  const { value, onBlur } = field;
  const { setValue } = helpers;

  return (
    <>
      <InputSSNMask
        inputProps={{
          id: id || name,
          type,
          placeholder: placeholder || '(xxx) xxx-xxxx',
          name,
          autoFocus,
          ...inputProps,
        }}
        icon={icon}
        className={
          meta && meta.touched && meta.error
            ? `${className} border border-red-500`
            : className
        }
        value={value}
        onChange={setValue}
        onBlur={onBlur}
      />
      <ErrorMessage
        component="p"
        name={name}
        className="mt-2 text-red-500 text-xs italic"
      />
    </>
  );
};

export interface SSNMaskFieldProps extends SSNMaskInputProps {
  label: string;
  indicateOptional?: boolean;
  indicateRequired?: boolean;
}

export const SSNMaskField: FC<SSNMaskFieldProps> = props => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;

  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <SSNMaskInput {...rest} />
    </VerticalField>
  );
};

export const HorizontalSSNMaskField: FC<SSNMaskFieldProps> = props => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;

  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <SSNMaskInput {...rest} />
    </HorizontalField>
  );
};
