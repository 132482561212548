import React, { forwardRef, HTMLAttributes, ReactNode } from 'react';

type FieldStructureProps = {
  id?: string;
  name?: string;
  indicateOptional?: boolean;
  indicateRequired?: boolean;
  label: ReactNode;
  labelId?: string;
  className?: string;
  labelClassName?: string;
  htmlFor?: string;
} & HTMLAttributes<HTMLDivElement>;

export const VerticalField = forwardRef<HTMLDivElement, FieldStructureProps>(
  (props, ref) => {
    const {
      name,
      label,
      indicateOptional = false,
      indicateRequired = false,
      children,
      className = '',
      labelId,
      htmlFor,
      ...rest
    } = props;
    return (
      <div className={`text-left ${className}`} ref={ref} {...rest}>
        <label
          id={labelId}
          htmlFor={htmlFor}
          className="block text-gray-700 text-left font-bold mb-1"
        >
          {label}
          {indicateOptional && (
            <span className="font-normal ml-2 text-gray-500 text-xs">
              Optional
            </span>
          )}
          {indicateRequired && (
            <span className="font-normal italic ml-2 text-purple-500 text-xs">
              Required
            </span>
          )}
        </label>
        {children}
      </div>
    );
  }
);

export const HorizontalField = forwardRef<HTMLDivElement, FieldStructureProps>(
  (props, ref) => {
    const {
      name,
      label,
      indicateOptional = false,
      indicateRequired = false,
      children,
      className = '',
      labelId,
      htmlFor,
      ...rest
    } = props;

    return (
      <div
        className={`md:flex md:items-start ${className}`}
        ref={ref}
        {...rest}
      >
        <div className="md:w-1/3">
          <label
            id={labelId}
            htmlFor={htmlFor}
            className="block py-1 md:py-2 text-gray-700 font-bold md:text-right mb-1 md:mb-0 pr-4"
          >
            {label}
            {indicateOptional && (
              <span className="md:block font-normal ml-2 text-gray-500 text-xs">
                Optional
              </span>
            )}
            {indicateRequired && (
              <span className="md:block font-normal italic ml-2 text-purple-500 text-xs">
                Required
              </span>
            )}
          </label>
        </div>
        <div className="md:w-2/3 md:py-1">
          <div className="max-w-lg">
            {children}
          </div>
        </div>
      </div>
    );
  }
);
