import { FC } from "react";
import { useAuth } from "context/AuthContext";
import { Route, RouteProps, Redirect, useLocation } from "react-router-dom";
import qs from "query-string";

interface PrivateRouteProps extends RouteProps { }

export const PrivateRoute: FC<PrivateRouteProps> = (props) => {
  const { children, ...rest } = props;
  const location = useLocation();
  const { plt: passwordlessLoginToken } = qs.parse(location.search);
  const { token: sessionToken } = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        sessionToken ? (
          children
        ) : passwordlessLoginToken ? (
          <Redirect
            to={{
              pathname: "/auth/passwordless",
              state: { from: location },
            }}
          />
        ) : (
          <Redirect
            to={{
              pathname: "/auth/sign_in",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
