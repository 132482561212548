import { FC, useState, useCallback } from "react";
import { useRouteMatch } from "react-router-dom";
import { gql, useQuery } from '@apollo/client';
import { ClipboardListIcon } from '@heroicons/react/outline'
import { RequestPacketForm } from "./RequestPacketForm";
import { Button, FAIcon, Spinner } from "@preferral/ui";
import { usernameKind } from "screens/RequestPasswordlessLoginLinkScreen/helpers";
import { ReactComponent as LinkSentPic } from "screens/RequestPasswordlessLoginLinkScreen/link_sent.svg";
import { formatPhoneNumber } from "@preferral/common";
import { TipsModal } from "./TipsModal";

const PATIENT_PACKET = gql`
  query GetPatientPacket($id: UUID4!) {
    patientPacket(id: $id) {
      id
      title
      department {
        id
        name
        logoUrl
        organization {
          id
          name
          logoUrl
        }
      }
    }
  }
`;

interface Data {
  patientPacket: PatientPacketModel;
}

interface Variables {
  id: string;
}

interface PatientPacketModel {
  id: string;
  title: string;
  department: {
    id: string;
    name: string;
    logoUrl?: string;
    organization: {
      id: string;
      name: string;
      logoUrl?: string;
    }
  }
}


/**
 * RequestPacket.
 */

interface RequestPacketProps {
  patientPacket: PatientPacketModel;
  onSuccess(sentTo: string): void;
}

const RequestPacket: FC<RequestPacketProps> = props => {
  const { onSuccess, patientPacket } = props;

  // const { data, loading, error } = useQuery<Data, Variables>(PATIENT_PACKET, { variables: { id: patientPacketId } });
  const orgDeptName = patientPacket.department.name === patientPacket.department.organization.name ?
    patientPacket.department.name :
    `${patientPacket.department.organization.name} - ${patientPacket.department.name}`;

  return (
    <div className="_RequestLink bg-white mt-8 px-4 sm:px-12 py-4 sm:py-8 rounded-xl sm:rounded-2xl shadow-2xl sm:mx-auto sm:w-full">
      <div className="text-center">
        <div className="bg-blue-50 border border-blue-500 inline-block mx-auto p-4 rounded-full shadow-md">
          <ClipboardListIcon className="h-12 text-blue-700 w-12" style={{ transform: "skew(-10deg, -3deg) rotate(12deg)" }} />
        </div>
      </div>

      <h4 className="mt-4 border-b border-purple-300 font-semibold inline-block italic m-0 pr-4 text-purple-500 text-xs">Patient Form Packet</h4>
      <div className="flex items-center mt-3">
        <span className="mr-3 text-center text-gray-400 text-lg w-5">
          <FAIcon icon="file-alt" />
        </span>
        <h2 className="font-bold leading-9 m-0 text-2xl text-gray-900">
          {patientPacket.title}
        </h2>
      </div>
      <div className="flex items-center">
        <span className="mr-3 text-center text-gray-400 text-sm w-5">
          <FAIcon icon="hospital-alt" />
        </span>
        <h3 className="font-regular m-0 text-base text-cool-gray-500">{orgDeptName}</h3>
      </div>
      <p className="leading-snug mt-6 text-base text-gray-700">
        Enter your email address or cell phone number below to receive the form packet.
      </p>
      <div className="mt-6">
        <RequestPacketForm patientPacketId={patientPacket.id} onSuccess={onSuccess} />
      </div>

    </div>
  )
}

/**
 * PacketSent.
 */

interface PacketSentProps {
  patientPacket: PatientPacketModel;
  sentTo: string;
  resetForm(): void;
}

type ActiveModal = "TIPS";

const PacketSent: FC<PacketSentProps> = props => {
  const { patientPacket, sentTo, resetForm } = props;
  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  const closeModal = useCallback(() => setActiveModal(null), [setActiveModal]);
  const openTipsModal = useCallback(() => setActiveModal("TIPS"), [setActiveModal]);

  const kind = usernameKind(sentTo);

  const messageTypeProse = kind === "PHONE_NUMBER" ? "a text message" : "an email";
  const sentToProse = kind === "PHONE_NUMBER" ? formatPhoneNumber(sentTo) : sentTo;

  return (
    <>
      <TipsModal
        isOpen={activeModal === "TIPS"}
        onClose={closeModal}
        sentTo={sentTo}
      />
      <div className="_RequestLink bg-white mt-8 px-4 sm:px-12 py-4 sm:py-8 rounded-xl sm:rounded-2xl shadow-2xl sm:mx-auto sm:w-full">
        <LinkSentPic className="mx-auto w-36" />
        <h2 className="font-bold leading-9 mt-6 text-2xl text-center text-gray-900">
          Your {patientPacket.title} link is on the way
        </h2>
        <p className="leading-snug mt-6 text-base text-center text-gray-700">
          You should receive {messageTypeProse} at <span className="font-semibold underline italic">{sentToProse}</span>{" "}with the
          link.
        </p>
        <p className="leading-snug mt-6 text-base text-center text-gray-700">
          The link expires in 24 hours and can only be used once.
        </p>
        <div className="mt-12 border-t pt-6 pb-4 text-center">
          <p className="text-sm">
            Didn't get the link?
            <Button
              className="inline-block ml-3"
              type="button"
              size="xs"
              onClick={openTipsModal}
            >
              Try these tips
            </Button>
          </p>
        </div>
      </div>
      <div className="mt-6 text-center">
        <Button type="button" onClick={resetForm}>
          <span className="mr-2">
            <FAIcon icon="arrow-left" />
          </span>{" "}
          Back to Request Packet form
        </Button>
      </div>
    </>
  )
}

/**
 * RequestPatientPacketScreen.
 */

interface RequestPatientPacketScreenProps { };

interface RouteParams {
  patientPacketId: string;
}

export const RequestPatientPacketScreen: FC<RequestPatientPacketScreenProps> = () => {
  const match = useRouteMatch<RouteParams>();
  const { patientPacketId } = match.params;

  const [sentTo, setSentTo] = useState<string | null>(null);

  const { data, loading, error } = useQuery<Data, Variables>(PATIENT_PACKET, { variables: { id: patientPacketId } });

  const logoUrl = data?.patientPacket.department.logoUrl || data?.patientPacket.department.organization.logoUrl;

  return (
    <div className="_RequestPatientPacketScreen min-w-screen min-h-screen bg-cool-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      {
        loading ? (
          <div className="p-4 text-center">
            <Spinner />
          </div>
        ) : error || !data?.patientPacket ? (
          <p>Failed to load.</p>
        ) : (
          <div className="sm:mx-auto sm:w-full sm:max-w-lg">
            {
              logoUrl ? (
                <img className="mx-auto max-h-24 max-w-xs" src={logoUrl} alt="Organization Logo" />
              ) : null
            }
            {!!sentTo ? (
              <PacketSent
                patientPacket={data.patientPacket}
                sentTo={sentTo}
                resetForm={() => setSentTo(null)}
              />
            ) : (
              <RequestPacket
                patientPacket={data.patientPacket}
                onSuccess={setSentTo}
              />
            )
            }
          </div>
        )
      }
    </div>
  );
};
