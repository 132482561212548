import React, { FC, HTMLAttributes } from 'react';

export const CloudUploadIcon: FC<HTMLAttributes<SVGElement>> = props => {
  return (
    <svg
      width="85"
      height="66"
      viewBox="0 0 85 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M36.9552 51H16.6563C12.4433 51 4.40027 45.2669 4.01732 36.4762C3.41793 22.7168 18.5713 20.4236 18.5713 20.4236C18.5713 20.4236 18.5713 13.1617 28.1462 8.5752C35.8062 4.90603 43.4662 10.4862 43.4662 10.4862C43.4662 10.4862 48.4451 2.16944 57.6371 4.37094C66.0631 6.38899 66.829 15.8371 66.829 15.8371C66.829 15.8371 81 20.4236 81 33.0363C81 43.7381 70.6591 51 66.0631 51H51.8921"
        stroke="#ADB8C2"
        strokeWidth="6"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.3799 37.312C32.6215 38.0704 32.6215 39.3 33.3799 40.0584C34.1383 40.8168 35.3679 40.8168 36.1263 40.0584L42.4237 33.761L42.4237 62.8915C42.4237 64.056 43.2932 65 44.3657 65C45.4383 65 46.3078 64.056 46.3078 62.8915L46.3078 33.7614L52.6048 40.0584C53.3632 40.8168 54.5929 40.8168 55.3513 40.0584C56.1097 39.3 56.1097 38.0704 55.3513 37.312L45.7388 27.6995C44.9804 26.9411 43.7508 26.9411 42.9924 27.6995L33.3799 37.312Z"
        fill="#6B839D"
      />
    </svg>
  );
};
