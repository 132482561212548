import { FC } from 'react'
import { HorizontalPhoneMaskField, required, Validator, validatePhone } from '@preferral/ui'
import { PhoneNumberQuestion } from '../types'
import { useQuestion } from './QuestionnaireFormContext'

interface PhoneNumberQuestionFieldProps {
  question: PhoneNumberQuestion
}

export const PhoneNumberQuestionField: FC<PhoneNumberQuestionFieldProps> = (
  props,
) => {
  const { question } = props

  const { name, isRequired, isVisible } = useQuestion(question.id)

  if (!isVisible) {
    return null
  }

  const validate = [
    isRequired ? required : null,
    validatePhone
  ].filter(Boolean) as Validator[]

  return (
    <div className="_PhoneNumberQuestionField">
      <HorizontalPhoneMaskField
        indicateRequired={isRequired}
        label={question.prompt}
        name={name}
        validate={validate}
      />
    </div>
  )
}
