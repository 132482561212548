import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { gql, useMutation } from "@apollo/client";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { TextField, Button } from "@preferral/ui";
import { useAuth } from "context/AuthContext";
import toast from "react-hot-toast";

const LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      errors {
        key
        message
      }
      session {
        token
      }
    }
  }
`;

interface Data {
  login: {
    errors?: InputError[];
    session?: {
      token: string;
    };
  };
}

interface Variables {
  username: string;
  password: string;
}

interface FormValues {
  username: string;
  password: string;
}

interface SignInFormProps { }

export const SignInForm: React.FC<SignInFormProps> = () => {
  const { login: signIn } = useAuth();
  const [login] = useMutation<Data, Variables>(LOGIN);

  const onSubmit = useCallback(
    async (values: FormValues, formikActions: any) => {
      const { setStatus, setSubmitting } = formikActions;
      setStatus({ errors: null });

      try {
        const { data } = await login({ variables: values });

        if (data?.login.errors) {
          setStatus({ errors: data.login.errors });
        } else if (data?.login?.session) {
          // it worked
          const { token } = data.login.session;
          return signIn(token);
        }
        setSubmitting(false);
      } catch (e) {
        toast.error("Something went wrong.");
        console.error(e);
        setSubmitting(false);
      }
    },
    [login, signIn]
  );

  return (
    <>
      <div className="_SignInForm bg-white py-8 px-4 shadow-xl sm:rounded-2xl sm:px-10">
        <Formik<FormValues>
          initialValues={{ username: "", password: "" }}
          validationSchema={Yup.object().shape({
            username: Yup.string().trim().required("Required"),
            password: Yup.string().required("Required"),
          })}
          onSubmit={onSubmit}
        >
          {({ status, isSubmitting, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormStatusErrors status={status} />

              <TextField
                name="username"
                type="text"
                label="Email or Phone Number"
                autoFocus
              />

              <div className="mt-3">
                <TextField
                  name="password"
                  placeholder="••••••••••"
                  type="password"
                  label="Password"
                />
              </div>

              <div className="mt-8">
                <span className="block w-full rounded-md shadow-sm">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    color="blue"
                    className="w-full justify-center"
                  >
                    Sign in
                  </Button>
                </span>
              </div>
            </form>
          )}
        </Formik>
      </div>
      <div className="mt-4 text-sm">
        <p className="leading-5 max-w mt-1 text-center text-gray-600 text-sm">
          Don't want to type your password?{" "}
          <Link
            to="/auth/request_passwordless"
            className="inline-block ml-2 text-blue-500 hover:text-blue-700 font-semibold"
          >
            Get an instant sign-in link
          </Link>
        </p>
        {/* <p className="leading-5 max-w mt-1 text-center text-gray-600 text-sm">
          Using SSO?{" "}
          <Link
            to="/auth/sso"
            className="inline-block ml-2 text-blue-500 hover:text-blue-700 font-semibold"
          >
            Sign in with SSO
          </Link>
        </p> */}
        <p className="leading-5 max-w mt-1 text-center text-gray-600 text-sm">
          <Link
            to="/auth/forgot_password"
            className="inline-block ml-2 text-blue-500 hover:text-blue-700 font-semibold"
          >
            Forgot password?
          </Link>
        </p>
      </div>
    </>
  );
};
