import React, { FC } from 'react';
import { useField, ErrorMessage } from 'formik';
import {
  VerticalField,
  HorizontalField,
} from '../../FieldStructure/FieldStructure';
import { StandardOption } from '../SelectField/SelectField';
import {
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '../../RadioGroup/RadioGroup';
import { compose, ValidateProp } from '../validation';

/**
 * RadioGroupInput.
 */

export interface RadioGroupInputProps {
  name: string;
  id?: string;
  className?: string;
  inline?: boolean;
  options: StandardOption[];
  inputProps?: Partial<RadioGroupProps<string>>;
  validate?: ValidateProp;
  isClearable?: boolean;
}

export const RadioGroupInput: FC<RadioGroupInputProps> = (props) => {
  const { name, id, className, inline, options, inputProps = {}, validate: validators, isClearable = false } = props;

  const validate = validators && compose([validators].flat());
  const [{ onBlur, ...field }, meta, helpers] = useField({ name, validate });
  const { setValue } = helpers;

  const showError = !!(meta && meta.touched && meta.error);

  const controlClassName = [
    'w-full',
    showError && '-m-2 p-2 border border-red-500 rounded',
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <div className="max-w-lg">
      <div className={controlClassName}>
        <RadioGroup
          {...field}
          labelledBy={`field--${id || name}`}
          onChange={setValue}
          onBlur={onBlur(name)}
          className={className}
          inline={inline}
          isClearable={isClearable}
          {...inputProps}
        >
          {options.map((option) => (
            <Radio key={option.value} value={option.value}>
              {option.label}
            </Radio>
          ))}
        </RadioGroup>
      </div>
      <ErrorMessage
        component="p"
        name={name}
        className="mt-2 text-red-500 text-xs italic"
      />
    </div>
  );
};

/**
 * RadioGroupField.
 */

export interface RadioGroupFieldProps extends RadioGroupInputProps {
  label: string;
  indicateOptional?: boolean;
  indicateRequired?: boolean;
}

export const RadioGroupField: FC<RadioGroupFieldProps> = (props) => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;

  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <RadioGroupInput {...rest} />
    </VerticalField>
  );
};

export const HorizontalRadioGroupField: FC<RadioGroupFieldProps> = (props) => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;

  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <RadioGroupInput {...rest} />
    </HorizontalField>
  );
};
