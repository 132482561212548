import React, { FC, useEffect, useRef, useCallback } from 'react';
import { Icon } from '../Icon/Icon';
import { FileIcon, iconNameForMIME } from '../FileIcon/FileIcon';
import { Spinner } from '../Spinner/Spinner';
import format from 'date-fns/format';
import { FAIcon } from '../FAIcon/FAIcon';
import { CircleProgress } from './CircleProgress';
import { useFileUploader } from './FileUploaderContext';
import { humanFileSize } from './helpers';
import { AbortHandler, DoUploadFn, UploadModel } from './model';

interface FileUploadProps {
  instanceId: string;
  upload: UploadModel;
  doUpload: DoUploadFn;
  onSuccess?(fileUpload: UploadModel): void;
  onRemove?(fileUpload: UploadModel): void;
}

const NewFileUpload: FC<FileUploadProps> = props => {
  const { instanceId, doUpload, upload, onSuccess, onRemove } = props;

  const aborter = useRef<AbortHandler | null>(null);
  const { actions } = useFileUploader({ instanceId });

  const onProgress = useCallback(
    (ev: ProgressEvent) => {
      const progress = ev.loaded / ev.total;
      actions.uploadProgressed(upload.localId, progress);
    },
    [actions.uploadProgressed, upload.localId]
  );

  const onAbortPossible = useCallback((abortHandler: AbortHandler) => {
    aborter.current = abortHandler;
  }, []);

  // On Mount, upload the file
  useEffect(() => {
    actions.uploadStarted(upload.localId);
    doUpload(upload, { onProgress, onAbortPossible })
      .then(remoteRef => {
        aborter.current = null;
        actions.uploadCompleted(upload.localId, remoteRef);
        // NB: Going to call onSuccess callback in effect, because
        //     we need to be sure state update has completed.
        //     Not ideal, but necessary...
      })
      .catch((err: any) => {
        console.error(err);
        throw err;
      });
  }, []);

  // On Unmount, if the file upload isn't complete, abort.
  useEffect(() => {
    return () => {
      if (aborter.current) {
        aborter.current();
      }
    };
  }, []);

  // If the file gets successfully uploaded, do onSuccess:
  const onSuccessCalled = useRef(false);
  useEffect(() => {
    if (
      upload.status === 'COMPLETED' &&
      onSuccess &&
      !onSuccessCalled.current
    ) {
      onSuccessCalled.current = true;
      onSuccess(upload);
    }
  }, [upload, onSuccess]);

  const handleRemove = useCallback(() => {
    actions.uploadRemoved(upload.localId);
    if (onRemove) onRemove(upload);
  }, [upload.localId, actions.uploadRemoved, onRemove]);

  return (
    <div className="flex items-center -mx-2 border-b">
      <div className="_file-icon p-2">
        <FileIcon icon={iconNameForMIME(upload.file.type)} size={32} />
      </div>
      <div className="_file-info flex-1 p-2">
        <p className="_file-info-name font-semibold text-gray-800 text-sm">
          {upload.file.name}
        </p>
        <p className="_file-info-meta text-gray-600 text-xs">
          {format(upload.file.lastModified, 'M/d/yy')} -{' '}
          {humanFileSize(upload.file.size)}
        </p>
      </div>
      {upload.progress >= 1 && upload.status === 'IN_PROGRESS' ? (
        <div className="_file-finishing-spinner p-2">
          <Spinner />
        </div>
      ) : null}
      <div className="_file-progress-actions p-2">
        {upload.progress < 1 ? (
          <div className="relative">
            <CircleProgress progress={upload.progress * 100} />
            <button
              type="button"
              className="absolute bg-white border border-red-700 cursor-pointer flex h-8 items-center justify-center rounded-full opacity-0 hover:opacity-100 transition-opacity transition-faster w-8"
              style={{
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
              }}
              onClick={handleRemove}
            >
              <Icon icon="x" size={30} color="red" />
            </button>
          </div>
        ) : (
          <button
            type="button"
            className="btn btn-red-alt"
            onClick={handleRemove}
          >
            <FAIcon icon="trash" />
          </button>
        )}
      </div>
    </div>
  );
};

/**
 * FileList.
 */

interface FileListProps {
  instanceId: string;
  doUpload: DoUploadFn;
  onUploadSuccess?(upload: UploadModel): void;
  onRemove?(userUpload: UploadModel): void;
}

export const FileList: FC<FileListProps> = props => {
  const { instanceId, doUpload, onRemove, onUploadSuccess } = props;

  const { state } = useFileUploader({ instanceId });
  const uploads = Object.values(state.uploads);

  return (
    <div className="_FileList">
      {uploads.map(upload => (
        <NewFileUpload
          key={upload.localId}
          instanceId={instanceId}
          upload={upload}
          doUpload={doUpload}
          onSuccess={onUploadSuccess}
          onRemove={onRemove}
        />
      ))}
    </div>
  );
};
