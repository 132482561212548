import { FC } from 'react'
import {
  HorizontalRadioGroupField,
  required,
  StandardOption,
  Validator,
} from '@preferral/ui'
import { RadioQuestion } from '../types'
import { useQuestion } from './QuestionnaireFormContext'

interface RadioQuestionFieldProps {
  question: RadioQuestion
}

export const RadioQuestionField: FC<RadioQuestionFieldProps> = (props) => {
  const { question } = props

  const { name, isVisible, isRequired } = useQuestion(question.id)

  if (!isVisible) {
    return null
  }

  const validate = [isRequired ? required : null].filter(Boolean) as Validator[]

  return (
    <div className="_RadioQuestionField">
      <HorizontalRadioGroupField
        indicateRequired={isRequired}
        name={name}
        label={question.prompt}
        validate={validate}
        options={question.options.map(asOption)}
        isClearable
      />
    </div>
  )
}

function asOption(value: string): StandardOption {
  return {
    value,
    label: value,
  }
}
