import { FC } from "react";
import { formatPhoneNumber } from "@preferral/common";
import { Modal, ModalHeader } from "components/Modal";
import { usernameKind } from "./helpers";

/**
 * Tip.
 */

interface TipProps {
  number: number;
  title: string;
}

const Tip: FC<TipProps> = (props) => {
  const { number, title, children } = props;

  return (
    <div className="_Tip w-full px-0 sm:px-4 py-4 border-t flex">
      <div className="flex-grow-0 p-2">
        <p className="bg-teal-200 text-teal-700 flex font-black h-8 items-center justify-center rounded-full text-xl w-8">
          {number}
        </p>
      </div>
      <div className="flex-grow p-2">
        <h5 className="text-xl font-semibold text-gray-800 leading-snug">
          {title}
        </h5>
        <div className="py-4">{children}</div>
      </div>
    </div>
  );
};

/**
 * TipsModal.
 */

interface TipsModalProps {
  isOpen: boolean;
  onClose(): void;
  sentTo: string;
}

export const TipsModal: FC<TipsModalProps> = (props) => {
  const { isOpen, onClose, sentTo } = props;

  const kind = usernameKind(sentTo);

  return (
    <Modal
      className="_TipsModal max-w-xl"
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <ModalHeader
        icon="question"
        title="Try these tips if you didn't receive a link."
        onClose={onClose}
      />
      <div className="p-4">
        <Tip
          number={1}
          title={`Confirm you're using the correct ${kind === "PHONE_NUMBER" ? "phone number" : "email"
            }.`}
        >
          <p className="text-sm text-gray-800">
            Double check that{" "}
            <span className="font-semibold italic underline">
              {kind === "PHONE_NUMBER" ? formatPhoneNumber(sentTo) : sentTo}
            </span>{" "}
            is associated with your account
            {kind !== "PHONE_NUMBER" ? " and spelled correctly" : ""}.
          </p>
          <p className="text-sm text-gray-800 mt-4">
            Did you put in the wrong{" "}
            {kind === "PHONE_NUMBER" ? "phone number" : "email"}? Just request a
            new link.
          </p>
        </Tip>

        {kind !== "PHONE_NUMBER" ? (
          <Tip number={2} title="Check your Spam/Junk folder.">
            <p className="text-sm text-gray-800">
              Add{" "}
              <span className="font-semibold italic underline">
                yourprovider@preferral.com
              </span>{" "}
              to your contact list to prevent these emails from going to Spam.
            </p>
          </Tip>
        ) : null}

        {kind === "PHONE_NUMBER" ? (
          <Tip number={2} title="Try using your email address.">
            <p className="text-sm text-gray-800">
              Is your account associated with your email address and not your
              phone number?
            </p>
            <p className="text-sm text-gray-800 mt-4">
              If so, request a link using your email address.
            </p>
          </Tip>
        ) : (
          <Tip number={3} title="Try using your phone number.">
            <p className="text-sm text-gray-800">
              Is your account associated with your phone number and not your
              email address?
            </p>
            <p className="text-sm text-gray-800 mt-4">
              If so, request a link using your phone number.
            </p>
          </Tip>
        )}

        {/* <Tip
          number={kind !== "PHONE_NUMBER" ? 4 : 3}
          title="Call our office to request a Pin code."
        >
          <p className="text-sm text-gray-800">
            Contact us by calling, (555) 555-5555, to request your Pin code.
          </p>
          <p className="text-sm text-gray-800 mt-4">
            Got the Pin code from our office? Sign in via Pin code.
          </p>
        </Tip> */}
      </div>
    </Modal>
  );
};
