import { gql } from "@apollo/client";

export const QUESTIONNAIRE_FRAGMENT = gql`
  fragment QuestionnaireFields on Questionnaire {
    id
    preSubmissionRules {
      id
      conditionalGroup
      thenActions
    }
    postSubmissionRules {
      id
      conditionalGroup
      thenActions
    }
    sections {
      id
      title
      defaultVisible
      forcePrintVisible
      text
      rules {
        id
        conditionalGroup
        thenActions
      }
      questions {
        id
        kind
        prompt
        semanticMapping
        required
        defaultVisible
        forcePrintVisible
        rules {
          id
          conditionalGroup
          thenActions
        }
        config
        answer {
          __typename
          ... on QuestionnaireStringValue {
            _kind
            value
          }
          ... on QuestionnaireNumberValue {
            _kind
            value
          }
          ... on QuestionnaireMedicationListValue {
            _kind
            value {
              name
              dosage
              frequency
            }
          }
          ... on QuestionnaireStringListValue {
            _kind
            value
          }
          ... on QuestionnaireTeethValue {
            _kind
            value
          }
          ... on QuestionnaireAddressValue {
            _kind
            value
          }
          ... on QuestionnaireDateValue {
            _kind
            value
          }
          ... on QuestionnaireEmailValue {
            _kind
            value
          }
          ... on QuestionnairePhoneNumberValue {
            _kind
            value
          }
          ... on QuestionnaireProviderIdValue {
            _kind
            value
          }
          ... on QuestionnaireSignatureValue {
            _kind
            value
            updatedAt
          }
        }
        options
      }
    }
  }
`;
