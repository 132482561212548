import React, { FC } from "react";
import { Link } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { CalendarIcon, LocationMarkerIcon } from "@heroicons/react/solid";
import { ClipboardListIcon } from "@heroicons/react/outline";
import { Spinner } from "@preferral/ui";
import { ScreenTitle } from "context/ScreenTitle";
import { mmDdYyyy } from "lib/dateFormatters";

const NoResults: FC = () => {
  return (
    <div className="bg-gray-200 overflow-hidden rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <div className="px-4 py-8 text-center">
          <div className="bg-gray-200 inline-block leading-none mx-auto p-5 rounded text-center text-gray-600 text-xl">
            <ClipboardListIcon
              className="flex-shrink-0 mr-1.5 h-20 w-20 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <p className="mb-12 mt-8 text-gray-800 text-xl">No Forms</p>
        </div>
      </div>
    </div>
  );
};

const PATIENT_PACKET_SUBMISSIONS = gql`
  query ListPatientPacketSubmissions {
    patientPacketSubmissions {
      id
      title
      completedAt
      insertedAt
      patientFormSubmissionsCount
      completedPatientFormsCount
      patientPacket {
        id
        title
        department {
          id
          name
          organization {
            id
            name
          }
        }
      }
    }
  }
`;

interface Data {
  patientPacketSubmissions: PatientPacketSubmission[];
}

interface PatientPacketSubmission {
  id: string;
  title: string;
  patientFormSubmissionsCount: number;
  completedPatientFormsCount: number;
  completedAt?: string;
  insertedAt: string;
  patientPacket: {
    id: string;
    title: string;
    department: {
      id: string;
      name: string;
      organization: {
        id: string;
        name: string;
      };
    };
  };
}

interface FormsScreenProps { }

export const FormsScreen: FC<FormsScreenProps> = (props) => {
  const { data, loading, error } = useQuery<Data>(PATIENT_PACKET_SUBMISSIONS);

  return (
    <>
      <ScreenTitle title="Forms" />
      <div className="_FormsScreen pt-8">
        {loading ? (
          <div className="p-4 text-center">
            <Spinner />
          </div>
        ) : error || !data?.patientPacketSubmissions ? (
          <p>Failed to load.</p>
        ) : data.patientPacketSubmissions.length === 0 ? (
          <NoResults />
        ) : (
          <div className="bg-white shadow overflow-hidden sm:rounded-md">
            <ul className="divide-y divide-gray-200">
              {data.patientPacketSubmissions.map((packetSubmission) => (
                <li key={packetSubmission.id}>
                  <Link
                    to={`/pp/packets/${packetSubmission.id}`}
                    className="block hover:bg-gray-50"
                  >
                    <div className="px-4 py-4 sm:px-6">
                      <div className="flex items-center justify-between">
                        <div className="flex flex-col md:flex-row">
                          <p className="text-sm font-medium text-indigo-600 truncate">
                            {packetSubmission.title}
                          </p>
                          <p className="italic md:ml-2 text-gray-500 text-xs">
                            ({packetSubmission.completedPatientFormsCount} out of {packetSubmission.patientFormSubmissionsCount} forms complete)
                          </p>
                        </div>
                        <div className="ml-2 flex-shrink-0 flex">
                          <p
                            className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${!!packetSubmission.completedAt
                              ? "bg-green-100 text-green-800"
                              : "bg-blue-100 text-green-800"
                              }`}
                          >
                            {!!packetSubmission.completedAt
                              ? "Completed"
                              : "In progress"}
                          </p>
                        </div>
                      </div>
                      <div className="mt-2 sm:flex sm:justify-between">
                        <div className="sm:flex">
                          <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                            <LocationMarkerIcon
                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                            {packetSubmission.patientPacket.department
                              .organization.name ===
                              packetSubmission.patientPacket.department.name
                              ? packetSubmission.patientPacket.department.name
                              : `${packetSubmission.patientPacket.department.organization.name} - ${packetSubmission.patientPacket.department.name}`}
                          </p>
                        </div>
                        <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                          <CalendarIcon
                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <p>
                            Requested on{" "}
                            <time dateTime={packetSubmission.insertedAt}>
                              {mmDdYyyy(packetSubmission.insertedAt)}
                            </time>
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};
