import getDay from 'date-fns/getDay';
import parseISO from 'date-fns/parseISO';

const shortDayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const longDayNames = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export function ddd(dateOrIsoString: Date | string): string {
  const date = toDate(dateOrIsoString);
  return shortDayNames[getDay(date)];
}

export function dddd(dateOrIsoString: Date | string): string {
  const date = toDate(dateOrIsoString);
  return longDayNames[getDay(date)];
}

function toDate(dateOrIsoString: Date | string): Date {
  return typeof dateOrIsoString === 'string'
    ? parseISO(dateOrIsoString)
    : dateOrIsoString;
}
