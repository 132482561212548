import React, { FC } from "react";
import { useToggle } from "../../hooks/useToggle";

interface JsonDebuggerProps {
  label?: string;
  data: any;
};

export const JsonDebugger: FC<JsonDebuggerProps> = props => {
  const { data, label = "View Debugger Data" } = props;
  const [isOpen, toggleOpen] = useToggle();

  return (
    <div className="_JsonDebugger opacity-50 hover:opacity-100 transition-opacity duration-150 p-2 rounded-md bg-white text-left">
      <button type="button" className="underline text-xs text-purple-500 p-2 bg-transparent hover:bg-purple-100 cursor-pointer" onClick={toggleOpen}>{label}</button>
      {
        isOpen ? (
          <pre className="mt-2 p-2 rounded-md shadow-inner text-xs bg-gray-800 text-green-400 font-mono">
            {JSON.stringify(data, null, 2)}
          </pre>
        ) : null
      }
    </div>
  );
};
