import { FC } from "react";
import { useScreenTitle } from "context/ScreenTitle";
// import { useLayout } from "./Layout";

interface PageContentProps { }

export const PageContent: FC<PageContentProps> = (props) => {
  const { children } = props;
  // const { toggleMenu } = useLayout();
  const { titleString } = useScreenTitle();

  return (
    <div className="_PageContent py-10">
      <header>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold leading-tight text-gray-900">
            {titleString}
          </h1>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          {/* <!-- Replace with your content --> */}
          {children}
          {/* <!-- /End replace --> */}
        </div>
      </main>
    </div>
  );
};
