import React, { FC, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { useDropdown } from '../../hooks/useDropdown';
import { Link } from 'react-router-dom';
import { Manager, Reference, Popper } from 'react-popper';
import { FAIcon } from '../FAIcon/FAIcon';
import { Icon } from '../Icon/Icon';
import { Spinner } from '../Spinner/Spinner';

export type DropdownItemColor = 'blue' | 'red';

const colorClassNames: Record<DropdownItemColor, string> = {
  blue: 'text-gray-800 hover:bg-blue-200 hover:text-blue-800',
  red: 'text-red-800 hover:bg-red-200 hover:text-red-600',
};

/**
 * DropdownButton.
 */

interface DropdownButtonProps {
  label?: ReactNode;
}

export const DropdownButton: FC<DropdownButtonProps> = props => {
  const { label, children } = props;

  const { isOpen, toggle, triggerRef, contentRef } = useDropdown();

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <div ref={ref} className="inline-block">
            <button
              ref={triggerRef}
              onClick={toggle}
              type="button"
              className="h-8 font-medium border border-gray-300 hover:bg-gray-100 ml-2 px-1 rounded text-gray-800 focus:outline-none focus:shadow-outline"
            >
              {!label ? (
                <Icon icon="dots-horizontal" />
              ) : (
                <p className="flex items-center px-1 text-sm">
                  {label}
                  <span className="ml-2 mt-1 text-xs text-gray-700">
                    <FAIcon icon="chevron-down" />
                  </span>
                </p>
              )}
            </button>
          </div>
        )}
      </Reference>
      {isOpen &&
        ReactDOM.createPortal(
          <Popper placement="bottom-end">
            {({ placement, ref, style }) => (
              <div ref={ref} style={style} data-placement={placement}>
                <div
                  className="absolute mt-1 z-10 right-0 origin-top-right"
                  ref={contentRef}
                  hidden={!isOpen}
                >
                  <div className="w-48 text-left bg-white border border-gray-300 rounded-lg px-1 pb-1 shadow-lg">
                    {children}
                  </div>
                </div>
              </div>
            )}
          </Popper>,
          document.querySelector('#dropdown-button-action-portal')!
        )}
    </Manager>
  );
};

/**
 * DropdownItemButton.
 */

interface DropdownItemButtonProps {
  onClick?(): void;
  className?: string;
  color?: DropdownItemColor;
  disabled?: boolean;
  isLoading?: boolean;
}

export const DropdownItemButton: FC<DropdownItemButtonProps> = props => {
  const { className = '', color = 'blue', onClick, children, disabled = false, isLoading = false } = props;

  const cn = [
    "block w-full bg-white text-sm font-semibold text-left cursor-pointer rounded mt-1 px-2 py-1 relative",
    className,
    colorClassNames[color],
    disabled && "opacity-50",
  ].filter(Boolean).join(" ");

  return (
    <button
      type="button"
      onClick={onClick}
      className={cn}
      disabled={disabled}
    >
      {children}
      {
        isLoading ? (
          <div className="absolute transform -translate-y-1/2 pr-2 right-0 top-1/2">
            <Spinner />
          </div>
        ) : null
      }
    </button>
  );
};

/**
 * DropdownItemLink.
 */

interface DropdownItemLinkProps {
  to: string;
  className?: string;
  color?: DropdownItemColor;
}

export const DropdownItemLink: FC<DropdownItemLinkProps> = props => {
  const { className, to, color = 'blue', children } = props;

  return (
    <Link
      to={to}
      className={`block w-full bg-white text-sm font-semibold text-left cursor-pointer rounded mt-1 px-2 py-1 ${className ||
        ''} ${colorClassNames[color]}`}
    >
      {children}
    </Link>
  );
};
