import React, { FC } from "react";
import { ErrorMessage, useField } from 'formik';
import styled from 'styled-components/macro';
import { Rating } from 'react-simple-star-rating'
import { HorizontalField, VerticalField } from "../../FieldStructure/FieldStructure";
import { compose, ValidateProp } from "../validation";

const StyledRating = styled(Rating)`
  svg.star-svg {
    display: inline-block;
  }

  // Only show the tooltip when hovering on the Rating component:
  & + .react-simple-star-rating-tooltip {
    display: none !important;
  }
  &:hover + .react-simple-star-rating-tooltip {
    display: inline-block !important;
  }
`

/**
 * InputStarRating.
 */

export interface InputStarRatingProps {
  value: number;
  onChange(value: number): void;
  showTooltip?: boolean;
  tooltipArray?: Array<string>;
  tooltipDefaultText?: string;
  className?: string;
  inputProps?: Partial<React.ComponentProps<typeof Rating>>;
}

export const InputStarRating: FC<InputStarRatingProps> = props => {
  const { value, onChange, showTooltip, tooltipArray, tooltipDefaultText = "Your Rating", className = '', inputProps = {} } = props;

  return (
    <StyledRating
      transition
      className={className}
      onClick={onChange}
      ratingValue={value}
      showTooltip={showTooltip}
      tooltipArray={tooltipArray}
      tooltipDefaultText={tooltipDefaultText}
      {...inputProps}
    />
  )
}

/**
 * StarRatingInputProps.
 */
export interface StarRatingInputProps extends Omit<InputStarRatingProps, "value" | "onChange"> {
  id?: string;
  name: string;
  validate?: ValidateProp;
}

export const StarRatingInput: FC<StarRatingInputProps> = (props) => {
  const { id, name, validate: validators, ...rest } = props;

  const validate = validators && compose([validators].flat());
  const [field, meta, helpers] = useField({ name, validate });
  const { value } = field;
  const { setValue } = helpers;

  const showError = !!(meta && meta.touched && meta.error);

  const controlClassName = [
    'w-full',
    showError && '-m-2 p-2 border border-red-500 rounded',
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <>
      <div id={id} className={controlClassName}>
        <InputStarRating
          value={value}
          onChange={setValue}
          {...rest}
        />
      </div>
      <ErrorMessage
        component="p"
        name={name}
        className="mt-2 text-red-500 text-xs italic"
      />
    </>
  );
};

/**
 * StarRatingFieldProps.
 */
export interface StarRatingFieldProps extends StarRatingInputProps {
  label: string;
  indicateOptional?: boolean;
  indicateRequired?: boolean;
}
export const StarRatingField: FC<StarRatingFieldProps> = (props) => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;

  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <StarRatingInput {...rest} />
    </VerticalField>
  );
};

export const HorizontalStarRatingField: FC<StarRatingFieldProps> = (props) => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;

  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <StarRatingInput {...rest} />
    </HorizontalField>
  )
}
