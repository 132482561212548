import React, { FC } from "react";
import { ScreenTitle } from "context/ScreenTitle";

interface HomeScreenProps {}

export const HomeScreen: FC<HomeScreenProps> = (props) => {
  return (
    <>
      <ScreenTitle title="Home" />
      <div className="HomeScreen">
        <p>HomeScreen</p>
      </div>
    </>
  );
};
