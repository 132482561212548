import React, { forwardRef, HTMLAttributes } from 'react';
import { Transition } from 'react-transition-group';

interface ZoomInProps extends HTMLAttributes<HTMLDivElement> {
  show?: boolean;
}

const stateClassNames = {
  enter: 'transition ease-out duration-100',
  leave: 'transition ease-in duration-75',
};

const stageClassNames = {
  entering: 'transform opacity-0 scale-95',
  entered: 'transition ease-out duration-100 transform opacity-100 scale-100',
  exiting: 'transform opacity-0 scale-95',
};

export const ZoomIn = forwardRef<HTMLDivElement, ZoomInProps>((props, ref) => {
  const { show, className = '', children, ...rest } = props;

  const stateName = show ? 'enter' : 'leave';
  const stateClassName = stateClassNames[stateName];

  return (
    <Transition appear in={show} timeout={{ enter: 0, exit: 75 }} unmountOnExit nodeRef={ref}>
      {stage => (
        <div
          {...rest}
          ref={ref}
          className={`_ZoomIn _${stage} ${className} ${stateClassName} ${stageClassNames[
            stage
          ] || ''}`}
          {...rest}
        >
          {children}
        </div>
      )}
    </Transition>
  );
});
