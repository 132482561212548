import { FC } from "react";
import { Modal, ModalHeader } from "components/Modal";
import { usernameKind } from "screens/RequestPasswordlessLoginLinkScreen/helpers";

/**
 * Tip.
 */

interface TipProps {
  number: number;
  title: string;
}

const Tip: FC<TipProps> = (props) => {
  const { number, title, children } = props;

  return (
    <div className="_Tip w-full px-0 sm:px-4 py-4 border-t flex">
      <div className="flex-grow-0 p-2">
        <p className="bg-teal-200 text-teal-700 flex font-black h-8 items-center justify-center rounded-full text-xl w-8">
          {number}
        </p>
      </div>
      <div className="flex-grow p-2">
        <h5 className="text-xl font-semibold text-gray-800 leading-snug">
          {title}
        </h5>
        <div className="py-4">{children}</div>
      </div>
    </div>
  );
};

/**
 * TipsModal.
 */

interface TipsModalProps {
  isOpen: boolean;
  onClose(): void;
  sentTo: string;
}

export const TipsModal: FC<TipsModalProps> = (props) => {
  const { isOpen, onClose, sentTo } = props;

  const kind = usernameKind(sentTo);

  return (
    <Modal
      className="_TipsModal max-w-xl"
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <ModalHeader
        icon="question"
        title="Try these tips if you didn't receive a link."
        onClose={onClose}
      />
      <div className="p-4">
        <Tip
          number={1}
          title={`Confirm you're using the correct ${kind === "PHONE_NUMBER" ? "phone number" : "email"
            }.`}
        >
          <p className="text-sm text-gray-800 mt-4">
            Did you put in the wrong{" "}
            {kind === "PHONE_NUMBER" ? "phone number" : "email"}? Just request a
            new link.
          </p>
        </Tip>

        {kind !== "PHONE_NUMBER" ? (
          <Tip number={2} title="Check your Spam/Junk folder.">
            <p className="text-sm text-gray-800">
              Add{" "}
              <span className="font-semibold italic underline">
                yourprovider@preferral.com
              </span>{" "}
              to your contact list to prevent these emails from going to Spam.
            </p>
          </Tip>
        ) : null}

        <Tip
          number={3}
          title={`Still not working?`}
        >
          <p className="text-sm text-gray-800 mt-4">
            Contact our office to request that they send you the form packet link.
          </p>
        </Tip>
      </div>
    </Modal>
  );
};
