import React, { ComponentType, FC } from "react";
import {
  UserCircleIcon,
  CogIcon,
  KeyIcon,
  BellIcon,
  CreditCardIcon,
  ViewGridAddIcon,
} from "@heroicons/react/outline";
import { ScreenTitle } from "context/ScreenTitle";
import { Link, Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { ProfileSettingsScreen } from "./ProfileSettingsScreen";
import { AccountSettingsScreen } from "./AccountSettingsScreen";
import { PasswordSettingsScreen } from "./PasswordSettingsScreen";
import { NotificationsSettingsScreen } from "./NotificationsSettingsScreen";
import { IntegrationsSettingsScreen } from "./IntegrationsSettingsScreen";

type IconName =
  | "userCircle"
  | "cog"
  | "key"
  | "bell"
  | "creditCard"
  | "viewGridAdd";

const IconMap: Record<IconName, ComponentType<{ className?: string }>> = {
  userCircle: UserCircleIcon,
  cog: CogIcon,
  key: KeyIcon,
  bell: BellIcon,
  creditCard: CreditCardIcon,
  viewGridAdd: ViewGridAddIcon,
};

/**
 * NavLink.
 */

interface NavLinkProps {
  to: string;
  label: string;
  icon: IconName;
  className?: string;
}

const NavLink: FC<NavLinkProps> = (props) => {
  const { to, label, icon, className = "" } = props;

  const match = useRouteMatch(to);
  const isActive = !!match;

  const linkClassNames = isActive
    ? "bg-blue-50 border-blue-500 text-blue-700 hover:bg-blue-50 hover:text-blue-700 focus:bg-blue-100 focus:text-blue-700"
    : "border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900 focus:bg-gray-50 focus:text-gray-900";

  const iconClassNames = isActive
    ? "text-blue-500 group-hover:text-blue-500 group-focus:text-blue-600"
    : "text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500";

  const Icon = IconMap[icon];

  return (
    <Link
      to={to}
      className={`${linkClassNames} ${className} group border-l-4 px-3 py-2 flex items-center text-sm leading-5 font-medium focus:outline-none transition ease-in-out duration-150`}
    >
      <Icon
        className={`${iconClassNames} flex-shrink-0 -ml-1 mr-3 h-6 w-6 transition ease-in-out duration-150`}
      />
      <span className="truncate">{label}</span>
    </Link>
  );
};

/**
 * SettingsScreen.
 */

interface SettingsScreenProps {}

export const SettingsScreen: FC<SettingsScreenProps> = (props) => {
  const match = useRouteMatch();
  return (
    <>
      <ScreenTitle title="Settings" />
      <div className="mt-8 bg-white rounded-lg shadow overflow-hidden">
        <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
          <aside className="py-6 lg:col-span-3">
            <nav>
              <NavLink
                to={`${match.url}/profile`}
                label="Profile"
                icon="userCircle"
              />
              <NavLink to={`${match.url}/account`} label="Account" icon="cog" />
              <NavLink
                to={`${match.url}/password`}
                label="Password"
                icon="key"
              />
              <NavLink
                to={`${match.url}/notifications`}
                label="Notifications"
                icon="bell"
              />
              {/* <NavLink
                to={`${match.url}/billing`}
                label="Billing"
                icon="creditCard"
              /> */}
              <NavLink
                to={`${match.url}/integrations`}
                label="Integrations"
                icon="viewGridAdd"
              />
            </nav>
          </aside>

          {/* Her */}
          <Switch>
            <Route exact path={`${match.path}/profile`}>
              <ProfileSettingsScreen />
            </Route>
            <Route exact path={`${match.path}/account`}>
              <AccountSettingsScreen />
            </Route>
            <Route exact path={`${match.path}/password`}>
              <PasswordSettingsScreen />
            </Route>
            <Route exact path={`${match.path}/notifications`}>
              <NotificationsSettingsScreen />
            </Route>
            <Route exact path={`${match.path}/integrations`}>
              <IntegrationsSettingsScreen />
            </Route>

            <Route path="*">
              <Redirect to={`${match.url}/profile`} />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  );
};
