import { FC } from "react";
import { HorizontalTextField, required, Validator, validateEmail } from '@preferral/ui';
import { EmailQuestion } from '../types';
import { useQuestion } from './QuestionnaireFormContext';

interface EmailQuestionFieldProps {
  question: EmailQuestion;
};

export const EmailQuestionField: FC<EmailQuestionFieldProps> = props => {
  const { question } = props;

  const { name, isRequired, isVisible } = useQuestion(question.id);

  if (!isVisible) {
    return null;
  }

  const validate = [
    isRequired ? required : null,
    validateEmail
  ].filter(Boolean) as Validator[]

  return (
    <div className="_EmailQuestionField">
      <HorizontalTextField
        indicateRequired={isRequired}
        label={question.prompt}
        name={name}
        validate={validate}
      />
    </div>
  );
};
