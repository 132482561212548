import { useRef, useCallback, useEffect } from 'react';
import { Handler } from '../reactHelpers';

/** Returns a setTimeout-like function that clears the previous timeout when re-invoked or when the component unmounts. */
export function useTimeout() {
  const handleRef = useRef<number>();

  useEffect(() => () => clearTimeout(handleRef.current), []);

  return useCallback((handler: Handler, timeout?: number) => {
    clearTimeout(handleRef.current);
    handleRef.current = setTimeout(handler, timeout) as any as number;
  }, []);
}
