import React, { forwardRef, HTMLAttributes } from 'react';
import { Transition } from 'react-transition-group';

const stateClassNames = {
  enter: 'transition ease-out duration-300',
  leave: 'transition ease-in duration-100',
};

const stageClassNames = {
  entering: 'opacity-0 scale-75 translate-y-32',
  entered: 'opacity-100 scale-100 translate-x-0',
  exiting: 'opacity-0 scale-75 translate-y-32',
  exited: 'opacity-0 scale-75 translate-y-32',
};

interface FadeUpInProps extends HTMLAttributes<HTMLDivElement> {
  show?: boolean;
}

export const FadeUpIn = forwardRef<HTMLDivElement, FadeUpInProps>(
  (props, ref) => {
    const { show, className = '', children, ...rest } = props;

    const stateName = show ? 'enter' : 'leave';
    const stateClassName = stateClassNames[stateName];

    return (
      <Transition
        appear
        in={show}
        timeout={{ enter: 0, exit: 100 }}
        unmountOnExit
        nodeRef={ref}
      >
        {stage => (
          <div
            ref={ref}
            className={`_FadeUpIn _${stage} ${className} ${stateClassName} ${stageClassNames[
              stage
            ] || ''}`}
            {...rest}
          >
            {children}
          </div>
        )}
      </Transition>
    );
  }
);
