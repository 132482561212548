import { FC } from 'react'
import { HorizontalDateMaskField, required, Validator, validateLocalDate } from '@preferral/ui'
import { DateQuestion } from '../types'
import { useQuestion } from './QuestionnaireFormContext'

interface DateQuestionFieldProps {
  question: DateQuestion
}

export const DateQuestionField: FC<DateQuestionFieldProps> = (props) => {
  const { question } = props

  const { name, isVisible, isRequired } = useQuestion(question.id)

  if (!isVisible) {
    return null
  }

  const validate = [
    isRequired ? required : null,
    validateLocalDate
  ].filter(Boolean) as Validator[]

  return (
    <div className="_DateQuestionField">
      <HorizontalDateMaskField
        indicateRequired={isRequired}
        label={question.prompt}
        name={name}
        validate={validate}
        prepopulateToday={question.config?.prepopulateToday || false}
      />
    </div>
  )
}
