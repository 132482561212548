export function formatPhoneNumber(str: string): string {
  // Filter only numbers from the input
  const cleaned = ('' + str).replace(/\D/g, '');

  // Check if the input is of correct length
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return `${intlCode}(${match[2]}) ${match[3]}-${match[4]}`;
  }

  return str;
}
