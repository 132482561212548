import { useState, useCallback } from 'react';
import { Handler } from '../reactHelpers';

/** Simple toggleable state. */
export function useToggle(initialValue = false): [boolean, Handler] {
  const [value, setValue] = useState(initialValue);
  const toggle = useCallback(() => setValue((currentValue) => !currentValue), [
    setValue,
  ]);

  return [value, toggle];
}
