import { FC } from "react";

interface ProfileSettingsScreenProps { }

export const ProfileSettingsScreen: FC<ProfileSettingsScreenProps> = (
  props
) => {
  return null;
  // return (
  //   <form
  //     className="divide-y divide-gray-200 lg:col-span-9"
  //     action="#"
  //     method="POST"
  //   >
  //     {/* <!-- Profile section --> */}
  //     <div className="py-6 px-4 space-y-6 sm:p-6 lg:pb-8">
  //       <div>
  //         <h2 className="text-lg leading-6 font-medium text-gray-900">
  //           Profile
  //         </h2>
  //         <p className="mt-1 text-sm leading-5 text-gray-500">
  //           This information will be displayed publicly so be careful what you
  //           share.
  //         </p>
  //       </div>

  //       <div className="flex flex-col space-y-6 lg:flex-row lg:space-y-0 lg:space-x-6">
  //         <div className="flex-grow space-y-6">
  //           <div className="space-y-1">
  //             <label
  //               htmlFor="username"
  //               className="block text-sm font-medium leading-5 text-gray-700"
  //             >
  //               Username
  //             </label>
  //             <div className="rounded-md shadow-sm flex">
  //               <span className="bg-gray-50 border border-r-0 border-gray-300 rounded-l-md px-3 inline-flex items-center text-gray-500 sm:text-sm">
  //                 workcation.com/
  //               </span>
  //               <input
  //                 id="username"
  //                 className="form-input flex-grow block w-full min-w-0 rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
  //                 value="lisamarie"
  //               />
  //             </div>
  //           </div>

  //           <div className="space-y-1">
  //             <label
  //               htmlFor="about"
  //               className="block text-sm leading-5 font-medium text-gray-700"
  //             >
  //               About
  //             </label>
  //             <div className="rounded-md shadow-sm">
  //               <textarea
  //                 id="about"
  //                 rows={3}
  //                 className="form-textarea mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
  //               ></textarea>
  //             </div>
  //             <p className="mt-2 text-sm text-gray-500">
  //               Brief description for your profile. URLs are hyperlinked.
  //             </p>
  //           </div>
  //         </div>

  //         <div className="flex-grow space-y-1 lg:flex-grow-0 lg:flex-shrink-0">
  //           <p
  //             className="block text-sm leading-5 font-medium text-gray-700"
  //             aria-hidden="true"
  //           >
  //             Photo
  //           </p>
  //           <div className="lg:hidden">
  //             <div className="flex items-center">
  //               <div
  //                 className="flex-shrink-0 inline-block rounded-full overflow-hidden h-12 w-12"
  //                 aria-hidden="true"
  //               >
  //                 <img
  //                   className="rounded-full h-full w-full"
  //                   src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixqx=z5nYHwE2vf&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=80h"
  //                   alt=""
  //                 />
  //               </div>
  //               <div className="ml-5 rounded-md shadow-sm">
  //                 <div className="group relative border border-gray-300 rounded-md py-2 px-3 flex items-center justify-center focus-within:border-blue-300 focus-within:shadow-outline-blue focus-within:text-gray-900 transition duration-150 ease-in-out">
  //                   <label
  //                     htmlFor="user_photo"
  //                     className="relative text-sm leading-4 font-medium text-gray-700 group-hover:text-gray-500 transition duration-150 ease-in-out pointer-events-none"
  //                   >
  //                     <span>Change</span>
  //                     <span className="sr-only"> user photo</span>
  //                   </label>
  //                   <input
  //                     id="user_photo"
  //                     type="file"
  //                     className="absolute w-full h-full opacity-0 cursor-pointer"
  //                   />
  //                 </div>
  //               </div>
  //             </div>
  //           </div>

  //           <div className="hidden relative rounded-full overflow-hidden lg:block transition duration-150 ease-in-out">
  //             <img
  //               className="relative rounded-full w-40 h-40"
  //               src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixqx=z5nYHwE2vf&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=320&h=320&q=80"
  //               alt=""
  //             />
  //             <label className="absolute inset-0 w-full h-full bg-black bg-opacity-75 flex items-center justify-center text-sm leading-5 font-medium text-white opacity-0 hover:opacity-100 focus-within:opacity-100 transition duration-150 ease-in-out">
  //               <span>Change</span>
  //               <span className="sr-only"> user photo</span>
  //               <input
  //                 type="file"
  //                 className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
  //               />
  //             </label>
  //           </div>
  //         </div>
  //       </div>

  //       <div className="grid grid-cols-12 gap-6">
  //         <div className="col-span-12 sm:col-span-6">
  //           <label
  //             htmlFor="first_name"
  //             className="block text-sm font-medium leading-5 text-gray-700"
  //           >
  //             First name
  //           </label>
  //           <input
  //             id="first_name"
  //             className="form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
  //           />
  //         </div>

  //         <div className="col-span-12 sm:col-span-6">
  //           <label
  //             htmlFor="last_name"
  //             className="block text-sm font-medium leading-5 text-gray-700"
  //           >
  //             Last name
  //           </label>
  //           <input
  //             id="last_name"
  //             className="form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
  //           />
  //         </div>

  //         <div className="col-span-12">
  //           <label
  //             htmlFor="url"
  //             className="block text-sm font-medium leading-5 text-gray-700"
  //           >
  //             URL
  //           </label>
  //           <input
  //             id="url"
  //             className="form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
  //           />
  //         </div>

  //         <div className="col-span-12 sm:col-span-6">
  //           <label
  //             htmlFor="company"
  //             className="block text-sm font-medium leading-5 text-gray-700"
  //           >
  //             Company
  //           </label>
  //           <input
  //             id="company"
  //             className="form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
  //           />
  //         </div>
  //       </div>
  //     </div>

  //     {/* <!-- Privacy section --> */}
  //     <div className="pt-6 space-y-4 divide-y divide-gray-200">
  //       <div className="px-4 space-y-2 sm:px-6">
  //         <div>
  //           <h2 className="text-lg leading-6 font-medium text-gray-900">
  //             Privacy
  //           </h2>
  //           <p className="mt-1 text-sm leading-5 text-gray-500">
  //             Ornare eu a volutpat eget vulputate. Fringilla commodo amet.
  //           </p>
  //         </div>
  //         <ul className="divide-y divide-gray-200">
  //           <li className="py-4 flex items-center justify-between space-x-4">
  //             <div className="flex flex-col">
  //               <p
  //                 id="privacy-option-label-1"
  //                 className="text-sm leading-5 font-medium text-gray-900"
  //               >
  //                 Available to hire
  //               </p>
  //               <p
  //                 id="privacy-option-description-1"
  //                 className="text-sm leading-5 text-gray-500"
  //               >
  //                 Nulla amet tempus sit accumsan. Aliquet turpis sed sit
  //                 lacinia.
  //               </p>
  //             </div>
  //             {/* <!-- On: "bg-blue-500", Off: "bg-gray-200" --> */}
  //             <span
  //               role="checkbox"
  //               tabIndex={0}
  //               aria-checked="true"
  //               aria-labelledby="privacy-option-label-1"
  //               aria-describedby="privacy-option-description-1"
  //               className="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline"
  //             >
  //               {/* <!-- On: "translate-x-5", Off: "translate-x-0" --> */}
  //               <span
  //                 aria-hidden="true"
  //                 className="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200"
  //               ></span>
  //             </span>
  //           </li>
  //           <li className="py-4 flex items-center justify-between space-x-4">
  //             <div className="flex flex-col">
  //               <p
  //                 id="privacy-option-label-2"
  //                 className="text-sm leading-5 font-medium text-gray-900"
  //               >
  //                 Make account private
  //               </p>
  //               <p
  //                 id="privacy-option-description-2"
  //                 className="text-sm leading-5 text-gray-500"
  //               >
  //                 Pharetra morbi dui mi mattis tellus sollicitudin cursus
  //                 pharetra.
  //               </p>
  //             </div>
  //             {/* <!-- On: "bg-blue-500", Off: "bg-gray-200" --> */}
  //             <span
  //               role="checkbox"
  //               tabIndex={0}
  //               aria-checked="false"
  //               aria-labelledby="privacy-option-label-2"
  //               aria-describedby="privacy-option-description-2"
  //               className="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline"
  //             >
  //               {/* <!-- On: "translate-x-5", Off: "translate-x-0" --> */}
  //               <span
  //                 aria-hidden="true"
  //                 className="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200"
  //               ></span>
  //             </span>
  //           </li>
  //           <li className="py-4 flex items-center justify-between space-x-4">
  //             <div className="flex flex-col">
  //               <p
  //                 id="privacy-option-label-3"
  //                 className="text-sm leading-5 font-medium text-gray-900"
  //               >
  //                 Allow commenting
  //               </p>
  //               <p
  //                 id="privacy-option-description-3"
  //                 className="text-sm leading-5 text-gray-500"
  //               >
  //                 Integer amet, nunc hendrerit adipiscing nam. Elementum ame
  //               </p>
  //             </div>
  //             {/* <!-- On: "bg-blue-500", Off: "bg-gray-200" --> */}
  //             <span
  //               role="checkbox"
  //               tabIndex={0}
  //               aria-checked="true"
  //               aria-labelledby="privacy-option-label-3"
  //               aria-describedby="privacy-option-description-3"
  //               className="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline"
  //             >
  //               {/* <!-- On: "translate-x-5", Off: "translate-x-0" --> */}
  //               <span
  //                 aria-hidden="true"
  //                 className="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200"
  //               ></span>
  //             </span>
  //           </li>
  //           <li className="py-4 flex items-center justify-between space-x-4">
  //             <div className="flex flex-col">
  //               <p
  //                 id="privacy-option-label-4"
  //                 className="text-sm leading-5 font-medium text-gray-900"
  //               >
  //                 Allow mentions
  //               </p>
  //               <p
  //                 id="privacy-option-description-4"
  //                 className="text-sm leading-5 text-gray-500"
  //               >
  //                 Adipiscing est venenatis enim molestie commodo eu gravid
  //               </p>
  //             </div>
  //             {/* <!-- On: "bg-blue-500", Off: "bg-gray-200" --> */}
  //             <span
  //               role="checkbox"
  //               tabIndex={0}
  //               aria-checked="true"
  //               aria-labelledby="privacy-option-label-4"
  //               aria-describedby="privacy-option-description-4"
  //               className="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline"
  //             >
  //               {/* <!-- On: "translate-x-5", Off: "translate-x-0" --> */}
  //               <span
  //                 aria-hidden="true"
  //                 className="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200"
  //               ></span>
  //             </span>
  //           </li>
  //         </ul>
  //       </div>
  //       <div className="py-4 px-4 flex justify-end space-x-5 sm:px-6">
  //         <div className="inline-flex rounded-md shadow-sm">
  //           <button
  //             type="button"
  //             className="bg-white border border-gray-300 rounded-md py-2 px-4 inline-flex justify-center text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-light-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
  //           >
  //             Cancel
  //           </button>
  //         </div>
  //         <div className="inline-flex rounded-md shadow-sm">
  //           <button
  //             type="submit"
  //             className="bg-light-blue-700 border border-transparent rounded-md py-2 px-4 inline-flex justify-center text-sm leading-5 font-medium text-white hover:bg-light-blue-600 focus:outline-none focus:border-light-blue-800 focus:shadow-outline-blue active:bg-light-blue-800 transition duration-150 ease-in-out"
  //           >
  //             Save
  //           </button>
  //         </div>
  //       </div>
  //     </div>
  //   </form>
  // );
};
