import { FC, useCallback, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  QuestionnaireModel,
  QUESTIONNAIRE_FRAGMENT,
  Response,
  questionnaireForServer,
  QuestionnaireFields,
  QuestionnaireFieldset,
  templateToFields,
  mergeAnswers,
} from "components/Questionnaire";
import {
  Spinner,
  Button,
  useInterval,
  useToggle,
  ToggleSwitch,
  useIsMounted,
} from "@preferral/ui";
import { FormikProvider, useFormik } from "formik";
import toast from "react-hot-toast";
import { ScreenTitle } from "context/ScreenTitle";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { FormSubmittedModal } from "./FormSubmittedModal";

const PATIENT_FORM_SUBMISSION = gql`
  query FetchPatientFormSubmission($id: UUID4!) {
    patientFormSubmission(id: $id) {
      id
      title
      submittedAt
      patientForm {
        id
        title
        kind
        logoUrl
      }
      form {
        ...QuestionnaireFields
      }
      patientPacketSubmission {
        id
        title
        patientFormSubmissionsCount
        completedPatientFormsCount
        nextForm {
          id
          title
        }
      }
    }
  }
  ${QUESTIONNAIRE_FRAGMENT}
`;

interface Data {
  patientFormSubmission: PatientFormSubmissionModel;
}

export interface PatientFormSubmissionModel {
  id: string;
  title: string;
  submittedAt: string;
  patientForm: {
    id: string;
    title: string;
    kind: string;
    logoUrl: string;
  };
  patientPacketSubmission: {
    id: string;
    title: string;
    patientFormSubmissionsCount: number;
    completedPatientFormsCount: number;
    nextForm: {
      id: string;
      title: string;
    };
  };
  form: QuestionnaireModel;
}

interface Variables {
  id: string;
}

const SUBMIT_PATIENT_FORM = gql`
  mutation SubmitPatientPacketForm(
    $id: UUID4!
    $input: PatientFormSubmissionInput!
  ) {
    submitPatientPacketForm(id: $id, input: $input) {
      errors {
        key
        message
      }
      patientFormSubmission {
        id
        title
        submittedAt
        patientForm {
          id
          title
          logoUrl
        }
        form {
          ...QuestionnaireFields
        }
        patientPacketSubmission {
          id
          title
          patientFormSubmissionsCount
          completedPatientFormsCount
          nextForm {
            id
            title
          }
        }
      }
    }
  }
  ${QUESTIONNAIRE_FRAGMENT}
`;

interface SubmitMutationData {
  submitPatientPacketForm: {
    errors?: InputError[];
    patientFormSubmission?: PatientFormSubmissionModel;
  };
}

interface MutationVariables {
  id: string;
  input: {
    form: QuestionnaireModel;
  };
}

const AUTOSAVE_PATIENT_FORM = gql`
  mutation AutosavePatientPacketFormSubmission(
    $id: UUID4!
    $input: PatientFormSubmissionInput!
  ) {
    autosavePatientPacketFormSubmission(id: $id, input: $input) {
      errors {
        key
        message
      }
      patientFormSubmission {
        id
      }
    }
  }
`;

interface AutosaveMutationData {
  autosavePatientPacketFormSubmission: {
    errors?: InputError[];
    patientFormSubmission?: Pick<PatientFormSubmissionModel, "id">;
  };
}

/**
 * Form.
 */

// interface SavePatientFormSubmissionResult {
//   errors?: InputError[];
//   patientFormSubmission?: PatientFormSubmissionModel;
// }

interface FormValues {
  form: QuestionnaireFieldset;
}
interface FormProps {
  patientFormSubmission: PatientFormSubmissionModel;
  // save(values: FormValues): Promise<SavePatientFormSubmissionResult>;
  onSuccess(patientFormSubmission: PatientFormSubmissionModel): void;
}

const Form: FC<FormProps> = props => {
  const { patientFormSubmission, onSuccess } = props;
  const { patientForm } = patientFormSubmission;
  const [autosaveEnabled, toggleAutosaveEnabled] = useToggle(true);

  const initialValues = {
    form: templateToFields(patientFormSubmission.form),
  };

  const [submitPatientForm] = useMutation<
    SubmitMutationData,
    MutationVariables
  >(SUBMIT_PATIENT_FORM);
  const [savePatientForm] = useMutation<
    AutosaveMutationData,
    MutationVariables
  >(AUTOSAVE_PATIENT_FORM);

  const onSubmit = useCallback(
    async (values, formikHelpers) => {
      const { setStatus, setSubmitting } = formikHelpers;

      const questionnaire = questionnaireForServer(
        mergeAnswers(patientFormSubmission.form, values.form)
      );
      const input = {
        ...values,
        form: questionnaire,
      };

      setStatus({ errors: null });

      try {
        const { data } = await submitPatientForm({
          variables: {
            id: patientFormSubmission.id,
            input,
          },
        });

        if (data?.submitPatientPacketForm.errors) {
          setStatus({ errors: data.submitPatientPacketForm.errors });
        } else if (data?.submitPatientPacketForm.patientFormSubmission) {
          // it worked...
          toast.success("Form submitted!");
          onSuccess(data.submitPatientPacketForm.patientFormSubmission);
        }
        setSubmitting(false);
      } catch (e) {
        toast.error("Something went wrong.");
        console.error(e);
        setSubmitting(false);
      }
    },
    [patientFormSubmission, submitPatientForm, onSuccess]
  );

  const formikBag = useFormik<FormValues>({
    initialValues,
    onSubmit,
  });

  const { handleSubmit, status, isSubmitting, errors, values } = formikBag;
  const hasErrors = !!Object.keys(errors).length;

  const autosave = useCallback(async () => {
    const questionnaire = questionnaireForServer(
      mergeAnswers(patientFormSubmission.form, values.form)
    );

    const input = {
      ...values,
      form: questionnaire,
    };

    const variables = {
      id: patientFormSubmission.id,
      input,
    };

    try {
      const { data } = await savePatientForm({ variables });
      if (data?.autosavePatientPacketFormSubmission.patientFormSubmission) {
        // it worked.
        // toast.success("Form progress autosaved");
      }
    } catch (e) {
      // it didn't work.
      console.error(e);
    }
  }, [
    savePatientForm,
    patientFormSubmission.id,
    patientFormSubmission.form,
    values,
  ]);

  const isMounted = useIsMounted();

  useInterval(() => {
    if (isMounted && autosaveEnabled && !isSubmitting && !hasErrors) {
      autosave();
    }
  }, 10_000);

  return (
    <>
      <div className="flex items-center justify-end">
        <label
          htmlFor="toggle-autosave"
          className="cursor-pointer mr-2 text-gray-500 text-xs"
        >
          Autosave Progress
        </label>
        <ToggleSwitch
          id="toggle-autosave"
          checked={autosaveEnabled}
          onChange={toggleAutosaveEnabled}
          size={20}
        />
      </div>
      <FormikProvider value={formikBag}>
        <form onSubmit={handleSubmit}>
          <FormStatusErrors status={status} />

          <QuestionnaireFields
            name="form"
            template={patientFormSubmission.form}
          />

          <div
            className={
              patientForm.kind === "notice"
                ? "bg-white border-gray-200 border-t bottom-0 fixed left-0 mx-auto p-6 right-0 shadow-2xl w-full"
                : "max-w-3xl mx-auto mt-8 border-t border-gray-200 pt-5"
            }
          >
            <div className="container mx-auto">
              <div className="flex justify-end">
                <span className="ml-3 inline-flex rounded-md shadow-sm">
                  <Button
                    type="submit"
                    color="blue"
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    {patientFormSubmission.patientForm.kind === "notice"
                      ? "Acknowledge Notice"
                      : "Submit Form"}
                  </Button>
                </span>
              </div>
            </div>
          </div>
        </form>
      </FormikProvider>
    </>
  );
};

/**
 * PatientPacketFormScreen.
 */

interface PatientPacketFormScreenProps { }

interface RouteParams {
  patientPacketSubmissionId: string;
  patientFormSubmissionId: string;
}

type ActiveModal = {
  type: "FORM_SUBMITTED";
  patientFormSubmission: PatientFormSubmissionModel;
};

export const PatientPacketFormScreen: FC<PatientPacketFormScreenProps> = props => {
  const match = useRouteMatch<RouteParams>();
  const { patientFormSubmissionId } = match.params;

  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);
  const closeModal = useCallback(() => setActiveModal(null), []);

  const { data, loading, error } = useQuery<Data, Variables>(
    PATIENT_FORM_SUBMISSION,
    {
      variables: { id: patientFormSubmissionId },
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
    }
  );

  const onSuccess = useCallback(
    patientFormSubmission => {
      setActiveModal({ type: "FORM_SUBMITTED", patientFormSubmission });
    },
    [setActiveModal]
  );

  return (
    <>
      <ScreenTitle title={""} />
      {activeModal?.patientFormSubmission ? (
        <FormSubmittedModal
          isOpen={activeModal?.type === "FORM_SUBMITTED"}
          onClose={closeModal}
          patientFormSubmission={activeModal?.patientFormSubmission}
        />
      ) : null}
      <div
        className="_PatientPacketFormScreen pt-4"
        style={
          data?.patientFormSubmission.patientForm.kind === "notice"
            ? { paddingBottom: "4rem" }
            : {}
        }
      >
        {loading ? (
          <div className="p-6 text-center">
            <Spinner />
          </div>
        ) : error || !data?.patientFormSubmission ? (
          <p>Failed to load.</p>
        ) : (
          <div className="rounded-xl border shadow-xl p-4 lg:p-8 bg-white">
            {data?.patientFormSubmission.patientForm.logoUrl ? (
              <div className="pt-4 pb-8">
                <img
                  alt="logo"
                  className="mx-auto"
                  src={data.patientFormSubmission.patientForm.logoUrl}
                  style={{
                    maxWidth: 240,
                    maxHeight: 90,
                    width: "auto",
                    height: "auto",
                  }}
                />
              </div>
            ) : null}

            {loading ? (
              <div className="p-12 text-center">
                <Spinner />
              </div>
            ) : error || !data?.patientFormSubmission ? (
              <p>Failed to load</p>
            ) : (
              <>
                <h2 className="mt-4 mb-8 text-2xl lg:text-3xl font-bold text-gray-700">
                  {data.patientFormSubmission.patientForm.title}
                </h2>

                {!!data.patientFormSubmission.submittedAt ? (
                  <Response questionnaire={data.patientFormSubmission.form} />
                ) : (
                  <Form
                    patientFormSubmission={data.patientFormSubmission}
                    onSuccess={onSuccess}
                  />
                )}
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};
