import React, { FC, createContext, useContext } from 'react';
import { getQuestion, questionRuleState } from '../helpers';
import { QuestionnaireModel, SomeAnswer } from '../types';

interface State {
  questionnaire: QuestionnaireModel
}

const questionnaireResponseContext = createContext<State>(null!);

interface QuestionnaireResponseProviderProps {
  questionnaire: QuestionnaireModel;
}

export const QuestionnaireResponseProvider: FC<QuestionnaireResponseProviderProps> = props => {
  const { questionnaire, children } = props;

  return (
    <questionnaireResponseContext.Provider value={{ questionnaire }}>
      {children}
    </questionnaireResponseContext.Provider>
  )
}

function useQuestionnaireResponse() {
  const context = useContext(questionnaireResponseContext);
  if (context === undefined) {
    throw new Error(
      `useQuestionnaireResponse must be used within a QuestionnaireResponseProvider`
    )
  }
  return context
}

type Answer = SomeAnswer["value"] | null

export function useQuestion(questionId: string) {
  const { questionnaire } = useQuestionnaireResponse()
  const question = getQuestion(questionId, questionnaire);

  function getAnswerValue(questionId: string): Answer {
    const targetQuestion = getQuestion(questionId, questionnaire);
    return targetQuestion?.answer?.value;
  }

  const { isVisible } = questionRuleState(question!, {
    getAnswerValue
  });

  return { isVisible };
}
