import React from 'react';
import { FormikProvider, FormikValues, useFormik } from 'formik';
import { shallowEqual } from '@preferral/common';
import { FAIcon } from '../FAIcon/FAIcon';

interface FilterFormProps<T = never> {
  defaultValue: T;
  value: T;
  onChange(newValue: T): void;
  children: React.ReactNode | React.ReactNodeArray;
  showClearFilter?: boolean;
  validationSchema?: object;
}

export function FilterForm<T extends FormikValues>(props: FilterFormProps<T>) {
  const {
    defaultValue,
    value,
    onChange,
    children,
    showClearFilter = true,
    validationSchema,
  } = props;

  const formikBag = useFormik<T>({
    initialValues: value,
    onSubmit: onChange,
    validationSchema: validationSchema,
  });

  const { handleSubmit, resetForm, submitForm } = formikBag;

  const isResettable = !shallowEqual(defaultValue, value);

  const resetFilter = React.useCallback(() => {
    resetForm({ values: defaultValue });
    submitForm();
  }, [resetForm, submitForm, defaultValue]);

  return (
    <div className="_FilterForm text-left">
      <FormikProvider value={formikBag}>
        <form onSubmit={handleSubmit}>{children}</form>
        {isResettable && showClearFilter ? (
          <div className="p-1">
            <button
              type="button"
              className="text-sm text-red-500 border-b border-red-400 border-dashed"
              onClick={resetFilter}
            >
              <span className="mr-2">
                <FAIcon icon="times" />
              </span>
              Clear filter
            </button>
          </div>
        ) : null}
      </FormikProvider>
    </div>
  );
}
