interface CompareFn<T> {
  (a: T, b: T): number;
}

/**
 *
 * @param initArray List of objects to sort
 * @param attr Object attribute to sort by
 * @returns
 */
export function sortBy<T extends unknown>(
  initArray: T[],
  attrOrCompareFn: keyof T | CompareFn<T>
): T[] {
  // Clone so we can mutate in place
  const array = [...initArray];
  const compareFn =
    typeof attrOrCompareFn === 'string'
      ? (a: T, b: T) => (a[attrOrCompareFn] <= b[attrOrCompareFn] ? -1 : 1)
      : (attrOrCompareFn as CompareFn<T>);
  return array.sort(compareFn);
}
