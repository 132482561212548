const emailRegex = /^.+@.+\..+$/;
const phoneRegex = /^\d{10}$/;
// \d{10}$/

type UsernameKind = "EMAIL" | "PHONE_NUMBER" | "UNKNOWN";

export function usernameKind(rawUsername: string): UsernameKind {
  const username = rawUsername.trim();
  if (username.match(emailRegex)) {
    return "EMAIL";
  }

  const phoneNumber = username.replace(/[^0-9]+/g, "");
  if (phoneNumber.match(phoneRegex)) {
    return "PHONE_NUMBER";
  }

  return "UNKNOWN";
}
