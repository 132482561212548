import { useRef } from 'react';

let uniqueId = 0;
const getUniqueId = () => uniqueId++;

export function useComponentId(prefix?: string) {
  const idRef = useRef<string>();
  if (idRef.current === undefined) {
    idRef.current = (prefix || '') + getUniqueId();
  }
  return idRef.current;
}
