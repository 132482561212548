import React, { FC } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FAIcon } from '../FAIcon/FAIcon';
import { AppColor } from 'styles/colorStyles';

function colorClass(color: AppColor): string {
  switch (color) {
    case 'blue':
      return 'bg-blue-200 text-blue-700';
    case 'red':
      return 'bg-red-200 text-red-700';
    case 'orange':
      return 'bg-orange-200 text-orange-700';
    case 'yellow':
      return 'bg-yellow-200 text-yellow-700';
    case 'green':
      return 'bg-green-200 text-green-700';
    case 'purple':
      return 'bg-purple-200 text-purple-700';
    case 'indigo':
      return 'bg-indigo-200 text-indigo-700';
    case 'gray':
      return 'bg-gray-200 text-gray-700';
    case 'pink':
      return 'bg-pink-200 text-pink-700';
    case 'teal':
      return 'bg-teal-200 text-teal-700';
  }
}

interface CircleIconProps {
  icon: IconProp;
  size?: number;
  iconSize?: number;
  color?: AppColor;
  className?: string;
};

export const CircleIcon: FC<CircleIconProps> = props => {
  const { icon, size, iconSize, color = 'blue', className } = props;

  const cn = [
    "_CircleIcon h-10 w-10 flex justify-center items-center rounded-full",
    colorClass(color),
    className
  ].filter(Boolean).join(" ");

  return (
    <div
      className={cn}
      style={!!size ? { width: size, height: size } : {}}
    >
      {!!iconSize ? (
        <span style={{ fontSize: iconSize }}>
          <FAIcon icon={icon} />
        </span>
      ) : (
        <FAIcon icon={icon} />
      )}
    </div>
  );
};
