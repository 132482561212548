import React, { ReactNode, useCallback, useEffect } from 'react';
import Select, {
  createFilter,
  ValueType,
  Props as ReactSelectProps,
  Styles as ReactSelectStyles,
} from 'react-select';
import { ErrorMessage, useField } from 'formik';
import {
  VerticalField,
  HorizontalField,
} from '../../FieldStructure/FieldStructure';
import { compose, ValidateProp } from '../validation';

export interface StandardOption<ValueType = string> {
  value: ValueType;
  label: string;
}

export function defaultGetOptionLabel(option: StandardOption): string {
  return option.label;
}

export function defaultGetOptionValue(option: StandardOption): string {
  return option?.value || "";
}

const filterFromStart = createFilter({ matchFrom: 'start' });
const filterFromAny = createFilter({ matchFrom: 'any' });

const defaultCustomStyles: any = {
  control: (base: any) => ({
    ...base,
    "*": {
      boxShadow: "none !important",
    },
  }),
};

/**
 * InputSelect.
 */

export interface InputSelectProps<T = StandardOption, IsMulti extends boolean = false>
  extends Omit<ReactSelectProps<T, IsMulti>, 'value' | 'onChange'> {
  value: string | string[] | null;
  options: T[];
  onChange(value: IsMulti extends true ? readonly string[] : string | null): void;
  autoSelect?: boolean;
  matchFrom?: 'start' | 'any';
  isMulti?: IsMulti;
}

export function InputSelect<T = StandardOption, IsMulti extends boolean = false>(props: InputSelectProps<T, IsMulti>) {
  const {
    value,
    options,
    onChange,
    getOptionLabel = (defaultGetOptionLabel as unknown) as ReactSelectProps<
      T,
      IsMulti
    >["getOptionLabel"],
    getOptionValue = (defaultGetOptionValue as unknown) as ReactSelectProps<
      T,
      IsMulti
    >["getOptionValue"],
    matchFrom = 'any',
    autoSelect = false,
    isMulti = false,
    styles = defaultCustomStyles,
    ...rest
  } = props;

  const selected = (isMulti
    ? (options || []).filter((o) => (value || []).includes(getOptionValue!(o)))
    : options.find((o) => getOptionValue!(o) === value) || null) as ValueType<
      T,
      IsMulti
    >;

  const handleChange = useCallback(
    (option: ValueType<T, IsMulti>) => {
      if (isMulti) {
        return onChange(
          ((option || []) as T[]).map((o) => getOptionValue!(o)) as any
        );
      } else {
        return onChange(option ? getOptionValue(option) : null);
      }
    },
    [isMulti, onChange, getOptionValue]
  );

  useEffect(() => {
    if (autoSelect && options?.length === 1) {
      if (isMulti && ((selected as any) as string[])?.length === 0) {
        onChange([getOptionValue(options[0])] as any);
      } else if (!selected) {
        onChange(getOptionValue(options[0]));
      }
    }
  }, [isMulti, autoSelect, selected, options, onChange, getOptionValue]);

  /**
   * If options change, and the `value` of the field is no longer
   * present in the `options`, unset the value.
   */
  useEffect(() => {
    if (isMulti) {
      if (
        value &&
        value.length > 0 &&
        (!selected || (selected as any).length === 0)
      ) {
        onChange([] as any);
      }
    } else {
      if (value && !selected) {
        onChange(null as any);
      }
    }
  }, [isMulti, value, selected, onChange]);

  return (
    <Select<T, IsMulti>
      {...rest}
      options={options}
      value={selected}
      onChange={handleChange}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      filterOption={matchFrom === 'start' ? filterFromStart : filterFromAny}
      isMulti={isMulti as IsMulti}
      styles={styles}
    />
  );
}

/**
 * SelectInput.
 */

export interface SelectInputProps<T = StandardOption, IsMulti extends boolean = false> {
  id?: string;
  name: string;
  isLoading?: boolean;
  isClearable?: boolean;
  placeholder?: ReactNode;
  options: T[];
  getOptionLabel?: ReactSelectProps<T, IsMulti>["getOptionLabel"]; // (option: ValueType<T, IsMulti>): string;
  getOptionValue?: ReactSelectProps<T, IsMulti>["getOptionValue"]; // (option: ValueType<T, IsMulti>): string;
  components?: any;
  matchFrom?: 'start' | 'any';
  isMulti?: boolean;
  disabled?: boolean;
  autoSelect?: boolean;
  validate?: ValidateProp;
}

export function SelectInput<T = StandardOption, IsMulti extends boolean = false>(props: SelectInputProps<T, IsMulti>) {
  const {
    id,
    name,
    isLoading,
    isClearable,
    placeholder,
    options,
    getOptionLabel = (defaultGetOptionLabel as unknown) as ReactSelectProps<
      T,
      IsMulti
    >["getOptionLabel"],
    getOptionValue = (defaultGetOptionValue as unknown) as ReactSelectProps<
      T,
      IsMulti
    >["getOptionValue"],
    components,
    matchFrom = 'any',
    isMulti = false,
    disabled = false,
    autoSelect = false,
    validate: validateProp,
  } = props;

  const validate = validateProp && compose([validateProp].flat());
  const [field, meta, helpers] = useField({ name, validate });
  const { value } = field;
  const { setValue, setTouched, setError } = helpers;

  const handleChange = (option: ValueType<T, IsMulti>) => {
    setTouched(true);
    setError(undefined);
    if (isMulti) {
      return setValue(((option || []) as T[]).map((o) => getOptionValue!(o)));
    } else {
      return setValue(option ? getOptionValue!(option as T) : null);
    }
  };

  const handleBlur = () => setTouched(true);
  const selected = (isMulti
    ? (options || []).filter((o) => (value || []).includes(getOptionValue!(o)))
    : options.find((o) => getOptionValue!(o) === value) || null) as ValueType<
      T,
      IsMulti
    >;

  useEffect(() => {
    if (autoSelect && options?.length === 1) {
      if (isMulti && ((selected as any) as string[])?.length === 0) {
        setValue([getOptionValue!(options[0])] as any);
      } else if (!selected) {
        setValue(getOptionValue!(options[0]));
      }
    }
  }, [isMulti, autoSelect, selected, options, setValue, getOptionValue]);

  /**
   * If options change, and the `value` of the field is no longer
   * present in the `options`, unset the value.
   */
  useEffect(() => {
    if (value && !isLoading && !selected) {
      setValue(null);
    }
  }, [value, selected, setValue, isLoading]);

  const controlStyleOverrides = {
    "*": {
      boxShadow: "none !important",
    },
  };

  const showError = meta && meta.touched && meta.error;
  const customStyles: ReactSelectStyles<T, IsMulti> = {
    control: (provided) =>
      showError
        ? {
          ...provided,
          ...controlStyleOverrides,
          border: '1px solid rgb(240, 82, 82)',
        }
        : { ...provided, ...controlStyleOverrides },
  };

  return (
    <>
      <Select<T, IsMulti>
        isLoading={isLoading}
        isClearable={isClearable}
        placeholder={placeholder}
        id={id || name}
        name={name}
        options={options}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        onChange={handleChange}
        onBlur={handleBlur}
        value={selected}
        components={components}
        filterOption={matchFrom === 'start' ? filterFromStart : filterFromAny}
        isMulti={isMulti as any}
        styles={customStyles}
        autoSelect={autoSelect}
        isDisabled={disabled}
      />
      <ErrorMessage
        component="p"
        name={name}
        className="mt-2 text-red-500 text-xs italic"
      />
    </>
  );
}

export interface SelectFieldProps<T = StandardOption>
  extends SelectInputProps<T> {
  label: string;
  indicateOptional?: boolean;
  indicateRequired?: boolean;
}

export function SelectField<T = StandardOption>(props: SelectFieldProps<T>) {
  const { label, indicateOptional, indicateRequired, ...rest } = props;
  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <SelectInput {...rest} />
    </VerticalField>
  );
}

export function HorizontalSelectField<T = StandardOption>(
  props: SelectFieldProps<T>
) {
  const { label, indicateOptional, indicateRequired, ...rest } = props;
  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <SelectInput {...rest} />
    </HorizontalField>
  );
}
