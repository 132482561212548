import React, { FC, useState, createContext, useContext } from "react";

/**
 * LayoutContext.
 */

interface State {
  menuOpen: boolean;
}

interface Update {
  toggleMenu(): void;
}

const LayoutStateContext = createContext<State | undefined>(undefined);
const LayoutUpdateContext = createContext<Update | undefined>(undefined);

interface LayoutProviderProps {}

const initialLayoutState: State = {
  menuOpen: false,
};

const LayoutProvider: FC<LayoutProviderProps> = (props) => {
  const { children } = props;
  const [menuOpen, setMenuOpen] = useState<boolean>(
    initialLayoutState.menuOpen
  );

  const layoutState: State = {
    menuOpen,
  };

  function toggleMenu() {
    setMenuOpen(!menuOpen);
  }

  const update: Update = {
    toggleMenu,
  };

  return (
    <LayoutStateContext.Provider value={layoutState}>
      <LayoutUpdateContext.Provider value={update}>
        {children}
      </LayoutUpdateContext.Provider>
    </LayoutStateContext.Provider>
  );
};

export function useLayout() {
  const stateCtx = useContext(LayoutStateContext);
  const updateCtx = useContext(LayoutUpdateContext);
  if (stateCtx === undefined || updateCtx === undefined) {
    throw new Error("useLayout must be used within a LayoutProvider");
  }
  return {
    ...stateCtx,
    ...updateCtx,
  };
}

/**
 * Layout.
 */

interface LayoutProps {}

export const Layout: FC<LayoutProps> = (props) => {
  const { children } = props;

  return (
    <LayoutProvider>
      <div className="_Layout min-h-screen bg-gray-100">{children}</div>
    </LayoutProvider>
  );
};
