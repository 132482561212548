import React, { FC, useState, useCallback } from "react";
import qs from "query-string";
import { RequestPasswordlessLoginLinkForm } from "./RequestPasswordlessLoginLinkForm";
import logoSrc from "images/aristamd_logo.svg";
import { ReactComponent as MailLinkPic } from "./mail_link.svg";
import { ReactComponent as LinkSentPic } from "./link_sent.svg";
import { FAIcon, Button } from "@preferral/ui";
import { formatPhoneNumber } from "@preferral/common";
import { TipsModal } from "./TipsModal";
import { usernameKind } from "./helpers";
import { useLocation } from "react-router-dom";

/**
 * RequestLink.
 */

interface RequestLinkProps {
  onSuccess(sentTo: string): void;
}

const RequestLink: FC<RequestLinkProps> = (props) => {
  const { onSuccess } = props;
  const location = useLocation();
  const { hasInvalidToken } = qs.parse(location.search);
  const requestingNewToken = !!hasInvalidToken;

  return (
    <div className="_RequestLink bg-white mt-8 px-4 sm:px-12 py-4 sm:py-8 rounded-xl sm:rounded-2xl shadow-2xl sm:mx-auto sm:w-full">
      <MailLinkPic className="mx-auto w-24" />
      <h2 className="font-bold leading-9 mt-6 text-2xl text-center text-gray-900">
        {requestingNewToken
          ? "Looks like you need a new link"
          : "Instant sign in"}
      </h2>
      <p className="leading-snug mt-6 text-base text-center text-gray-700">
        {requestingNewToken
          ? "Get a link sent to you to instantly sign in."
          : "Get a link sent to your inbox to sign in—no password necessary."}
      </p>
      <div className="mt-6">
        <RequestPasswordlessLoginLinkForm onSuccess={onSuccess} />
      </div>
    </div>
  );
};

/**
 * LinkRequested.
 */

interface LinkRequestedProps {
  sentTo: string;
  resetForm(): void;
}

type ActiveModal = "TIPS";

const LinkRequested: FC<LinkRequestedProps> = (props) => {
  const { resetForm, sentTo } = props;
  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  const closeModal = useCallback(() => {
    setActiveModal(null);
  }, [setActiveModal]);

  const openTipsModal = useCallback(() => {
    setActiveModal("TIPS");
  }, [setActiveModal]);

  const kind = usernameKind(sentTo);

  return (
    <>
      <TipsModal
        isOpen={activeModal === "TIPS"}
        onClose={closeModal}
        sentTo={sentTo}
      />
      <div className="_RequestLink bg-white mt-8 px-4 sm:px-12 py-4 sm:py-8 rounded-xl sm:rounded-2xl shadow-2xl sm:mx-auto sm:w-full">
        <LinkSentPic className="mx-auto w-36" />
        <h2 className="font-bold leading-9 mt-6 text-2xl text-center text-gray-900">
          Your instant sign-in link is on the way
        </h2>
        <p className="leading-snug mt-6 text-base text-center text-gray-700">
          If an account for{" "}
          <span className="font-semibold underline italic">
            {kind === "PHONE_NUMBER" ? formatPhoneNumber(sentTo) : sentTo}
          </span>{" "}
          exists, you'll receive{" "}
          {kind === "PHONE_NUMBER" ? "a text message" : "an email"} with the
          link.
        </p>
        <p className="leading-snug mt-6 text-base text-center text-gray-700">
          The link expires in 24 hours and can only be used once.
        </p>
        <div className="mt-12 border-t pt-6 pb-4 text-center">
          <p className="text-sm">
            Didn't get the link?
            <Button
              className="inline-block ml-3"
              type="button"
              size="xs"
              onClick={openTipsModal}
            >
              Try these tips
            </Button>
          </p>
        </div>
      </div>
      <div className="mt-6 text-center">
        <Button type="button" onClick={resetForm}>
          <span className="mr-2">
            <FAIcon icon="arrow-left" />
          </span>{" "}
          Back to Request Link form
        </Button>
      </div>
    </>
  );
};

/**
 * RequestPasswordlessLoginLinkScreen.
 */

interface RequestPasswordlessLoginLinkScreenProps { }

export const RequestPasswordlessLoginLinkScreen: FC<RequestPasswordlessLoginLinkScreenProps> = () => {
  const [sentTo, setSentTo] = useState<string | null>(null);

  const onSuccess = useCallback(
    (sentTo: string) => {
      setSentTo(sentTo);
    },
    [setSentTo]
  );

  return (
    <div className="_RequestPasswordlessLoginLinkScreen">
      <div className="min-h-screen bg-cool-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-lg">
          <img className="mx-auto w-48" src={logoSrc} alt="Preferral Logo" />
          {!!sentTo ? (
            <LinkRequested sentTo={sentTo} resetForm={() => setSentTo(null)} />
          ) : (
            <RequestLink onSuccess={onSuccess} />
          )}
        </div>
      </div>
    </div>
  );
};
