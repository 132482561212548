import { FC } from 'react'
import { required, Validator } from '@preferral/ui'
import { HorizontalToothSelectorField } from 'components/formik/ToothSelectorField'
import { ToothSelectorQuestion } from '../types'
import { useQuestion } from './QuestionnaireFormContext'

interface ToothSelectorQuestionFieldProps {
  question: ToothSelectorQuestion
}

export const ToothSelectorQuestionField: FC<ToothSelectorQuestionFieldProps> = (
  props,
) => {
  const { question } = props

  const { name, isRequired, isVisible } = useQuestion(question.id)

  if (!isVisible) {
    return null
  }

  const validate = [isRequired ? required : null].filter(Boolean) as Validator[]

  return (
    <div className="_ToothSelectorQuestionField">
      <HorizontalToothSelectorField
        indicateRequired={isRequired}
        name={name}
        label={question.prompt}
        validate={validate}
      />
    </div>
  )
}
