import { FC } from "react";
import ReactMarkdown from "react-markdown";
import { QuestionnaireModel, QuestionModel, SomeAnswer } from "../types";
import { HorizontalField } from "@preferral/ui";
import { ToothSelector } from "components/ToothSelector";
import { SignaturePad } from "components/SignaturePad";
import { QuestionnaireResponseProvider, useQuestion } from "./QuestionnaireResponseContext";

/**
 * AnswerValue.
 */

interface AnswerValueProps {
  answer: SomeAnswer | null;
}

const AnswerValue: FC<AnswerValueProps> = (props) => {
  const { answer } = props;

  if (!answer) {
    return <p className="text-lg text-cool-gray-500">-</p>;
  }

  switch (answer._kind) {
    case "address": {
      const addressStr =
        typeof answer.value === "string"
          ? answer.value
          : JSON.stringify(answer.value);
      return <p className="py-1 md:py-2">{addressStr}</p>;
    }
    case "teeth":
      return <ToothSelector value={answer.value!} editable={false} />;
    case "string_list":
      return (
        <ul className="block py-1 md:py-2 text-xl text-gray-600 pr-4">
          {answer.value?.map((v) => (
            <li key={v} className="mb-1 md:mb-0">
              {v}
            </li>
          ))}
        </ul>
      );
    case "medication_list":
      return (
        <ul className="block py-1 md:py-2 text-xl text-gray-600 pr-4">
          {answer.value?.map((v, idx) => (
            <li key={idx} className="mb-1 md:mb-0">
              {[v.name, v.dosage, v.frequency].filter(Boolean).join(", ")}
            </li>
          ))}
        </ul>
      )
    case "base64png":
      return (
        <SignaturePad
          className="lg:w-72"
          value={answer.value || ""}
          editable={false}
        />
      );
    default:
      return (
        <p className="block py-1 md:py-2 text-xl text-gray-600 mb-1 md:mb-0 pr-4">
          {answer.value}
        </p>
      );
  }
};

/**
 * QuestionResponse.
 */

interface QuestionResponseProps {
  question: QuestionModel;
}

const QuestionResponse: FC<QuestionResponseProps> = (props) => {
  const { question } = props;

  const { isVisible } = useQuestion(question.id);

  if (!isVisible) {
    return null;
  }

  switch (question.kind) {
    case "signature":
      return (
        <div>
          <p className="py-8">{question.prompt}</p>
          <AnswerValue answer={question.answer} />
        </div>
      );
    default:
      return (
        <HorizontalField label={question.prompt}>
          <AnswerValue answer={question.answer} />
        </HorizontalField>
      );
  }
};

interface ResponseProps {
  questionnaire: QuestionnaireModel;
}

export const Response: FC<ResponseProps> = (props) => {
  const { questionnaire } = props;

  return (
    <QuestionnaireResponseProvider questionnaire={questionnaire}>
      {questionnaire.sections.map((section, sectionIdx) => (
        <div
          key={section.id}
          className="px-4 pb-4 pt-8 bg-white border border-gray-300 rounded shadow"
        >
          <div className="max-w-3xl mx-auto">
            <h4 className="ml-4 text-xl text-gray-800">{section.title}</h4>
            {section.text ? (
              <div className="py-8 prose mx-auto">
                <ReactMarkdown>{section.text}</ReactMarkdown>
              </div>
            ) : null}
            <div className="mt-4 p-2">
              {section.questions.map((question) => (
                <div key={question.id} className="mt-3">
                  <QuestionResponse question={question} />
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </QuestionnaireResponseProvider>
  );
};
