import { FC, createContext, useContext } from 'react'
import { useFormikContext } from 'formik'
import { getQuestion, questionRuleState } from '../helpers'
import { QuestionnaireModel, SomeAnswer } from '../types'
import { QuestionnaireFieldset } from './Form'

interface State {
  namePrefix?: string
  template: QuestionnaireModel
}

const questionnaireFormContext = createContext<State>(null!)

interface QuestionnaireFormProviderProps {
  namePrefix?: string
  template: QuestionnaireModel
}

export const QuestionnaireFormProvider: FC<QuestionnaireFormProviderProps> = (
  props,
) => {
  const { namePrefix, template, children } = props

  return (
    <questionnaireFormContext.Provider value={{ namePrefix, template }}>
      {children}
    </questionnaireFormContext.Provider>
  )
}

function useQuestionnaireForm() {
  const context = useContext(questionnaireFormContext)
  if (context === undefined) {
    throw new Error(
      `useQuestionnaireForm must be used within a QuestionnaireFormProvider`,
    )
  }
  return context
}

type FormValues =
  | QuestionnaireFieldset
  | { [key: string]: QuestionnaireFieldset }
type Answer = SomeAnswer['value'] | null

export function useQuestion(questionId: string) {
  const { values } = useFormikContext<FormValues>()
  const { namePrefix, template } = useQuestionnaireForm()

  const question = getQuestion(questionId, template)

  function getAnswerValue(questionId: string): Answer {
    if (namePrefix) {
      return values[namePrefix]![questionId]
    }
    return values[questionId] as Answer
  }

  const name = namePrefix ? `${namePrefix}.${question!.id}` : question!.id

  const { isVisible, isRequired } = questionRuleState(question!, {
    getAnswerValue,
  })
  return { name, isVisible, isRequired }
}
