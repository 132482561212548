import React, { FC, useState, useCallback } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Button, ButtonProps } from '../Button/Button';
import { useTimeout } from '../../hooks/useTimeout';

interface CopyButtonProps extends ButtonProps {
  text: string;
}

export const CopyButton: FC<CopyButtonProps> = props => {
  const { text, children, ...buttonProps } = props;

  const [copied, setCopied] = useState(false);
  const setTimeout = useTimeout();

  const handleCopyClick = useCallback(() => {
    setCopied(true);
    setTimeout(() => setCopied(false), 4000);
  }, [setTimeout, setCopied]);

  return (
    <CopyToClipboard text={text} onCopy={handleCopyClick}>
      <Button {...buttonProps}>
        {
          copied ? (
            <>
              Copied!
            </>
          ) : (
            <>
              {children}
            </>
          )
        }
      </Button>
    </CopyToClipboard>
  )
}
