import React, { FC } from "react";

interface IntegrationsSettingsScreenProps {}

export const IntegrationsSettingsScreen: FC<IntegrationsSettingsScreenProps> = (
  props
) => {
  return (
    <div className="_IntegrationsSettingsScreen py-6 px-4 space-y-6 sm:p-6 lg:pb-8">
      <div>
        <h2 className="text-lg leading-6 font-medium text-gray-900">
          Integrations
        </h2>
      </div>
    </div>
  );
};
