import { FC, useState, useEffect, useLayoutEffect } from "react";
import qs from "query-string";
import { gql, useMutation } from "@apollo/client";
import { useLocation, useHistory } from "react-router-dom";
import { useAuth } from "context/AuthContext";
import toast from "react-hot-toast";

const LOGIN_WITH_TOKEN = gql`
  mutation LoginWithPasswordlessToken($token: String!) {
    loginWithPasswordlessToken(token: $token) {
      errors {
        key
        message
      }
      session {
        token
        patientAccount {
          id
        }
      }
    }
  }
`;

interface Data {
  loginWithPasswordlessToken: {
    errors?: InputError[];
    session?: {
      token: string;
      patientAccount: {
        id: string;
      };
    };
  };
}

interface Variables {
  token: string;
}

interface PasswordlessLoginScreenProps { }

interface LocationState {
  from?: { pathname?: string; search?: string };
}

export const PasswordlessLoginScreen: FC<PasswordlessLoginScreenProps> = () => {
  const [success, setSuccess] = useState(false);
  const [status, setStatus] = useState<{ errors: InputError[] | null }>({
    errors: null,
  });
  const { logout, login } = useAuth();
  const location = useLocation<LocationState>();
  const history = useHistory();
  const destinationPath = location.state?.from?.pathname || "/pp/home";

  const { plt: passwordlessLoginToken } = qs.parse(
    location.state?.from?.search || location.search || ""
  );

  const [loginWithToken, { data, loading, error }] = useMutation<
    Data,
    Variables
  >(LOGIN_WITH_TOKEN);

  useEffect(() => {
    async function doPasswordlessLogin() {
      if (passwordlessLoginToken) {
        setStatus({ errors: null });
        logout();

        try {
          const { data } = await loginWithToken({
            variables: { token: passwordlessLoginToken as string },
          });
          if (data?.loginWithPasswordlessToken.errors) {
            // setStatus({ errors: data.loginWithPasswordlessToken.errors });
            /**
             * We don't have to worry about the specific error here so much,
             * the important thing is that it's one of several _expected_ error
             * cases, and the user just needs to request a new passwordless token
             * link.
             */
            const params = qs.stringify({
              target: destinationPath,
              hasInvalidToken: 1,
            });
            history.replace(`/auth/request_passwordless?${params}`);
          } else if (data?.loginWithPasswordlessToken.session) {
            const {
              token: jwtString,
            } = data.loginWithPasswordlessToken.session;
            login(jwtString, () => setSuccess(true));
          }
        } catch (e) {
          toast.error("Something went wrong.");
          console.error(e);
        }
      }
    }
    doPasswordlessLogin();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // If login is successful, redirect to the destination path.
  useLayoutEffect(() => {
    if (success) {
      history.replace(destinationPath);
    }
  }, [success, history, destinationPath]);

  return (
    <div className="PasswordlessLoginScreen">
      <p>PasswordlessLoginScreen</p>
      <p>State:</p>
      <pre>{JSON.stringify(location.state, null, 2)}</pre>
      <pre>{JSON.stringify({ status, data, loading, error }, null, 2)}</pre>
    </div>
  );
};
