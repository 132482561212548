import { FC } from 'react'
import { required, Validator } from '@preferral/ui'
import { SignatureInput } from 'components/formik/SignatureField'
import { SignatureQuestion } from '../types'
import { useQuestion } from './QuestionnaireFormContext'

interface SignatureQuestionFieldProps {
  question: SignatureQuestion
}

export const SignatureQuestionField: FC<SignatureQuestionFieldProps> = (
  props,
) => {
  const { question } = props

  const { name, isRequired, isVisible } = useQuestion(question.id)

  if (!isVisible) {
    return null
  }

  const validate = [isRequired ? required : null].filter(Boolean) as Validator[]

  return (
    <div className="_SignatureQuestionField max-w-2xl mx-auto">
      <p className="py-4">{question.prompt}</p>
      <SignatureInput name={name} validate={validate} />
    </div>
  )
}
