import { useState, useCallback, useRef } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import { useOnEscape } from './useOnEscape';

export function useDropdown(defaultValue: boolean = false) {
  const [isOpen, setIsOpen] = useState(defaultValue);

  const toggle = useCallback(() => setIsOpen(value => !value), []);
  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  const contentRef = useRef<any>();
  const triggerRef = useRef<any>();
  const triggerEl = triggerRef.current;

  const handleClickOutside = useCallback(
    ev => {
      if (
        triggerEl &&
        (ev.terget === triggerEl || triggerEl.contains(ev.target))
      ) {
        // The click outside the dropdown content area was on the dropdown trigger
        // itself, which will handle closing the dropdown, so in this branch
        // we have a no-op.
      } else {
        close();
      }
    },
    [triggerEl, close]
  );

  useOnClickOutside(contentRef, handleClickOutside);
  useOnEscape(isOpen, close);

  return {
    isOpen,
    toggle,
    open,
    close,
    triggerRef,
    contentRef,
  };
}
