import { FC } from 'react'
import { PreferredProviderQuestion } from '../types'
import { useQuestion } from './QuestionnaireFormContext'

interface ProviderQuestionFieldProps {
  question: PreferredProviderQuestion
}

export const ProviderQuestionField: FC<ProviderQuestionFieldProps> = (
  props,
) => {
  const { question } = props

  // const { name, isVisible, isRequired } = useQuestion(question.id)
  const { isVisible } = useQuestion(question.id)

  if (!isVisible) {
    return null
  }

  return (
    <div className="_ProviderQuestionField">
      <p>ProviderQuestionField</p>
    </div>
  )
}
