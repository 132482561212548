import { useRef, useEffect, useCallback } from 'react';

interface CheckFn {
  (): boolean;
}

interface ResolveFn<T> {
  (value?: boolean | PromiseLike<T> | undefined): void;
}

interface RejectFn {
  (reason?: any): void;
}

interface ResetFn {
  (): void;
}

interface DoWaitFn {
  (): Promise<boolean>;
}

type WaitForHookTuple = [DoWaitFn, { reset: ResetFn }];

/**
 * useWaitFor()
 * @param checkFn
 *
 * This is a hook that returns a promise returning function that
 * will resolve when the given `checkFn` returns `true`.
 *
 * This is useful to turn a condition you might want to wait on (like
 * waiting on some state) into a promise you can await.
 *
 * Developed by Jon here: https://codesandbox.io/s/beautiful-chaplygin-jzvqw?file=/src/App.js
 *
 * Example:
 * ```js
 * const check = useCallback(() => !isUploading, [isUploading]);
 * const [waitForUploads] = useWaitFor(check)
 *
 * async function handleSubmit() {
 *   await waitForUploads()
 *
 *   const values = {
 *      ...fields,
 *      uploads
 *   }
 *
 *   await doSubmit(values)
 *   alert("Success!")
 * }
 * ```
 */
export function useWaitFor(checkFn: CheckFn): WaitForHookTuple {
  const resolveRef = useRef<ResolveFn<boolean> | null>(null);
  const rejectRef = useRef<RejectFn | null>(null);
  const promiseRef = useRef<Promise<boolean> | null>(null);

  const maybeResolve = useCallback(() => {
    if (resolveRef.current && checkFn()) {
      resolveRef.current(true);
    }
  }, [checkFn()]);

  useEffect(maybeResolve, [maybeResolve]);

  const makeNewPromise = useCallback(() => {
    if (rejectRef.current) {
      rejectRef.current({ isCancelled: true });
    }
    promiseRef.current = new Promise((resolve, reject) => {
      resolveRef.current = resolve;
      rejectRef.current = reject;
    });
  }, []);

  const doWaitFor = useCallback(() => {
    makeNewPromise();
    maybeResolve();
    return promiseRef.current!;
  }, [makeNewPromise, maybeResolve]);

  return [doWaitFor, { reset: makeNewPromise }];
}
