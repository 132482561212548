import React, { FC } from "react";
import { MedicationListInput as RawMedicationListInput } from "components/MedicationListInput";
import { useField, ErrorMessage } from "formik";
import {
  VerticalField,
  HorizontalField,
  compose,
  ValidateProp
} from "@preferral/ui";

/**
 * MedicationListInput.
 */

export interface MedicationListInputProps {
  id?: string;
  name: string;
  className?: string;
  includeFrequency?: boolean;
  includeDosage?: boolean;
  validate?: ValidateProp;
}

export const MedicationListInput: FC<MedicationListInputProps> = (props) => {
  const {
    id,
    className = "",
    name,
    includeDosage,
    includeFrequency,
    validate: validators,
  } = props;

  const validate = validators && compose([validators].flat());
  const [field, meta, helpers] = useField({ name, validate });
  const { value = [] } = field;
  const { setValue } = helpers;

  return (
    <>
      <RawMedicationListInput
        id={id}
        value={value}
        onChange={setValue}
        includeDosage={includeDosage}
        includeFrequency={includeFrequency}
        className={
          meta && meta.touched && meta.error
            ? `${className} border border-red-500`
            : className
        }
      />
      <ErrorMessage
        component="p"
        name={name}
        className="mt-2 text-red-500 text-xs italic"
      />
    </>
  );
};

/**
 * MedicationListField.
 */

export interface MedicationListFieldProps extends MedicationListInputProps {
  label: string;
  indicateOptional?: boolean;
  indicateRequired?: boolean;
}

export const MedicationListField: FC<MedicationListFieldProps> = (props) => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;

  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <MedicationListInput {...rest} />
    </VerticalField>
  );
};

/**
 * HorizontalMedicationListField.
 */

export const HorizontalMedicationListField: FC<MedicationListFieldProps> = (
  props
) => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;

  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <MedicationListInput {...rest} />
    </HorizontalField>
  );
};
