import { FC, useCallback } from "react";
import * as Yup from 'yup';
import { gql, useMutation } from "@apollo/client";
import { Formik } from "formik";
import { Button, TextField } from "@preferral/ui";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { usernameKind } from "screens/RequestPasswordlessLoginLinkScreen/helpers";
import toast from "react-hot-toast";

const REQUEST_PATIENT_PACKET_LINK = gql`
  mutation RequestPatientPacketLink($id: UUID4!, $input: RequestPatientPacketInput!) {
    requestPatientPacket(id: $id, input: $input) {
      errors {
        key
        message
      }
      sent
    }
  }
`;

interface MutationData {
  requestPatientPacket: {
    errors?: InputError[];
    sent?: boolean;
  }
}

interface MutationVariables {
  id: string;
  input: { email: string } | { phoneNumber: string }
}

interface FormValues {
  username: string;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    username: Yup.string().required("Required")
  })
  .required();

interface RequestPacketFormProps {
  patientPacketId: string;
  onSuccess(sentTo: string): void;
};

export const RequestPacketForm: FC<RequestPacketFormProps> = props => {
  const { patientPacketId, onSuccess } = props;

  const [requestPacket] = useMutation<MutationData, MutationVariables>(REQUEST_PATIENT_PACKET_LINK);

  const onSubmit = useCallback(
    async (values: FormValues, formikHelpers) => {
      const { setStatus, setSubmitting } = formikHelpers;
      setStatus({ errors: null });

      const kind = usernameKind(values.username);

      let input;
      if (kind === "EMAIL") {
        input = { email: values.username }
      } else if (kind === "PHONE_NUMBER") {
        input = { phoneNumber: values.username }
      } else {
        setStatus({ errors: [{ key: "", message: "You must enter either an email address or a cell phone number." }] })
        setSubmitting(false);
        return
      }

      const variables = {
        id: patientPacketId,
        input
      };

      try {
        const { data } = await requestPacket({ variables });

        if (data?.requestPatientPacket.errors) {
          setStatus({ errors: data.requestPatientPacket.errors });
        } else if (data?.requestPatientPacket.sent) {
          // it worked...
          onSuccess(values.username);
        }
        setSubmitting(false);
      } catch (e) {
        toast.error("Something went wrong.");
        console.error(e);
        setSubmitting(false);
      }
    }, [patientPacketId, requestPacket, onSuccess])

  return (
    <Formik<FormValues>
      initialValues={{ username: "" }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ status, handleSubmit, isSubmitting }) => (
        <form className="_RequestPacketForm" onSubmit={handleSubmit}>
          <FormStatusErrors status={status} />

          <TextField label="Email or Cell Phone" name="username" />

          <div className="mt-3">
            <Button
              type="submit"
              isLoading={isSubmitting}
              kind="primary"
              color="blue"
              className="w-full"
            >
              Send Me the Packet
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
