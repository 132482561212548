const domain = window.location.hostname
  .split(".")
  .slice(-3)
  .join(".");

const productionDomains = process.env.REACT_APP_PRODUCTION_DOMAINS!.split(",");

const isProd = !!productionDomains
  .filter(Boolean)
  .find(d => domain.includes(d));

export const ENV = isProd ? "PROD" : "DEV";

export const MIXPANEL_TOKEN =
  ENV === "PROD"
    ? process.env.REACT_APP_MIXPANEL_PROD_TOKEN
    : process.env.REACT_APP_MIXPANEL_DEV_TOKEN;
