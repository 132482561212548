import invariant from 'tiny-invariant';

/**
 * Regex for "MM/DD/YYYY":
 */
export const localDateRegex = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;

/**
 * Pre-condition: `localDateString` is a string formatted like "MM/DD/YYYY";
 */
export function localDateToISO(localDateString: string): string {
  invariant(
    localDateString.match(localDateRegex),
    `[preferral-common][localDateToISO][localDateToISO] Invalid local date: ${localDateString}`
  );

  const [mm, dd, yyyy] = localDateString.split('/');
  return `${yyyy}-${mm.padStart(2, '0')}-${dd.padStart(2, '0')}`;
}

/**
 * Regex for "YYYY-MM-DD":
 */
export const isoDateRegex = /^(\d{4})-(\d{2})-(\d{2})$/;

/**
 * Pre-condition: `isoDateString` is a string formatted like "YYYY-MM-DD";
 */
export function ISODateToLocal(isoDateString: string): string {
  invariant(
    isoDateString.match(isoDateRegex),
    `[preferral-common][localDateToISO][ISODateToLocal] Invalid local date: ${isoDateString}`
  );

  const [yyyy, mm, dd] = isoDateString.split('-');
  return `${mm}/${dd}/${yyyy}`;
}
